import { TimesheetEntryAPIDTO, TimesheetEntry } from "models/timesheet";
import { DEFAULT_API_DATE_FORMAT } from "utils";
import { format } from 'date-fns';

export const formatTimesheetEntries = (uuid: string, data: TimesheetEntryAPIDTO[], dateRange: Date[]): TimesheetEntry[] => {
	const dataFlattened = data?.reduce((acc, curr) => ({
		...acc,
		[curr.date]: curr,
	}), {} as Record<string, TimesheetEntryAPIDTO>) ?? {};

	const timesheetEntries = dateRange.map(date => {
		const entry = dataFlattened[format(date, DEFAULT_API_DATE_FORMAT)];

		if (entry) {
			return {
				...entry,
				date: new Date(date),
			} as TimesheetEntry;
		}

		return {
			uuid: '',
			date,
			description: '',
			worked_hours: null,
			pay: null,
			status: null,
			organization_uuid: uuid,
		} as TimesheetEntry;
	});

	return timesheetEntries;
}
