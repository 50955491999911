import {
	Divider,
	List,
	ListItem,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { ProductRate } from 'models';
import { currencyFormat } from 'utils';

type ProductTabsProps = {
	products: ProductRate[];
	onTabChange: Function;
	selected?: ProductRate;
	numbersCeil?: boolean;
};

export const ProductTabs = ({
	onTabChange,
	products,
	selected,
	numbersCeil,
}: ProductTabsProps) => {
	let tabIndex: number = 0;

	products.forEach((product, index) => {
		if (product.id === selected.id) {
			tabIndex = index;
		}
	});

	return (
		<Tabs
			variant={'soft-rounded'}
			size={'sm'}
			isFitted={true}
			colorScheme={'blue'}
			defaultIndex={tabIndex}
			marginTop={1}>
			<TabList>
				{products.map((plan, index) => (
					<Tab
						marginX={index === 1 ? 2 : 0}
						_selected={{
							bg: 'blue.600',
							color: 'white',
						}}
						bg={'blue.100'}
						color={'blue.700'}
						onClick={() => onTabChange(plan.id)}
						key={'tab-' + plan.id}>
						{plan.display_name ?? plan.name} - {currencyFormat(plan.price)}
					</Tab>
				))}
			</TabList>
			<TabPanels>
				{products.map((plan) => (
					<TabPanel
						key={'tab-panel-' + plan.id}
						padding={0}
						margin={0}
						marginTop={5}>
						<List spacing={3} marginTop={5}>
							{plan.summary.map((summary) => (
								<ListItem key={'summary-' + summary.id}>
									<Stack
										direction='row'
										width='full'
										className='justify-between items-center'
										verticalAlign={'middle'}>
										<Text
											fontSize={'sm'}
											align='left'
											fontWeight={'400'}
											lineHeight={5}
											color={'gray.500'}
											width={'50%'}
											verticalAlign={'middle'}>
											{summary.label}
										</Text>
										{summary.description && (
											<>
												<Stack
													direction={'column'}
													margin={0}
													padding={0}
													width={'50%'}>
													{summary.type === 'numeric' && (
														<Text
															lineHeight={5}
															margin={0}
															padding={0}
															align='right'
															verticalAlign={'middle'}
															fontWeight={500}
															color={'gray.500'}
															fontSize={'xs'}>
															Up to
														</Text>
													)}
													<Text
														lineHeight={5}
														margin={0}
														padding={0}
														align='right'
														verticalAlign={'middle'}
														fontWeight={600}
														color={'gray.500'}
														fontSize={'sm'}>
														{summary.description}
													</Text>
												</Stack>
											</>
										)}
									</Stack>
								</ListItem>
							))}
						</List>
						{plan.details && (
							<>
								<Divider marginTop={2} />
								<Text
									marginTop={2}
									fontSize={'xs'}
									fontWeight={500}
									color={'gray.500'}
									fontStyle={'italic'}
									dangerouslySetInnerHTML={{ __html: plan.details }}
								/>
							</>
						)}
					</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	);
};
