import { ApplicationGender } from 'models';

export const getGenderText = (gender: ApplicationGender) => {
	switch (gender) {
		case ApplicationGender.Male:
			return 'Male';
		case ApplicationGender.Female:
			return 'Female';
		default:
			return '';
	}
};
