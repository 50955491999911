import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class NavigationStore {
	currentTab = 'benefits';

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'NavigationStore',
			properties: ['currentTab'],
			storage: window.localStorage,
		});
	}

	setCurrentTab(tab: string) {
		this.currentTab = tab;
	}

	clean() {
		this.currentTab = 'benefits';
	}
}
