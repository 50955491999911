import {
	CardExpirationDateElement,
	CardNumberElement,
	CardVerificationCodeElement,
	useBasisTheory,
} from '@basis-theory/basis-theory-react';
import { InfoIcon } from '@chakra-ui/icons';
import { Checkbox, Link, Stack, Text } from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import {
	AlertError,
	Content,
	ContentHeader,
	CustomButton,
	CustomFormControl,
	CustomInput,
	CustomRadio,
	Footer,
	Header,
	LocationDisplay,
	LocationStateSelect,
	Page,
	SecurityNote,
} from 'components';
import { config } from 'config';
import { useApplicationPaymentMutation, useLocationStates } from 'hooks';
import { observer } from 'mobx-react';
import { Address, Payment, PaymentCardDetails, PaymentType } from 'models';
import { useEffect, useRef, useState } from 'react';
import { GlobalError, useForm } from 'react-hook-form';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import { Logger } from 'utils';
import { getStateName } from 'utils/address';

export const ApplicationPaymentPage = observer(() => {
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { data: loadedStates = [] } = useLocationStates();
	const { applicationStore } = useStores();
	const [error, setError] = useState<Error>(null);
	const [errorCardNumber, setErrorCardNumber] = useState<GlobalError>(null);
	const [errorCVV, setErrorCVV] = useState<GlobalError>(null);
	const [errorExpirationDate, setErrorExpirationDate] =
		useState<GlobalError>(null);
	const [isBillingAddressEqual, setIsBillingAddressEqual] = useState(true);

	const mutateApplicationPayment = useApplicationPaymentMutation(
		applicationStore.application.uuid
	);

	useEffect(() => {
		analyticsManager.page(AnalyticsPage.Application.Payment);
	}, []);

	useEffect(() => {
		if (!applicationStore.isDraft) {
			navigate(RoutePath.applicationResult);
		}
	}, [applicationStore.isDraft, navigate]);

	const { bt, error: basisTheoryError } = useBasisTheory(config.basis_theory, {
		elements: true,
	});

	const expirationDateRef = useRef<any>(null);
	const cardCVVRef = useRef<any>(null);
	const cardNumberRef = useRef<any>(null);

	const defaultValues = {
		name: '',
		address: '',
		state_id: null,
		city: '',
		zipcode: '',
		nga_association: false,
	};

	type NGAAssociation = {
		nga_association: boolean;
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<
		Address & NGAAssociation & Pick<PaymentCardDetails, 'card_holder_name'>
	>({
		defaultValues,
		mode: 'onBlur',
	});

	const isBillingAddressOptions = [
		{ value: '1', text: 'Yes' },
		{ value: '0', text: 'No' },
	];

	useEffect(() => {
		if (basisTheoryError) {
			setError(basisTheoryError);
		}
	}, [basisTheoryError]);

	const goBack = () => {
		navigate({
			pathname: RoutePath.applicationConfirm,
			search: `?${createSearchParams([['goToLastStep', 'true']])}`,
		});
	};

	const isLoading = !bt;

	const getCardPayment = async (): Promise<
		Pick<
			PaymentCardDetails,
			'version' | 'provider' | 'token_id' | 'card_number'
		>
	> => {
		setErrorCardNumber(null);
		setErrorCVV(null);
		setErrorExpirationDate(null);
		const formatErrorFromBasisTheory = (refCurrent): GlobalError | null => {
			if (!refCurrent?.type) {
				return null;
			}
			switch (refCurrent.type) {
				case 'incomplete':
					return { type: 'required', message: t('This field is required') };
				case 'invalid':
					return { type: 'invalid', message: t('This field is invalid') };
				default:
					Logger.error({
						message: 'Unknown error from Basis Theory',
						extraData: refCurrent.type,
					});
					return { type: 'unknown', message: t('This field is invalid.') };
			}
		};

		try {
			if (!cardNumberRef.current.cardMetadata.cardLast4) {
				setErrorCardNumber({
					type: 'required',
					message: t('Invalid credit card number'),
				});
				return;
			}

			const token = await bt.tokenize({
				card: {
					type: 'token',
					data: {
						number: cardNumberRef.current,
						expiration_month: expirationDateRef.current.month(),
						expiration_year: expirationDateRef.current.year(),
						cvv: cardCVVRef.current,
					},
				},
			});
			const tokenCard = (token as any).card;
			return {
				provider: 'BasisTheory',
				version: 'v2',
				token_id: tokenCard.id,
				card_number: `XXXXXXXXXXXX${cardNumberRef.current.cardMetadata.cardLast4}`,
			};
		} catch (error) {
			const errorDetails = error?.details?.card?.data;
			const cardNumberErrorRef = errorDetails?.number;
			const cardNumberError = formatErrorFromBasisTheory(cardNumberErrorRef);
			setErrorCardNumber(cardNumberError);
			const expirationDateErrorRef =
				errorDetails?.expiration_month || errorDetails?.expiration_year;
			const expirationDateError = formatErrorFromBasisTheory(
				expirationDateErrorRef
			);
			setErrorExpirationDate(expirationDateError);
			const cvvErrorRef = error?.details?.cardExtraInfo;
			const cvvError = formatErrorFromBasisTheory(cvvErrorRef);
			setErrorCVV(cvvError);

			if (!expirationDateError && !cardNumberError && !cvvError) {
				Logger.error({
					message: 'Unknown error from Basis Theory',
					exception: error,
				});
			}
		}
	};

	const submitPayment = async (
		values: Partial<Address> &
			Partial<Pick<PaymentCardDetails, 'card_holder_name'>>
	) => {
		const cardPayment = await getCardPayment();
		if (!cardPayment) {
			return;
		}

		const formAddress: Address = {
			state_id: Number(values.state_id),
			address: values.address ?? '',
			city: values.city ?? '',
			zipcode: values.zipcode ?? '',
		};
		const applicationAddress: Address = {
			state_id: applicationStore.application.state_id,
			address: applicationStore.application.address,
			city: applicationStore.application.city,
			zipcode: applicationStore.application.zipcode,
		};
		const payment: Payment = {
			type: PaymentType.Card,
			...(isBillingAddressEqual ? applicationAddress : formAddress),
			details: {
				version: cardPayment.version,
				provider: cardPayment.provider,
				token_id: cardPayment.token_id,
				card_number: cardPayment.card_number,
				card_holder_name: values.card_holder_name,
			},
		};
		await mutateApplicationPayment.mutateAsync(payment);
		await applicationStore.reload();
		analyticsManager.track(
			AnalyticsTrack.ApplicationPaymentSubmitted({
				has_billing_address_equal: isBillingAddressEqual,
				location: getStateName(loadedStates, payment.state_id),
			})
		);
	};

	const renderAddress = () => (
		<>
			<CustomFormControl label={t('Address *')} error={errors.address}>
				<CustomInput
					type='text'
					{...register('address', { required: true })}
					placeholder={t('Enter address')}
				/>
			</CustomFormControl>
			<CustomFormControl label={t('State *')} error={errors.state_id}>
				<LocationStateSelect {...register('state_id', { required: true })} />
			</CustomFormControl>

			<CustomFormControl label={t('City *')} error={errors.city}>
				<CustomInput
					type='text'
					placeholder={t('Enter city')}
					{...register('city', { required: true })}
				/>
			</CustomFormControl>

			<CustomFormControl label={t('Zip code *')} error={errors.zipcode}>
				<CustomInput
					type='tel'
					mask='99999'
					defaultValue={defaultValues.zipcode}
					placeholder={t('Enter zip code')}
					{...register('zipcode', { required: true })}
				/>
			</CustomFormControl>
		</>
	);

	return (
		<Page>
			<Header canGoBack onClickBack={goBack} />
			<Content paddingX scrollY isLoading={isLoading}>
				<ContentHeader
					title='Enter your payment details'
					subTitle='Payments will be collected every month by each insurance company'
				/>
				<AlertError error={error} />
				<Stack spacing='6'>
					<CustomFormControl
						className='pb-4'
						label={t('Card Holder Name *')}
						error={errors.card_holder_name}>
						<CustomInput
							type='text'
							{...register('card_holder_name', { required: true })}
							placeholder={t('Enter your name')}
						/>
					</CustomFormControl>
					<div className='flex flex-wrap items-start'>
						<CustomFormControl
							className='!w-60 pb-4'
							label={t('Card Number *')}
							error={errorCardNumber}>
							<CardNumberElement
								id='card-number-input'
								ref={cardNumberRef}
								bt={bt}
								onChange={() => {
									setErrorCVV(null);
									setErrorCardNumber(null);
									setErrorExpirationDate(null);
								}}
							/>
						</CustomFormControl>
						<CustomFormControl
							className='!w-36 pb-4'
							label={t('Expiration Date *')}
							error={errorExpirationDate}>
							<CardExpirationDateElement
								id='card-expiration-date-input'
								ref={expirationDateRef}
								bt={bt}
								onChange={() => {
									setErrorCVV(null);
									setErrorCardNumber(null);
									setErrorExpirationDate(null);
								}}
							/>
						</CustomFormControl>
						<CustomFormControl
							className='!w-36 pb-4'
							label={t('CVV *')}
							error={errorCVV}>
							<CardVerificationCodeElement
								id='card-cvv-input'
								ref={cardCVVRef}
								bt={bt}
								onChange={() => {
									setErrorCVV(null);
									setErrorCardNumber(null);
									setErrorExpirationDate(null);
								}}
							/>
						</CustomFormControl>
					</div>
					<SecurityNote>
						<T>
							We are using Basis Theory to collect this data - it stays secure
							and encrypted only until it is needed to be charged.
						</T>{' '}
						<Link
							href='https://basistheory.com/security'
							isExternal
							color='blue.500'
							fontWeight='semibold'>
							<T>More on Basis Theory here.</T>
						</Link>
					</SecurityNote>
					<CustomFormControl
						label={t(
							'Is your billing address the same as the one you previously entered?'
						)}>
						<div className='pt-4'>
							<CustomRadio
								options={isBillingAddressOptions}
								value={isBillingAddressEqual ? '1' : '0'}
								onChange={(val) => setIsBillingAddressEqual(val === '1')}
							/>
						</div>
					</CustomFormControl>

					{isBillingAddressEqual && (
						<Stack>
							<Text>
								<T>Application Address:</T>
							</Text>
							<LocationDisplay address={applicationStore.application} />
						</Stack>
					)}
					{!isBillingAddressEqual && renderAddress()}

					{applicationStore.application.extras.need_NGA_associate && (
						<Stack spacing={3}>
							<Text fontSize={'md'} color={'gray.900'} fontWeight={600}>
								<T>Association agreement</T>
							</Text>
							<Text fontSize={'md'} color={'gray.900'} fontWeight={400}>
								<T>
									By applying to NGA plan you agree to be a part of the labour
									workers association
								</T>
							</Text>
							<Stack spacing='3' direction='row' align='center'>
								<InfoIcon width='24px' height='24px' color='blue.500' />
								<Text
									fontWeight={400}
									fontSize='xs'
									color='gray.500'
									lineHeight='16px'>
									<T>
										For more information about being a part of the association
									</T>
									<Link
										color={'blue.500'}
										fontWeight={600}
										fontSize={'xs'}
										marginLeft={1}
										target='_blank'
										href='https://nationalgigalliance.com/'>
										Click here
									</Link>
								</Text>
							</Stack>
							<CustomFormControl error={errors.nga_association}>
								<Checkbox
									defaultChecked
									{...register('nga_association', { required: true })}>
									<Text fontSize={'sm'} color={'gray.900'} fontWeight={400}>
										<T>I agree to the terms and conditions listed above</T>
									</Text>
								</Checkbox>
							</CustomFormControl>
						</Stack>
					)}
				</Stack>
			</Content>
			<Footer>
				<CustomButton
					bg='blue.500'
					color='white'
					size='lg'
					fontSize='lg'
					variant='solid'
					width='100%'
					_hover={{
						bg: 'blue.300',
					}}
					onClick={handleSubmit(submitPayment)}>
					<T>Submit Payment Info</T>
				</CustomButton>
			</Footer>
		</Page>
	);
});
