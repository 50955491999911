import { Content, Header, Page } from 'components';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import { ApplicationPersonalInfo } from './personal-info';

export const ApplicationPage = observer(() => {
	const { applicationStore } = useStores();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const goToLastStep = searchParams.get('goToLastStep');

	const [currentStep, setCurrentStep] = useState<number>(goToLastStep ? 2 : 0);

	useEffect(() => {
		if (applicationStore.isSetupFinished && !applicationStore.isDraft) {
			navigate(RoutePath.applicationResult);
		}
	}, [applicationStore.isSetupFinished, applicationStore.isDraft, navigate]);

	useEffect(() => {
		if (goToLastStep) {
			setCurrentStep(2);
			setSearchParams([]);
		}
	}, [goToLastStep, setSearchParams, setCurrentStep]);

	const canGoBack = currentStep > 0;

	const goBack = () => {
		if (currentStep > 0) {
			setCurrentStep((step) => step - 1);
		} else {
			navigate(RoutePath.benefits);
		}
	};

	const nextStep = useCallback(() => {
		if (currentStep < 3) {
			setCurrentStep(currentStep + 1);
		} else {
			navigate(RoutePath.applicationConfirm);
		}
	}, [currentStep, setCurrentStep, navigate]);

	return (
		<Page>
			<Header canGoBack onClickBack={goBack} />
			<Content>
				<ApplicationPersonalInfo currentStep={currentStep} next={nextStep} />
			</Content>
		</Page>
	);
});
