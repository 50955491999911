import { EditIcon } from '@chakra-ui/icons';
import {
	Card,
	CardBody,
	Container,
	FormControl,
	FormLabel,
	HStack,
	Heading,
	Input,
	PinInput,
	PinInputField,
	Stack,
	Text,
} from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AlertError } from './alert-response-error';
import { CustomButton } from './custom-button';

type EmailValidationModalProps = {
	email: string;
	canEdit: boolean;
	isErrorCodeSend: boolean;
	isErrorValidateEmail: boolean;
	validateEmail: (code: string) => Promise<void>;
	dispatchCode: () => Promise<void>;
	onEmailChanged?: (email: string) => Promise<void>;
};

export const EmailValidationModal = observer(
	({
		email,
		canEdit,
		isErrorCodeSend,
		isErrorValidateEmail,
		validateEmail: validateEmailProps,
		dispatchCode,
		onEmailChanged,
	}: EmailValidationModalProps) => {
		const { t } = useTranslate();
		const [error, setError] = useState(null);
		const [currentEmail, setCurrentEmail] = useState(email ?? '');
		const [isChanging, setIsChanging] = useState(false);
		const [allowedToSubmit, setAllowedToSubmit] = useState(false);
		const isInvalid = !!error;
		const validateEmail = async (code: string) => {
			try {
				await validateEmailProps(code);
				analyticsManager.track(AnalyticsTrack.EmailConfirmed());
			} catch (error) {
				setError(error);
			}
		};

		useEffect(() => {
			setAllowedToSubmit(
				!!String(currentEmail)
					.toLowerCase()
					.match(
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					)
			);
		}, [currentEmail]);

		useEffect(() => {
			dispatchCode();
			analyticsManager.page(AnalyticsPage.General.ConfirmEmail);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const getError = () => {
			if (error) {
				return error;
			} else if (isErrorCodeSend) {
				return new Error('Something went wrong, please try again.');
			} else if (isErrorValidateEmail) {
				return new Error('Something went wrong, please try again.');
			}
		};

		return (
			<>
				<Container maxW='container.md' padding='0'>
					<Stack spacing='3'>
						<Heading
							fontSize='2xl'
							fontWeight='700'
							_light={{ color: 'gray.900' }}>
							<T>Confirm your email address</T>
						</Heading>
						<T>We just want to make sure you're a human and not a robot!</T>
						{!isChanging && (
							<>
								<Text
									_light={{ color: 'gray.900' }}
									fontSize='md'
									fontWeight='500'>
									<T>We’ve sent you a confirmation code to:</T>
								</Text>
								<Card
									border='1'
									borderStyle='solid'
									borderColor='#D8D8E5'
									borderRadius='12px'
									gap='30px'>
									<CardBody padding='30px'>
										<Stack direction='row' alignItems='center'>
											<Heading
												className='truncate'
												fontSize='lg'
												fontWeight='600'
												_light={{ color: 'gray.800' }}
												letterSpacing='-1%'>
												{currentEmail}
											</Heading>
											{canEdit && (
												<EditIcon
													alignSelf='right'
													color='blue'
													onClick={() => {
														setIsChanging(true);
														setCurrentEmail('');
														setError(null);
													}}
												/>
											)}
										</Stack>
									</CardBody>
								</Card>
								<Text fontWeight='400' fontSize='md' color='gray.900'>
									<T>Once you get the message, fill the code above:</T>
								</Text>
								<HStack width='fit-content'>
									<PinInput
										otp
										autoFocus
										isInvalid={isInvalid}
										onChange={() => {
											setError(null);
										}}
										onComplete={(val) => validateEmail(val)}
										type='number'>
										<PinInputField />
										<PinInputField />
										<PinInputField />
										<PinInputField />
									</PinInput>
								</HStack>
								<AlertError error={getError()} />
								<CustomButton
									size='lg'
									bg='blue.500'
									color='white'
									width='100%'>
									<T>Confirm code</T>
								</CustomButton>
							</>
						)}
						{isChanging && (
							<>
								<FormControl>
									<FormLabel fontSize='md' color='gray.900' fontWeight='400'>
										<T>What's your email address?</T>
									</FormLabel>
									<Input
										autoFocus
										type='email'
										value={currentEmail}
										size='lg'
										bg='gray.100'
										placeholder={t('Enter email address')}
										onChange={(val) => {
											setCurrentEmail(val.target.value);
										}}
									/>
								</FormControl>
								<AlertError error={getError()} />
								<CustomButton
									size='lg'
									bg='blue.500'
									color='white'
									width='100%'
									isDisabled={!allowedToSubmit}
									onClick={() => {
										onEmailChanged && onEmailChanged(currentEmail);
										setIsChanging(false);
										dispatchCode();
									}}>
									<T>Send code</T>
								</CustomButton>
							</>
						)}
					</Stack>
				</Container>
			</>
		);
	}
);
