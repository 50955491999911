import * as Sentry from '@sentry/react';
import { config } from 'config';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AuthGuard } from 'guards';
import { HomePage } from 'pages';
import { ApplicationPage } from 'pages/application';
import { ApplicationConfirmPage } from 'pages/application-confirm';
import { ApplicationPaymentPage } from 'pages/application-payment';
import { ApplicationQuotePage } from 'pages/application-quote';
import { ApplicationResultPage } from 'pages/application-result';
import {
	ForgotPasswordPage,
	LoginPage,
	RegisterPage,
	ResetPasswordPage,
} from 'pages/authentication';
import { ValidateEmailPage } from 'pages/authentication/validate-email';
import { BenefitsPage } from 'pages/benefits';
import { ErrorCatchPage, ErrorThrowPage } from 'pages/error';
import { InvitePage } from 'pages/invite';
import { PayPage } from 'pages/pay';
import { TimesheetPage } from "pages/timesheet";
import { ProfilePage } from 'pages/profile';
import { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import {
	Outlet,
	RouterProvider,
	createBrowserRouter,
	useLocation,
	useSearchParams,
} from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import './index.css';
import { Providers } from './providers';
import reportWebVitals from './reportWebVitals';
import { AddTimesheetEntry } from "pages/timesheet/components/add-timesheet-entry";
import { TimesheetHoursResume } from "pages/timesheet/components/timesheet-hours-resume";
import { EditTimesheetEntry } from "pages/timesheet/components/edit-timesheet-entry";

dayjs.extend(customParseFormat);

window.analytics = null;
// @ts-ignore
window.Intercom = null;

const router = createBrowserRouter([
	{
		element: (
			<ErrorBoundary
				fallbackRender={(error) => <ErrorCatchPage error={error} />}>
				<Outlet />
			</ErrorBoundary>
		),
		children: [
			{
				path: RoutePath.root,
				element: (
					<>
						<TrackUTMAndIdentify />
						<HomePage />
					</>
				),
			},
			{
				path: RoutePath.throwError,
				element: (
					<>
						<TrackUTMAndIdentify />
						<ErrorThrowPage />
					</>
				),
			},
			{
				path: RoutePath.quote,
				element: (
					<AuthGuard isLoggedIn={false}>
						<>
							<TrackUTMAndIdentify />
							<ApplicationQuotePage />
						</>
					</AuthGuard>
				),
			},
			{
				path: RoutePath.register,
				element: (
					<AuthGuard isLoggedIn={false}>
						<>
							<TrackUTMAndIdentify />
							<RegisterPage />
						</>
					</AuthGuard>
				),
			},
			{
				path: RoutePath.login,
				element: (
					<AuthGuard isLoggedIn={false}>
						<>
							<TrackUTMAndIdentify />
							<LoginPage />
						</>
					</AuthGuard>
				),
			},
			{
				path: RoutePath.forgotPassword,
				element: <ForgotPasswordPage />,
			},
			{
				path: RoutePath.resetPassword,
				element: <ResetPasswordPage />,
			},
			{
				path: RoutePath.validateEmail,
				element: (
					<AuthGuard isLoggedIn isEmailAuthenticated={false}>
						<ValidateEmailPage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.pay,
				element: (
					<AuthGuard>
						<PayPage />
					</AuthGuard>
				),
			},
			{
				path: `${RoutePath.timesheet}/:uuid`,
				element: (
					<AuthGuard>
						<TimesheetPage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.timesheet,
				element: (
					<AuthGuard>
						<TimesheetPage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.addTimesheetEntry(),
				element: (
					<AuthGuard>
						<AddTimesheetEntry />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.editTimesheetEntry(),
				element: (
					<AuthGuard>
						<EditTimesheetEntry />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.timesheetHoursResume(),
				element: (
					<AuthGuard>
						<TimesheetHoursResume />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.benefits,
				element: (
					<AuthGuard>
						<BenefitsPage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.profile,
				element: (
					<AuthGuard>
						<ProfilePage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.invite,
				element: (
					<AuthGuard>
						<InvitePage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.application,
				element: (
					<AuthGuard>
						<>
							<TrackUTMAndIdentify />
							<ApplicationPage />
						</>
					</AuthGuard>
				),
			},
			{
				path: RoutePath.applicationConfirm,
				element: (
					<AuthGuard>
						<ApplicationConfirmPage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.applicationResult,
				element: (
					<AuthGuard>
						<ApplicationResultPage />
					</AuthGuard>
				),
			},
			{
				path: RoutePath.applicationPayment,
				element: (
					<AuthGuard>
						<ApplicationPaymentPage />
					</AuthGuard>
				),
			},
		],
	},
]);

Sentry.init({
	dsn: config.sentry.dsn,
	enabled: config.sentry.enabled,
	environment: config.sentry.environment,
	integrations: [
		new Sentry.BrowserTracing({
			tracePropagationTargets: [/gigeasy.com/],
		}),
	],
	tracesSampleRate: config.sentry.tracesSampleRate,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

function TrackUTMAndIdentify() {
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { utmStore } = useStores();

	useLayoutEffect(() => {
		if (searchParams.get('utm_source')) {
			utmStore.load({
				utm_source: searchParams.get('utm_source'),
				utm_medium: searchParams.get('utm_medium'),
				utm_campaign: searchParams.get('utm_campaign'),
				utm_term: searchParams.get('utm_term'),
				utm_content: searchParams.get('utm_content'),
			});
		}
		// eslint-disable-next-line
	}, [location, window.analytics]);

	return <></>;
}

function App() {
	return (
		<Providers>
			<RouterProvider router={router} />
		</Providers>
	);
}

root.render(<App />);

if (!config.is_production && config.enable_webvitals) {
	reportWebVitals(console.log);
}
