import {
	Button,
	Divider,
	InputGroup,
	InputRightElement,
	Spinner,
	Stack,
	Text,
} from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { CustomFormControl, CustomInput } from 'components/form';
import { useApplicationDependentMutation } from 'hooks';
import { observer } from 'mobx-react';
import {
	Application,
	ApplicationDependent,
	ApplicationProductStatus,
	ApplicationResult,
} from 'models';
import { useMemo } from 'react';
import { Form, useForm } from 'react-hook-form';
import { useStores } from 'stores';
import { currencyFormat } from 'utils';
import { ApplicationPlanInsurancyTypeSummary } from './application-plan-insurance-type-summary';

type ApplicationPlanSummaryProps = {
	isLoading: boolean;
	isRefetching: boolean;
	edit?: boolean;
	showDiscountInput?: boolean;
	refetch?: () => void;
	openDeclineReasons?: () => void;
	result: ApplicationResult;
	dependents: ApplicationDependent[];
};

export const ApplicationPlanSummary = observer(
	({
		openDeclineReasons,
		edit,
		result,
		dependents,
		isLoading,
		isRefetching,
		refetch,
		showDiscountInput,
	}: ApplicationPlanSummaryProps) => {
		const { t } = useTranslate();
		const { authenticationStore, applicationStore } = useStores();

		const applicationDependentMutation = useApplicationDependentMutation(
			applicationStore.application.uuid
		);
		const planIdToStatus: { [key: string]: ApplicationProductStatus } = useMemo(
			() =>
				(applicationStore.application.products ?? []).reduce(
					(acc, curr) => ({ ...acc, [curr.product_id]: curr.status }),
					{}
				),
			[applicationStore.application.products]
		);

		const defaultValues = {
			discount_code: applicationStore.application.discount_code ?? '',
		};

		const {
			register,
			control,
			getValues,
			formState: { errors },
		} = useForm<Pick<Application, 'discount_code'>>({
			defaultValues,
			mode: 'onBlur',
		});

		const updateApplication = async (data) => {
			await applicationStore.updateApplication({
				...data,
			});
			applicationStore.reload();
			refetch();
		};

		const eligibleEntries = useMemo(() => {
			return Object.entries(result?.insurance_types?.eligible ?? {});
		}, [result?.insurance_types?.eligible]);

		const taxes = useMemo(() => {
			return Object.entries(result?.taxes ?? {});
		}, [result?.taxes]);

		const discounts = useMemo(() => {
			return Object.entries(result?.discounts ?? {});
		}, [result?.discounts]);

		const changeInsuranceType = async (
			uuidApplicationDependent: string,
			insuranceType: string,
			add: boolean
		) => {
			const currentInsuranceTypes = eligibleEntries
				.map(
					([
						insuranceType,
						planInsuranceType,
					]: (typeof eligibleEntries)[number]) => {
						const dependents = planInsuranceType.dependents ?? {};
						return dependents[uuidApplicationDependent] === undefined
							? null
							: insuranceType;
					}
				)
				.filter(Boolean);
			const newInsyranceTypes = add
				? [...currentInsuranceTypes, insuranceType]
				: currentInsuranceTypes.filter((t) => t !== insuranceType);
			await applicationDependentMutation.mutateAsync({
				method: 'UPSERT',
				uuid: uuidApplicationDependent,
				body: {
					insurance_types: newInsyranceTypes,
				},
			});
		};

		const renderTax = ([_, tax]: (typeof taxes)[number]) => (
			<div className='mt-2 flex flex-row justify-between' key={tax.label}>
				<Text fontWeight='medium' fontSize='sm'>
					{tax.label}
				</Text>
				<div className='flex gap-3'>
					{isLoading && <Spinner color='blue.500' size='sm' />}
					<Text fontWeight='medium' fontSize='sm'>
						{currencyFormat(tax.value)}
					</Text>
				</div>
			</div>
		);

		const renderDiscount = ([_, discount]: (typeof discounts)[number]) => (
			<div className='mt-2 flex flex-row justify-between' key={discount.label}>
				<Text fontWeight='medium' fontSize='sm'>
					{discount.label}
				</Text>
				<div className='flex gap-3'>
					{isLoading && <Spinner color='blue.500' size='sm' />}
					<Text fontWeight='medium' fontSize='sm'>
						{discount.value === 0
							? `${currencyFormat(0)}`
							: `-${currencyFormat(discount.value)}`}
					</Text>
				</div>
			</div>
		);

		const renderPlan = ([
			insuranceType,
			planInsuranceType,
		]: (typeof eligibleEntries)[number]) => (
			<ApplicationPlanInsurancyTypeSummary
				key={`${insuranceType}-${planInsuranceType.product_id}`}
				status={planIdToStatus[planInsuranceType.product_id]}
				openDeclineReasons={openDeclineReasons}
				isLoading={isLoading || isRefetching}
				customer={authenticationStore.customer}
				planInsuranceType={planInsuranceType}
				edit={edit}
				dependents={dependents}
				onPlanChange={(uuidDependent, add) =>
					changeInsuranceType(uuidDependent, insuranceType, add)
				}
			/>
		);

		const noDiscountFound =
			applicationStore.application.discount_code &&
			(result?.discounts ?? []).filter(
				(discount) => discount.type === 'discount-code'
			).length === 0;

		return (
			<>
				<Text fontSize='xl' fontWeight='semibold' paddingTop='4'>
					<T>Plan summary</T>
				</Text>

				{eligibleEntries.map(renderPlan)}
				<Divider className='mt-4' borderStyle='dashed' />

				{showDiscountInput && (
					<Form control={control}>
						<Stack spacing='6'>
							<CustomFormControl
								label={t('Discount code')}
								onBlur={() => updateApplication(getValues())}
								error={
									errors.discount_code ||
									(noDiscountFound && {
										message: 'No discount is available for this code.',
									})
								}>
								<InputGroup size='md'>
									<CustomInput
										type='text'
										{...register('discount_code')}
										placeholder={t('Enter code')}
									/>
									<InputRightElement width='4.5rem'>
										<Button
											h='1.75rem'
											size='sm'
											onClick={() => updateApplication(getValues())}>
											Apply
										</Button>
									</InputRightElement>
								</InputGroup>
							</CustomFormControl>
						</Stack>
					</Form>
				)}

				{taxes.map(renderTax)}

				<div className='mt-2 flex flex-row justify-between'>
					<Text fontWeight='bold' fontSize='sm' _light={{ color: 'gray.900' }}>
						<T>Gross total</T>
					</Text>
					<div className='flex gap-3'>
						<Text
							fontWeight='bold'
							fontSize='sm'
							_light={{ color: 'gray.900' }}>
							{currencyFormat(result?.gross || result?.total)}
						</Text>
					</div>
				</div>

				{result?.paid > 0 && result?.pending > 0 && (
					<div className='mt-2 flex flex-row justify-between'>
						<Text
							fontWeight='bold'
							fontSize='sm'
							_light={{ color: 'gray.900' }}>
							<T>Already paid</T>
						</Text>
						<div className='flex gap-3'>
							<Text
								fontWeight='bold'
								fontSize='sm'
								_light={{ color: 'gray.900' }}>
								{currencyFormat(result.paid)}
							</Text>
						</div>
					</div>
				)}

				{discounts.map(renderDiscount)}

				<div className='mt-2 flex flex-row justify-between'>
					<Text fontWeight='700' fontSize='xl' _light={{ color: 'gray.900' }}>
						<T>{result?.pending > 0 ? 'To pay' : 'Total'}</T>
					</Text>
					<div className='flex gap-3'>
						<Text fontWeight='700' fontSize='xl' _light={{ color: 'gray.900' }}>
							{currencyFormat(result?.pending || result?.total)}
						</Text>
					</div>
				</div>
			</>
		);
	}
);
