import { Select, SelectProps } from '@chakra-ui/react';
import { useLocationStates } from 'hooks';
import { forwardRef } from 'react';

type LocationStateSelect = SelectProps;

export const LocationStateSelect = forwardRef(
	(props: LocationStateSelect, ref) => {
		const { data: loadedStates = [] } = useLocationStates();
		return (
			<Select size='lg' _light={{ bg: 'gray.100' }} ref={ref} {...props}>
				<option value={''}></option>
				{loadedStates.map((state) => (
					<option key={state.id} value={state.id}>
						{state.name}
					</option>
				))}
			</Select>
		);
	}
);
