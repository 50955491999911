import dayjs from 'dayjs';

export const isValidDate = (date: string) => {
	return dayjs(date, 'MM/DD/YYYY', true).isValid() ? true : 'Invalid date.';
};

export const isValidAge = (date: string) => {
	const birthday = dayjs(date, 'MM/DD/YYYY', true);
	return birthday.isValid() && birthday.isBefore(dayjs())
		? true
		: 'Invalid birthday.';
};

export const containsAtLeastOneLetter = (input: string) => {
	return /[a-zA-Z]/.test(input ?? '')
		? true
		: 'It should contain at least one letter.';
};

export const containsAtLeastOneNumber = (input: string) => {
	return /[0-9]/.test(input ?? '')
		? true
		: 'It should contain at least one number.';
};

export const containsAtLeastOneSymbol = (input: string) => {
	return /[^a-zA-Z0-9\s]/.test(input ?? '')
		? true
		: 'It should contain at least one symbol.';
};
