import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ThemeTypings,
} from '@chakra-ui/react';
import React from 'react';

type DialogConfirmDeleteProps = {
	isOpen: boolean;
	onClose(): void;
	onConfirm(): void;
	header: string;
	message: string;
	colorScheme?: ThemeTypings["colorSchemes"];
};

export const ConfirmDialog = ({
	isOpen,
	header,
	message,
	onClose,
	onConfirm,
	colorScheme = 'red',
}: DialogConfirmDeleteProps) => {
	const cancelRef = React.useRef();

	return (
		<>
			<AlertDialog
				motionPreset='slideInBottom'
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				isOpen={isOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>{header}</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>{message}</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							No
						</Button>
						<Button
							colorScheme={colorScheme}
							ml={3}
							onClick={() => {
								onConfirm();
								onClose();
							}}>
							Yes
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};
