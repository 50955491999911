import { Endpoints, ErrorResponse, api } from 'api';
import { makeAutoObservable } from 'mobx';
import { getPersistedStore, makePersistable } from 'mobx-persist-store';
import { InsuranceType } from 'models';
import { Quote } from 'models/quote';
import { Logger, Status } from 'utils';

export class QuoteStore {
	quote: Quote = {};

	error?: ErrorResponse = undefined;

	status?: Status = Status.Idle;

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'QuoteStore',
			properties: ['quote'],
			storage: window.localStorage,
		});
		this.load();
	}

	get isLoading() {
		return this.status === Status.Pending;
	}

	setStatus(status: Status) {
		this.status = status;
	}

	setQuote(quote: Quote) {
		this.quote = quote;
	}

	async load(local: boolean = true) {
		this.setStatus(Status.Pending);
		if (local) {
			await getPersistedStore(this);
		}
		const uuid = this.quote.uuid;
		try {
			const { data: quote } = uuid
				? await this.fetchQuote(uuid, { createIfNotFound: true })
				: await this.createQuote();
			this.setQuote({});
			this.updateQuote(quote, false);
			this.setStatus(Status.Resolved);
		} catch (error) {
			Logger.error({
				message: 'Error loading the quote from remote api',
				extraData: this.quote,
				exception: error,
			});
			this.error = error;
			this.setStatus(Status.Rejected);
		}
	}

	async reload() {
		return this.load(false);
	}

	async getUpdatedQuote(): Promise<Quote> {
		return this.quote;
	}

	async fetchQuote(
		uuid: string,
		{ createIfNotFound }: { createIfNotFound: boolean }
	) {
		try {
			return await api.get<Quote>(Endpoints.quotes(uuid));
		} catch (error) {
			if (createIfNotFound && error.status === 404) {
				return this.createQuote();
			}
			throw error;
		}
	}

	async createQuote() {
		return api.post<Quote, {}>(Endpoints.quotes(), {});
	}

	async patchQuote(partial: Partial<Quote>) {
		return api.patch<Quote, Partial<Quote>>(
			Endpoints.quotes(this.quote.uuid),
			partial
		);
	}

	async linkWithCustomer() {
		return api.post(Endpoints.quotesAttachCustomer(this.quote.uuid));
	}

	async updateQuote(partial: Partial<Quote>, patchRemote?: boolean) {
		this.setQuote({
			...this.quote,
			...partial,
		});

		if (patchRemote === undefined || patchRemote) {
			await this.patchQuote(partial);
		}
	}

	async toggleInsuranceType({ code }: Pick<InsuranceType, 'code'>) {
		let updatedList = Object.assign([], this.quote.insurance_types ?? []);

		if (updatedList.includes(code)) {
			updatedList = updatedList.filter((e) => e != code);
		} else {
			updatedList.push(code);
		}

		await this.updateQuote({ insurance_types: updatedList }, true);
	}

	clean() {
		this.setQuote({});
	}
}
