import { useParams } from "react-router-dom";
import { 
	Page, 
	Header, 
	TabsFooter, 
	Content 
} from 'components';
import { TimesheetHours } from "./timesheet";
import { ContractsList } from './contracts';
import { useStores } from "stores";
import { TimesheetEmptyState } from "./timesheet-empty-state";

export const TimesheetPage = () => {
	const { uuid } = useParams();
	const { authenticationStore: { organizations } } = useStores();
	
	return (
		<Page>
			<Header canGoBack={false} />
			<Content scrollY paddingX className='flex flex-col'>
				{organizations.length == 0 && <TimesheetEmptyState />}
				{organizations.length > 0 && (uuid ? <TimesheetHours /> : <ContractsList />)}
			</Content>
			<TabsFooter />
		</Page>
	);
}