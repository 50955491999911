import { Endpoints, api } from 'api';
import { useEffect, useState } from 'react';

export const ErrorThrowPage = () => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const load = async () => {
			try {
				await api.get(Endpoints.throwError);
			} finally {
				setIsLoading(false);
			}
		};
		load();
	}, []);

	if (isLoading) {
		return <></>;
	}
	throw Error(
		'Testing Errors. Error intentionally called by the /throw-error route.'
	);
};
