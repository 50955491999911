import {
	Heading,
} from "@chakra-ui/react";
import { T } from '@tolgee/react';
import { useParams, useSearchParams } from "react-router-dom";
import { startOfWeek } from 'date-fns';
import { useStores } from "stores";
import { SelectDateRange } from "components/form/custom-range-date-picker";
import { Content, CustomButton } from "components";
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { TimesheetEntries } from "./timesheet-entries";
import { useEffect, useState } from "react";
import { getStartAndEndOfWeek } from "utils/date/get-start-and-end-of-week";
import { dateToString } from "utils/date/date-to-string";
import { dateStringToDate } from "utils/date/date-string-to-date";
import { useMutationTimesheetEntrySubmit } from "hooks/timesheet/mutations";
import { ConfirmDialog } from 'components/confirm/confirm-dialog';
import { useTimesheetEntries } from "hooks/timesheet/queries";
import { useToast } from "hooks/toast";
import { EntryState } from "./constants/entry-state";

export const TimesheetHours = () => {
	const { authenticationStore: { organizations } } = useStores();
	const { toastSuccess } = useToast();
	
	const { uuid } = useParams();
	const currentOrg = organizations.find(org => org.uuid === uuid);
	
	const [ searchParams, setSearchParams ] = useSearchParams();
	
	const start = searchParams.get('start');
	const end = searchParams.get('end');
	const currentDateRef = (start && end)
	? { start: dateStringToDate(start), end: dateStringToDate(end) }
	: getStartAndEndOfWeek(startOfWeek(new Date()));
	
	const [ dateRef, setDateRef ] = useState(currentDateRef);
	const [ isConfirmDialogOpen, setIsConfirmDialogOpen ] = useState(false);

	const {
		data = [],
		refetch,
	} = useTimesheetEntries(uuid, dateToString(dateRef.start), dateToString(dateRef.end));
	
	const handleRangeDatePickerChange = (dateRangeSelected: Date[]) => {
		if (dateRangeSelected.length === 1) return;
		
		setDateRef({ start: dateRangeSelected[0], end: dateRangeSelected[1] });
		
		setSearchParams({
			start: dateToString(dateRangeSelected[0]),
			end: dateToString(dateRangeSelected[1]),
		});
	}

	
	const timesheetSubmitMutation = useMutationTimesheetEntrySubmit(uuid, dateToString(dateRef.start));
	const onSubmit = async () => {
		await timesheetSubmitMutation.mutateAsync();
		toastSuccess({
			message: "Hours submitted successfully",
			});
			refetch();
	};

	useEffect(() => {
		const entryState = searchParams.get('entryState');

		if (entryState === EntryState.ADDED) {
			toastSuccess({
				message: "Hours added successfully",
			});
		} else if (entryState === EntryState.UPDATED) {
			toastSuccess({
				message: "Hours updated successfully",
			});
		}

		searchParams.delete('entryState');
		setSearchParams(searchParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold' marginBottom={1}>
				<div className="flex">
					<div className="basis-1/2">
						Timesheet
						<div className="flex text-sm font-semibold text-blue-500 items-center">
							{currentOrg.name} <EditIcon className="ml-2" />
						</div>
					</div>
					<div className="flex basis-1/2 justify-end items-center">
						<CustomButton
							bg='blue.500'
							color='white'
							variant='solid'
							_hover={{ bg: 'blue.300' }}
							onClick={() => setIsConfirmDialogOpen(true)}>
							<T>Submit</T>
						</CustomButton>
					</div>
				</div>
			</Heading>
			<Content scrollY>
				<div className="mb-4">
					<SelectDateRange dateRef={dateRef} onChange={handleRangeDatePickerChange} />
				</div>
				<TimesheetEntries dateRef={dateRef} entries={data} />

				<ConfirmDialog
					colorScheme='primary'
					isOpen={isConfirmDialogOpen}
					onClose={() => setIsConfirmDialogOpen(false)}
					onConfirm={() => onSubmit()}
					header={"Submit Hours"}
					message={"You are about to submit your hours for approval. Please review and confirm if the information provided is correct."}
				/>
			</Content>
		</>
	);
}
