import { config } from 'config';

export const RoutePath = {
	root: '/',

	login: '/login',
	register: '/register',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	validateEmail: '/validate-email',
	onboardingRedirect: `${config.core_api}/individual/onboarding/redirect`,

	quote: '/quote',

	throwError: '/throw-error',

	application: '/application',
	applicationConfirm: '/application/confirm',
	applicationPayment: '/application/payment',
	applicationResult: '/application/result',

	pay: '/pay',
	timesheet: '/timesheet',
	addTimesheetEntry: (orgUuid: string = ":orgUuid", date: string = ":date") => `/timesheet/${orgUuid}/add/${date}`,
	editTimesheetEntry: (orgUuid: string = ":orgUuid", date: string = ":entryUuid") => `/timesheet/${orgUuid}/${date}/edit`,
	timesheetHoursResume: (orgUuid: string = ":orgUuid", entryUuid: string = ":entryUuid") => `/timesheet/${orgUuid}/${entryUuid}`,
	benefits: '/benefits',
	profile: '/profile',
	invite: '/invite',
} as const;
