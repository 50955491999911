import dayjs from 'dayjs';

export const DEFAULT_API_DATE_FORMAT = "yyyy-MM-dd";

export const formatDateIfValid = (
	date: string,
	format_from: string,
	format_to: string,
	strict: boolean = true
) => {
	return dayjs(date, format_from, strict).isValid()
		? dayjs(date, format_from).format(format_to)
		: null;
};

export const millisecondsIn = {
	second: 1000,
	minute: 60 * 1000,
	hour: 60 * 60 * 1000,
	day: 24 * 60 * 60 * 1000,
	week: 7 * 24 * 60 * 60 * 1000,
};

export const getDateRange = (dateRange: Date[]): Date[] => {
	const currentRange = [];

	for (let i = dateRange[0].getUTCDate(); i <= dateRange[1].getUTCDate(); i++) {
		const date = new Date(dateRange[0].getUTCFullYear(), dateRange[0].getUTCMonth(), i);
		
		currentRange.push(date);
	}

	return currentRange;
}