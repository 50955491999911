import { useNavigate, useParams } from "react-router-dom";
import { Heading } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Content, Header, Page } from 'components';
import { useStores } from "stores";
import { RoutePath } from "routes";
import { TimesheetEntryAPIDTO } from "models/timesheet";
import { TimesheetEntryForm } from "./timesheet-entry-form";
import { useUpdateMutationTimesheetEntry } from "hooks/timesheet/mutations";
import { useTimesheetEntry } from "hooks/timesheet/queries";
import { EntryState } from "../constants/entry-state";

export const EditTimesheetEntry = () => {
	const navigate = useNavigate();
	const params = useParams<{ orgUuid: string, entryUuid: string }>();
	const store = useStores();
	const currentOrg = store.authenticationStore.organizations.find(org => org.uuid === (params.orgUuid));

	const {
		data: timesheetEntry,
		isLoading,
	} = useTimesheetEntry(params.orgUuid, params.entryUuid);
	
	const mutationTimesheetEntry = useUpdateMutationTimesheetEntry(
		params.orgUuid,
		params.entryUuid,
		timesheetEntry?.date
	);

	const handleSubmit = async (formValues: TimesheetEntryAPIDTO) => {
		await mutationTimesheetEntry.mutateAsync(formValues);
		navigate(`${RoutePath.timesheet}/${params.orgUuid}?date=${timesheetEntry.date}&entryState=${EntryState.ADDED}`)
	};

	return (
		<Page>
			<Header canGoBack={false} />
			<Content paddingX className='flex flex-col'>
				<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold' marginBottom={7}>
					<div className="flex justify-between pr-1">
						<div>Edit Entry</div>
						<div className="cursor-pointer" onClick={() => navigate(`${RoutePath.timesheet}/${currentOrg.uuid}`)}><CloseIcon /></div>
					</div>
				</Heading>
				<Content scrollY>
					{!isLoading && <TimesheetEntryForm data={timesheetEntry} onSubmit={handleSubmit} />}
				</Content>
			</Content>
		</Page>
	);
}