import { T } from '@tolgee/react';
import { AnalyticsTrack, analyticsManager } from 'analytics';
import { analyticsMapFromPlanResult } from 'analytics/mapper';
import { Content, CustomButton, Footer, Header, Page } from 'components';
import { useApplicationDependents, useApplicationResults } from 'hooks';
import { observer } from 'mobx-react';
import { ApplicationStatus } from 'models';
import { useEffect, useState } from 'react';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import { Logger } from 'utils';
import { ApplicationConfirmBenefits } from './benefits';
import { ApplicationConfirmDependents } from './dependents';

export const ApplicationConfirmPage = observer(() => {
	const { applicationStore } = useStores();
	const [currentStep, setCurrentStep] = useState<number>(1);
	const [searchParams, setSearchParams] = useSearchParams();
	const goToLastStep = searchParams.get('goToLastStep');

	const navigate = useNavigate();

	useEffect(() => {
		if (!applicationStore.isDraft) {
			navigate(RoutePath.applicationResult);
		}
	}, [applicationStore.isDraft, navigate]);

	useEffect(() => {
		if (goToLastStep) {
			setCurrentStep(2);
			setSearchParams([]);
		}
	}, [goToLastStep, setCurrentStep, setSearchParams]);

	const { data: applicationResult, isLoading: isLoadingResult } =
		useApplicationResults(applicationStore.application.uuid);

	const { data: dependents = [], isLoading: isLoadingDependentsResult } =
		useApplicationDependents(applicationStore.application.uuid);

	const isLoading = isLoadingDependentsResult || isLoadingResult;

	const goBack = () => {
		if (currentStep === 1) {
			navigate({
				pathname: RoutePath.application,
				search: `?${createSearchParams([['goToLastStep', 'true']])}`,
			});
		} else {
			setCurrentStep(currentStep - 1);
		}
	};

	const nextStep = async () => {
		if (currentStep === 1) {
			analyticsManager.track(
				AnalyticsTrack.ApplicationResult(
					analyticsMapFromPlanResult(applicationResult, dependents.length)
				)
			);
			setCurrentStep(2);
		} else if (currentStep === 2) {
			const analyticsInformation = analyticsMapFromPlanResult(
				applicationResult,
				dependents.length
			);
			analyticsManager.track(
				AnalyticsTrack.ApplicationDependentsPlansSubmitted({
					...analyticsInformation,
					selected_insurance: analyticsInformation.selected_insurance.map(
						(s) => ({
							...s,
							total_dependents: s.total_dependents ?? 0,
						})
					),
				})
			);

			if (applicationResult.total === 0) {
				await applicationStore.patchApplication({
					status: ApplicationStatus.Submitted,
				});

				await applicationStore.reload();

				return;
			}

			navigate(RoutePath.applicationPayment);
		}
	};

	const renderContent = () => {
		switch (currentStep) {
			case 1:
				return <ApplicationConfirmBenefits dependents={dependents} />;
			case 2:
				return <ApplicationConfirmDependents dependents={dependents} />;
			default:
				Logger.error({ message: 'This page does not exist' });
		}
	};

	const isNextDisabled = () => {
		if (
			currentStep === 1 &&
			Object.keys(applicationResult?.insurance_types?.eligible ?? {}).length ===
				0
		) {
			return true;
		}
		return false;
	};

	return (
		<Page>
			<Header canGoBack onClickBack={goBack} />
			<Content paddingX scrollY isLoading={isLoading || !!goToLastStep}>
				{renderContent()}
			</Content>
			<Footer>
				<CustomButton
					bg='blue.500'
					color='white'
					size='lg'
					fontSize='lg'
					variant='solid'
					width='100%'
					_hover={{
						bg: 'blue.300',
					}}
					isDisabled={isNextDisabled()}
					onClick={nextStep}>
					<T>Next</T>
				</CustomButton>
			</Footer>
		</Page>
	);
});
