import { InfoIcon } from '@chakra-ui/icons';
import { Stack, Switch, Text } from '@chakra-ui/react';
import {
	AutoComplete,
	AutoCompleteInput,
	AutoCompleteItem,
	AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import {
	Content,
	CustomButton,
	CustomFormControl,
	CustomInput,
	Footer,
	Page,
} from 'components';
import { useProfession } from 'hooks/use-profession';
import { observer } from 'mobx-react';
import { Profession } from 'models/profession';
import { useEffect } from 'react';
import { useStores } from 'stores';

type ApplicationPersonalInfoProfessionProps = {
	next: () => void;
};

export const ApplicationPersonalInfoProfession = observer(
	({ next }: ApplicationPersonalInfoProfessionProps) => {
		const { t } = useTranslate();
		const { applicationStore } = useStores();
		const { data: loadedProfessions = [], isLoading } = useProfession();

		if (!applicationStore.application.extras.need_profession) {
			next();
		}

		useEffect(() => {
			analyticsManager.page(AnalyticsPage.Application.PersonalInfoProfession);
		}, []);

		const onSubmit = () => {
			analyticsManager.track(
				AnalyticsTrack.ApplicationProfessionSubmitted({
					profession:
						applicationStore.application.profession ??
						applicationStore.application.profession_custom,
				})
			);
			next();
		};

		return (
			<Page>
				<Content paddingX scrollY isLoading={isLoading}>
					<Stack spacing='6'>
						<CustomFormControl label={t('What is your profession?')}>
							<AutoComplete
								defaultValues={[applicationStore.application.profession]}
								emptyState={<T>Not found any results</T>}
								isReadOnly={applicationStore.hasCustomProfession}
								onChange={(e) => {
									const profession_id = loadedProfessions.find(
										(i) => i.name === e
									).id;

									applicationStore.updateApplication({
										profession_id,
									});
								}}
								openOnFocus>
								<AutoCompleteInput
									variant='filled'
									placeholder={t('Type to search...')}
									required
								/>
								<AutoCompleteList>
									{loadedProfessions.map((profession: Profession) => (
										<AutoCompleteItem
											key={`option-${profession.id}`}
											value={profession.name}>
											{profession.name}
										</AutoCompleteItem>
									))}
								</AutoCompleteList>
							</AutoComplete>
						</CustomFormControl>
						<Stack spacing='3' direction='row' align='center'>
							<InfoIcon width='19px' height='24px' color='blue.500' />
							<Text
								fontWeight={400}
								fontSize='xs'
								color='gray.500'
								lineHeight='16px'>
								<T>
									Choose the profession that closest describes your current
									position.
								</T>{' '}
							</Text>
						</Stack>
						<CustomFormControl
							display='flex'
							justifyContent={'space-between'}
							label={t('My profession is not listed')}>
							<Switch
								isChecked={applicationStore.hasCustomProfession}
								onChange={(e) =>
									applicationStore.setHasCustomProfession(e.target.checked)
								}
								value={1}
								size={'lg'}
								colorScheme={'blue'}
							/>
						</CustomFormControl>
						{applicationStore.hasCustomProfession && (
							<>
								<CustomFormControl>
									<CustomInput
										type='text'
										defaultValue={
											applicationStore.application.profession_custom
										}
										onBlur={(e) => {
											applicationStore.updateApplication({
												profession_custom: e.target.value,
											});
										}}
									/>
								</CustomFormControl>
								<Stack spacing='3' direction='row' align='center'>
									<InfoIcon width='19px' height='24px' color='blue.500' />
									<Text
										fontWeight={400}
										fontSize='xs'
										color='gray.500'
										lineHeight='16px'>
										<T>
											Only use this if the current profession does not match any
											of the options above.
										</T>{' '}
									</Text>
								</Stack>
							</>
						)}
					</Stack>
				</Content>

				<Footer>
					<CustomButton
						bg='blue.500'
						color='white'
						size='lg'
						fontSize='lg'
						variant='solid'
						width='100%'
						_hover={{
							bg: 'blue.300',
						}}
						isDisabled={
							(applicationStore.hasCustomProfession &&
								applicationStore.application.profession_custom === null) ||
							(!applicationStore.hasCustomProfession &&
								applicationStore.application.profession_id === null)
						}
						onClick={() => onSubmit()}>
						<T>Next</T>
					</CustomButton>
				</Footer>
			</Page>
		);
	}
);
