import { Text, useColorMode } from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { ReactComponent as HeartBeatIcon } from 'assets/heartbeat-solid.svg';
import { ReactComponent as ProfileIcon } from 'assets/profile.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet-solid.svg';
import { BusinessTimeIcon } from "react-line-awesome";
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

export const TabsFooter = () => {
	const navigate = useNavigate();
	const { colorMode } = useColorMode();
	const { navigationStore } = useStores();

	return (
		<div
			className={`${
				colorMode === 'dark' ? 'bg-[#24303F]' : 'bg-[#FAFAFA]'
			} w-full  border-t p-2 flex flex-row justify-center items-center`}>
			<div className='w-full max-w-3xl flex flex-row justify-around'>
				<div
					className={`flex flex-col items-center justify-center hover:text-primary cursor-pointer ${
						navigationStore.currentTab === 'benefits' ? 'text-primary' : ''
					}`}
					onClick={() => {
						navigationStore.setCurrentTab('benefits');
						navigate(RoutePath.benefits);
					}}>
					<HeartBeatIcon width={32} height={32} />
					<Text>
						<T>Benefits</T>
					</Text>
				</div>
				<div
					className={`flex flex-col items-center justify-center hover:text-primary cursor-pointer ${
						navigationStore.currentTab === 'pay' ? 'text-primary' : ''
					}`}
					onClick={() => {
						navigationStore.setCurrentTab('pay');
						navigate(RoutePath.pay);
					}}>
					<WalletIcon width={32} height={32} />
					<Text>
						<T>Pay</T>
					</Text>
				</div>
				<div
					className={`flex flex-col items-center justify-center hover:text-primary cursor-pointer ${
						navigationStore.currentTab === 'timesheet'
						|| navigationStore.currentTab === 'contracts' 
							? 'text-primary' 
							: ''
					}`}
					onClick={() => {
						navigationStore.setCurrentTab('contracts');
						navigate(RoutePath.timesheet);
					}}>
					<BusinessTimeIcon
						variant="solid"
						size="2x"
					/>
					<Text>
						<T>Timesheet</T>
					</Text>
				</div>
				<div
					className={`flex flex-col items-center justify-center hover:text-primary cursor-pointer ${
						navigationStore.currentTab === 'profile' ? 'text-primary' : ''
					}`}
					onClick={() => {
						navigationStore.setCurrentTab('profile');
						navigate(RoutePath.profile);
					}}>
					<ProfileIcon width={32} height={32} />
					<Text>
						<T>Profile</T>
					</Text>
				</div>
			</div>
		</div>
	);
};
