import {
	Badge,
	Button,
	Checkbox,
	Divider,
	Link,
	Spinner,
	Stack,
	Text,
} from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { AnalyticsTrack, analyticsManager } from 'analytics';
import { ReactComponent as AlertIcon } from 'assets/alert-icon.svg';
import {
	ApplicationDependent,
	Customer,
	PlanInsuranceTypeResult,
} from 'models';
import { ApplicationProductStatus } from 'models/enums';
import { useEffect, useMemo, useState } from 'react';
import { Logger, currencyFormat, formatCapitalize } from 'utils';

type ApplicationPlanInsurancyTypeSummaryProps = {
	customer: Customer;
	isLoading: boolean;
	status?: ApplicationProductStatus;
	planInsuranceType: PlanInsuranceTypeResult;
	dependents: ApplicationDependent[];
	edit: boolean;
	onPlanChange: (uuid: string, add: boolean) => void;
	openDeclineReasons?: () => void;
};

type Beneficiary = {
	uuid: string;
	name: string;
	price: number;
	isSelected: boolean;
	isDisabled: boolean;
	isSpouse?: boolean;
};

export const ApplicationPlanInsurancyTypeSummary = ({
	isLoading,
	customer,
	status,
	planInsuranceType,
	dependents,
	edit,
	onPlanChange,
	openDeclineReasons,
}: ApplicationPlanInsurancyTypeSummaryProps) => {
	const [dependentsSelected, setDependentsSelected] = useState<{
		[key: string]: number;
	}>({});
	useEffect(() => {
		if (!isLoading) {
			setDependentsSelected({ ...planInsuranceType.dependents });
		}
	}, [isLoading, planInsuranceType.dependents]);

	const product = useMemo(
		() =>
			planInsuranceType.products.find(
				(p) => p.id === planInsuranceType.product_id
			),
		[planInsuranceType.products, planInsuranceType.product_id]
	);

	const changeDependentPlans = (uuid: string, value: boolean) => {
		setDependentsSelected((d) => ({ ...d, [uuid]: value ? 0 : undefined }));
		onPlanChange(uuid, value);
	};

	const allBeneficiaries: Beneficiary[] = useMemo(() => {
		const fromCustomer = (customer: Customer): Beneficiary =>
			customer
				? {
						uuid: null,
						isSelected: true,
						isDisabled: true,
						name: customer.full_name,
						price:
							planInsuranceType.main_applicant_price ?? planInsuranceType.price,
				  }
				: null;

		const fromDependent = (dependent: ApplicationDependent): Beneficiary => ({
			uuid: dependent.uuid,
			isSpouse: !!dependent.spouse,
			isSelected: typeof dependentsSelected[dependent.uuid] === 'number',
			price: dependentsSelected[dependent.uuid] ?? 0,
			isDisabled: false,
			name: `${dependent.first_name} ${dependent.last_name}`,
		});

		return [
			fromCustomer(customer),
			...(dependents ?? []).map(fromDependent),
		].filter(Boolean);
	}, [
		customer,
		dependentsSelected,
		dependents,
		planInsuranceType?.main_applicant_price,
		planInsuranceType?.price,
	]);

	if (!product) {
		Logger.error({
			message:
				'Plan result with a product_id that does not exists in the products list',
		});
		return null;
	}

	const renderBeneficiary = (
		{ name, isSelected, price, isDisabled, uuid }: Beneficiary,
		index: number
	) => {
		const renderName = (isSelected: boolean, isDisabled: boolean) => (
			<Text
				className='select-none'
				_light={{ color: 'gray.500' }}
				fontSize='sm'
				fontWeight='medium'
				textDecoration={isSelected ? '' : 'line-through'}
				cursor={edit && !isDisabled ? 'pointer' : ''}
				onClick={(e) =>
					edit && !isDisabled && changeDependentPlans(uuid, !isSelected)
				}>
				{name}
			</Text>
		);

		return (
			<div
				className='flex flex-row justify-between mt-2'
				key={`${name}-${index}`}>
				<div className='flex items-center'>
					{edit ? (
						<>
							<Checkbox
								className='pr-4'
								isChecked={isSelected}
								isDisabled={isDisabled}
								onChange={(e) => changeDependentPlans(uuid, e.target.checked)}
							/>
							{renderName(isSelected, isDisabled)}
						</>
					) : (
						renderName(isSelected, isDisabled)
					)}
				</div>
				<div className='flex gap-3'>
					<Text
						fontSize='sm'
						fontWeight='medium'
						_light={{ color: 'gray.500' }}>
						{currencyFormat(price)}
					</Text>
				</div>
			</div>
		);
	};

	const shouldHaveInsuranceTypePrefix = !product.name
		.toLowerCase()
		.startsWith(product.insurance_type.toLowerCase());

	const renderStatusBadge = () => {
		switch (status) {
			case ApplicationProductStatus.Draft:
			case ApplicationProductStatus.Submitted:
				return (
					<Badge variant='solid' colorScheme='blue'>
						<T>UNDER REVIEW</T>
					</Badge>
				);
			case ApplicationProductStatus.Approved:
				return (
					<Badge variant='solid' colorScheme='green'>
						<T>APPROVED</T>
					</Badge>
				);
			case ApplicationProductStatus.Rejected:
				return (
					<Badge variant='solid' colorScheme='red'>
						<T>DECLINED</T>
					</Badge>
				);
			default:
				return <></>;
		}
	};

	const isDeclined = status === ApplicationProductStatus.Rejected;
	const showDeclineReasons = isDeclined && !!openDeclineReasons;
	const showSummary = !showDeclineReasons && product.summary_link;

	return (
		<Stack className='mt-4' key={`${product.id}`}>
			<div className='flex flex-row justify-between'>
				<div className='flex flex-col'>
					<Text
						className='text-base font-semibold'
						_light={{ color: 'gray.900' }}>
						{shouldHaveInsuranceTypePrefix &&
							formatCapitalize(product.insurance_type)}{' '}
						{product.name}
					</Text>
					{showSummary && (
						<Link
							className='text-sm font-bold'
							color='blue.500'
							href={product.summary_link}
							onClick={() => {
								analyticsManager.track(
									AnalyticsTrack.PlanSummaryBenefits({
										insurance_name: product.name,
										insurance_type: product.insurance_type,
									})
								);
							}}
							isExternal>
							<T>See benefits summary</T>
						</Link>
					)}
					{showDeclineReasons && (
						<Button
							onClick={openDeclineReasons}
							color='blue.500'
							variant='link'
							size='sm'
							rightIcon={<AlertIcon />}>
							<Text className='pointer text-sm font-bold'>
								<T>Why my application was declined?</T>
							</Text>
						</Button>
					)}
				</div>
				<div>{renderStatusBadge()}</div>
			</div>
			{allBeneficiaries.map(renderBeneficiary)}
			<Divider className='mt-4' borderStyle='dashed' />
			<div className='mt-2 flex flex-row justify-between'>
				<Text fontWeight='medium' fontSize='sm'>
					<T>Total to be paid</T>
				</Text>
				<div className='flex gap-3'>
					{isLoading && <Spinner color='blue.500' size='sm' />}
					<Text fontWeight='medium' fontSize='sm'>
						{currencyFormat(planInsuranceType.price)}
					</Text>
				</div>
			</div>
		</Stack>
	);
};
