import { ChevronRightIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { Avatar, Button, Divider, Heading, Text } from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { Content, Header, Page, TabsFooter } from 'components';
import { Link, useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

export const ProfilePage = () => {
	const { authenticationStore } = useStores();
	const navigate = useNavigate();

	const logout = () => {
		authenticationStore.logout();
		navigate(RoutePath.login);
	};

	return (
		<Page>
			<Header canGoBack={false} />
			<Content scrollY paddingX className='flex flex-col gap-4'>
				<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold'>
					<T>Your profile</T>
				</Heading>
				<div className='flex flex-col justify-center items-center mb-4'>
					<Avatar
						name={authenticationStore.customer.full_name}
						backgroundColor={'#EFF4FB'}
						color={'blue.500'}
						width={'80px'}
						height={'80px'}
						className='mb-2'
					/>

					<Text fontSize={'sm'} fontWeight={500} _light={{ color: 'gray.900' }}>
						{authenticationStore.customer.full_name}
					</Text>
					<Text fontSize={'sm'} fontWeight={400} _light={{ color: 'gray.900' }}>
						{authenticationStore.customer.email}
					</Text>
				</div>
				{authenticationStore.customer.extras.has_onboarding && (
					<Link
						to={`${RoutePath.onboardingRedirect}?uuid=${authenticationStore.customer.uuid}`}>
						<div className='flex flex-row items-center justify-between'>
							<Text size={'sm'} fontWeight={500} _light={{ color: 'gray.900' }}>
								Personal information
							</Text>
							<ChevronRightIcon ml={2} color={'blue.500'} />
						</div>
					</Link>
				)}
				<Divider color={'gray.200'} />
				<Link to={'https://help.gigeasy.com/'} target='_blank'>
					<div className='flex flex-row items-center justify-between'>
						<Text size={'sm'} fontWeight={500} _light={{ color: 'gray.900' }}>
							Help and support
						</Text>
						<QuestionOutlineIcon ml={2} color={'blue.500'} />
					</div>
				</Link>
				<Divider color={'gray.200'} />
				<Link to={RoutePath.invite}>
					<div className='flex flex-row items-center justify-between'>
						<Text size={'sm'} fontWeight={500} _light={{ color: 'gray.900' }}>
							Invite a friend
						</Text>
						<ChevronRightIcon ml={2} color={'blue.500'} />
					</div>
				</Link>
				<Divider color={'gray.200'} />
				<Button
					size={'lg'}
					variant={'outline'}
					colorScheme={'gray'}
					onClick={logout}>
					Log out
				</Button>
			</Content>
			<TabsFooter />
		</Page>
	);
};
