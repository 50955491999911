import { AddIcon, CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Badge,
	Button,
	Fade,
	HStack,
	Heading,
	Stack,
	Text,
} from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { AnalyticsTrack, analyticsManager } from 'analytics';
import RiverLogo from 'assets/logos/river-logo.png';
import NGALogo from 'assets/providers/everest+nga.png';
import EverestLogo from 'assets/providers/everest.png';
import RenaissanceLogo from 'assets/providers/renaissance.png';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { ApplicationResult } from 'models';
import { useMemo } from 'react';
import { Logger, currencyFormat, formatCapitalize } from 'utils';
import { CustomButton } from './custom-button';
import { LoadingSection } from './loading-section';
import { ProductTabs } from './product-tabs';

type ProductSelectPlansProps = {
	result: ApplicationResult;
	isRefetching?: boolean;
	onIncludeInsuranceType: (insuranceType: string) => void;
	onChangePlan: (uuid: string) => void;
	onRemoveInsuranceType?: (insuranceType: string) => void;
};

export const ProductSelectPlans = ({
	result,
	isRefetching,
	onIncludeInsuranceType,
	onChangePlan,
	onRemoveInsuranceType,
}: ProductSelectPlansProps) => {
	const eligibleEntries = useMemo(() => {
		return Object.entries(result?.insurance_types?.eligible ?? {}).filter(
			([insuranceTypeCode]) =>
				!result.insurances_types_already_approved?.includes(insuranceTypeCode)
		);
	}, [
		result?.insurance_types?.eligible,
		result.insurances_types_already_approved,
	]);

	const availableEntries = useMemo(() => {
		return Object.entries(result?.insurance_types?.available ?? {});
	}, [result?.insurance_types?.available]);

	const includeInsuranceType = async (insuranceType: string) => {
		try {
			await onIncludeInsuranceType(insuranceType);
		} catch (error) {}
	};

	const renderPricing = (price: number) => {
		return (
			<Text fontSize='sm' fontWeight='600' _light={{ color: 'gray.500' }}>
				<T
					keyName={'From {price}/mo'}
					params={{
						price: currencyFormat(price),
					}}
					defaultValue={'From {price}/mo'}
				/>
			</Text>
		);
	};

	const renderInsuranceTypeHeading = (isSelected: boolean, title: string) => {
		return (
			<Heading fontSize={'lg'} fontWeight={600} _light={{ color: 'gray.900' }}>
				{isSelected && (
					<>
						<CheckCircleIcon
							color={'blue.500'}
							marginRight={2}
							width={'16px'}
							height={'16px'}
						/>
					</>
				)}
				{formatCapitalize(title)}
			</Heading>
		);
	};

	const renderPoweredBy = (companies: string[]) => {
		const logos: Record<string, JSX.Element> = {
			RENAISSANCE: <img src={RenaissanceLogo} width={84} height={18} />,
			EVEREST: <img src={EverestLogo} width={84} height={18} />,
			NGA: <img src={NGALogo} width={139} height={18} />,
			RIVER: <img src={RiverLogo} width={49} height={18} />,
		};

		return (
			<HStack textAlign={'left'} spacing={2}>
				<Text fontSize={'xs'} fontWeight={400} _light={{ color: 'gray.500' }}>
					<T>Powered by</T>
				</Text>
				<Stack direction={'row'} _dark={{ bg: 'gray.100', padding: 1 }}>
					{companies.map((company) => logos[company])}
				</Stack>
			</HStack>
		);
	};

	const renderAvailable = ([
		insuranceType,
		plan,
	]: (typeof availableEntries)[number]) => (
		<Fade in={true} key={insuranceType}>
			<AccordionItem
				key={'accordion-item' + insuranceType}
				padding='12px'
				marginY='2'
				border='1px'
				_dark={{
					borderColor: 'blue.500',
				}}
				borderStyle='solid'
				borderColor='#D8D8E5'
				borderRadius='12px'>
				<CustomButton
					className='flex !justify-between my-8 w-full'
					color='blue.500'
					bg='transparent'
					_hover={{
						background: 'transparent',
					}}
					rightIcon={<AddIcon color='blue.500' />}
					isLoadingOnlyIcon
					onClick={() => includeInsuranceType(insuranceType)}>
					<Stack textAlign='left' spacing='2'>
						{renderInsuranceTypeHeading(false, insuranceType)}
						{renderPoweredBy(plan.companies)}
						{renderPricing(plan.lowest_price)}
						<Button
							width={'70px'}
							size='xs'
							leftIcon={<AddIcon />}
							variant='link'
							color='blue.500'>
							Add plan
						</Button>
					</Stack>
				</CustomButton>
			</AccordionItem>
		</Fade>
	);

	const renderIneligible = (insuranceType: string) => (
		<Fade in={true} key={insuranceType}>
			<AccordionItem
				key={'accordion-item-' + insuranceType}
				padding='12px'
				marginY='2'
				_dark={{
					borderColor: 'blue.500',
				}}
				border='1px'
				borderStyle='solid'
				borderColor='#D8D8E5'
				borderRadius='12px'>
				<CustomButton
					className='flex !justify-between my-4 w-full'
					color='red.800'
					bg='transparent'
					_hover={{
						background: 'transparent',
					}}
					rightIcon={<WarningTwoIcon color='red.800' />}>
					<div className='flex flex-col items-start gap-2'>
						{renderInsuranceTypeHeading(false, insuranceType)}
						<Badge backgroundColor='red.100'>
							<Text fontSize='xs' fontWeight='700' color='red.800'>
								<T>NOT AVAILABLE</T>
							</Text>
						</Badge>
					</div>
				</CustomButton>
			</AccordionItem>
		</Fade>
	);

	return (
		<Accordion allowToggle marginTop='0' marginBottom='5' defaultIndex={0}>
			{eligibleEntries.map(
				([
					insuranceType,
					planInsuranceType,
				]: (typeof eligibleEntries)[number]) => {
					const product = planInsuranceType.products.find(
						(p) => p.id === planInsuranceType.product_id
					);
					if (!product) {
						Logger.error({
							message:
								'Application confirmation benefist plan result with a product_id that does not exists in the products list',
						});
						return null;
					}

					return (
						<Fade in={true} key={insuranceType}>
							<AccordionItem
								key={'accordion-item-' + insuranceType}
								padding='12px'
								marginY='2'
								_dark={{
									borderColor: 'blue.500',
								}}
								border='1px'
								borderStyle='solid'
								borderColor='#D8D8E5'
								borderRadius='12px'>
								{({ isExpanded }) => (
									<>
										<AccordionButton
											className='flex justify-between'
											bg='transparent'
											_hover={{
												background: 'transparent',
											}}>
											<Stack textAlign={'left'} spacing={2}>
												{renderInsuranceTypeHeading(true, insuranceType)}
												{renderPoweredBy(planInsuranceType.companies)}
												{renderPricing(planInsuranceType.lowest_price)}
											</Stack>
											<AccordionIcon color='blue.500' />
										</AccordionButton>
										<AccordionPanel pb={4}>
											<ProductTabs
												selected={product}
												products={planInsuranceType.products}
												onTabChange={(uuid: string) => onChangePlan(uuid)}
											/>
											<Stack direction='row' paddingTop='16px'>
												<Button
													className='flex grow'
													size='sm'
													variant='outline'
													color='blue.500'
													isDisabled={!product.summary_link}
													onClick={() => {
														analyticsManager.track(
															AnalyticsTrack.PlanSummaryBenefits({
																insurance_name: product.name,
																insurance_type: product.insurance_type,
															})
														);
														window.open(product.summary_link, '_blank');
													}}>
													See benefits
												</Button>

												{eligibleEntries.length > 1 && (
													<CustomButton
														isLoadingOnlyIcon
														className='flex grow'
														size='sm'
														leftIcon={<TrashIcon />}
														variant='outline'
														color='red.500'
														onClick={() =>
															onRemoveInsuranceType(insuranceType)
														}>
														Remove
													</CustomButton>
												)}
											</Stack>
										</AccordionPanel>
									</>
								)}
							</AccordionItem>
						</Fade>
					);
				}
			)}
			{availableEntries.map(renderAvailable)}
			{(result?.insurance_types?.ineligible ?? []).map(renderIneligible)}
			{isRefetching && (
				<div className='w-full h-24'>
					<LoadingSection isLoading={isRefetching} />
				</div>
			)}
		</Accordion>
	);
};
