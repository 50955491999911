import { Application } from 'models';

class GoogleTagManager {
	sendApplicationResultsToGTMPurchase(application: Application): void {
		if (!window.analytics) {
			return;
		}

		const payload: any = {
			transaction_id: application.uuid,
			value: application.results.total,
			tax: application.results.taxes
				.map((tax) => tax.value)
				.reduce((a, b) => a + b, 0),
			shipping: 0,
			currency: 'USD',
			coupon: application.discount_code,
			items: Object.values(application.results.insurance_types.eligible).map(
				(product) => ({
					item_id: product.product_id,
					item_name: product.products.find((p) => p.id === product.product_id)
						?.name,
					quantity: 1,
					price: product.price,
				})
			),
		};

		//@ts-ignore
		gtag('event', 'purchase', payload);
	}
}

export const googleTagManager = new GoogleTagManager();
