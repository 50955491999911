import { analyticsManager } from 'analytics';
import { Endpoints, api, coreApi } from 'api';
import { makeAutoObservable } from 'mobx';
import { getPersistedStore, makePersistable } from 'mobx-persist-store';
import {
	Customer,
	CustomerForgotPassword,
	CustomerLogin,
	CustomerRegister,
	CustomerResetPassword,
	Organization,
} from 'models/customer';
import { Status } from 'utils';
import { UTM } from './utm-store';

type FromRootStore = {
	clean: () => void;
	reload: () => void;
};

export class AuthenticationStore {
	customer: Customer = undefined;

	error: any = {};

	status?: Status = Status.Idle;

	rootStore: FromRootStore;

	constructor(_rootStore: FromRootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'AuthenticationStore',
			properties: ['customer'],
			storage: window.localStorage,
		});
		this.rootStore = _rootStore;
		this.load();
	}

	get isLoggedIn(): boolean {
		return !!this.customer;
	}

	get isSetupFinished(): boolean {
		return this.status === Status.Resolved;
	}

	get isEmailAuthenticated(): boolean {
		return !!this.customer?.email_validated;
	}

	get organizations(): Organization[] {
		return this.customer?.organizations || [];
	}

	setRedirectToOnboarding(redirectToOnboarding: boolean) {
		const customer = this.customer;

		this.setCustomer({
			...customer,
			extras: {
				...customer.extras,
				has_onboarding_to_do: redirectToOnboarding,
			},
		});
	}

	setCustomer(customer?: Customer) {
		this.customer = customer;
		api.setToken(this.customer?.uuid, this.customer?.tokens?.individual);
		coreApi.setToken(this.customer?.uuid, this.customer?.tokens?.core_api);
	}

	validateEmail() {
		const customer = this.customer;
		this.setCustomer({
			...customer,
			email_validated: true,
		});
	}

	async load() {
		this.status = Status.Pending;
		await getPersistedStore(this);
		this.setCustomer(this.customer);
		this.status = Status.Resolved;
	}

	async register(customer: CustomerRegister, utm: UTM) {
		const response = await api.post<Customer, CustomerRegister>(
			Endpoints.register,
			customer
		);
		this.setCustomer(response.data);
		const { email, first_name, last_name, uuid } = response.data;
		analyticsManager.identify({
			userId: uuid,
			email,
			name: `${first_name} ${last_name}`,
			utm_source: utm.utm_source,
			utm_medium: utm.utm_medium,
			utm_campaign: utm.utm_campaign,
			utm_term: utm.utm_term,
			utm_content: utm.utm_content,
		});
		this.rootStore.reload();
	}

	async login(customer: CustomerLogin) {
		const response = await api.post<Customer, CustomerLogin>(
			Endpoints.login,
			customer
		);
		
		this.setCustomer(response.data);
		const { email, first_name, last_name, uuid } = response.data;
		analyticsManager.identify({
			userId: uuid,
			email,
			name: `${first_name} ${last_name}`,
		});
		this.rootStore.reload();
	}

	async logout() {
		this.rootStore.clean();
	}

	async forgotPassword({ email }: CustomerForgotPassword) {
		await api.post<Customer, CustomerForgotPassword>(Endpoints.resetPassword, {
			email,
		});
	}

	async resetPassword({ password, token }: CustomerResetPassword) {
		await api.post<Customer, CustomerResetPassword>(Endpoints.updatePassword, {
			password,
			token,
		});
	}

	async clean() {
		this.setCustomer(undefined);
	}
}
