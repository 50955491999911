import { Endpoints, api } from 'api';
import { MutationKey, QueryKey } from 'constant';
import { ApplicationDependent, Payment } from 'models';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const apiApplicationPlansUpdate = (
	uuidApplication: string,
	uuidPlan: string
) =>
	api
		.post(Endpoints.applicationPlanUpdate(uuidApplication, uuidPlan))
		.then((response) => response.data);

export function useApplicationPlansMutation(uuidApplication: string) {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: [MutationKey.applicationPlanUpdate, uuidApplication],
		mutationFn: (uuidPlan: string) =>
			apiApplicationPlansUpdate(uuidApplication, uuidPlan),
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: [QueryKey.applicationResults, uuidApplication],
			}),
	});
}

export function useApplicationDependentMutation(uuidApplication: string) {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: [MutationKey.applicationPlanUpdate, uuidApplication],
		mutationFn: ({
			method,
			body,
			uuid,
		}: {
			method: 'UPSERT' | 'DELETE';
			body?: Partial<ApplicationDependent>;
			uuid?: string;
		}) => {
			return method === 'DELETE'
				? api.delete(Endpoints.applicationDependents(uuidApplication, uuid))
				: uuid
					? api.patch(
							Endpoints.applicationDependents(uuidApplication, uuid),
							body
						)
					: api.post(Endpoints.applicationDependents(uuidApplication), body);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.applicationDependents, uuidApplication],
			});
			queryClient.invalidateQueries({
				queryKey: [QueryKey.applicationResults, uuidApplication],
			});
		},
	});
}

export function useApplicationPaymentMutation(uuidApplication: string) {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: [MutationKey.applicationPlanUpdate, uuidApplication],
		mutationFn: (payment: Payment) => {
			return api.post(Endpoints.applicationPayment(uuidApplication), payment);
		},
	});
}
