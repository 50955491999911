import { Content, ContentHeader, Page } from 'components';
import { useMemo } from 'react';
import { Logger } from 'utils';
import { ApplicationPersonalInfoAboutYou } from './about-you';
import { ApplicationPersonalInfoAddress } from './address';
import { ApplicationPersonalInfoDependents } from './dependents';
import { ApplicationPersonalInfoProfession } from './profession';

type ApplicationPersonalInfoProps = {
	next: () => void;
	currentStep: number;
};

export const ApplicationPersonalInfo = ({
	next,
	currentStep,
}: ApplicationPersonalInfoProps) => {
	const currentStepInformation = useMemo(() => {
		switch (currentStep) {
			case 0:
				return {
					component: <ApplicationPersonalInfoAboutYou next={next} />,
					title: "Let's continue with account information",
				};
			case 1:
				return {
					component: <ApplicationPersonalInfoAddress next={next} />,
					title: 'Enter your home address',
				};
			case 2:
				return {
					component: <ApplicationPersonalInfoDependents next={next} />,
					title: 'Confirm the people in your household',
				};
			case 3:
				return {
					component: <ApplicationPersonalInfoProfession next={next} />,
					title: 'Confirm your professional information',
				};
			default:
				Logger.error({ message: `Invalid step ${currentStep}` });
		}
		return { component: <></>, title: '' };
	}, [currentStep, next]);

	return (
		<Page>
			<Content paddingX>
				<ContentHeader title={currentStepInformation.title} />
			</Content>

			{currentStepInformation.component}
		</Page>
	);
};
