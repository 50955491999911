import { Stack } from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { Content, CustomButton, Footer, Page } from 'components';
import {
	CustomFormControl,
	CustomInput,
	LocationStateSelect,
} from 'components/form';
import { useLocationStates } from 'hooks';
import { observer } from 'mobx-react';
import { Application } from 'models';
import { useEffect } from 'react';
import { Form, useForm } from 'react-hook-form';
import { useStores } from 'stores';
import { getStateName } from 'utils/address';

type ApplicationPersonalInfoAddressProps = {
	next: () => void;
};

type FormValues = Pick<Application, 'address' | 'city' | 'zipcode' | 'phone' | 'state_id'>;

export const ApplicationPersonalInfoAddress = observer(
	({ next }: ApplicationPersonalInfoAddressProps) => {
		const { t } = useTranslate();
		const { applicationStore } = useStores();
		const { data: loadedStates = [], isLoading } = useLocationStates();

		useEffect(() => {
			analyticsManager.page(AnalyticsPage.Application.PersonalInfoAddress);
		}, []);

		const updateApplication = (data) => {
			const { state_id } = data;
			applicationStore.updateApplication({
				...data,
				state_id: state_id ? Number(state_id) : null,
			});
			applicationStore.reload();
		};

		const defaultValues = {
			address: applicationStore.application.address ?? '',
			state_id: applicationStore.application.state_id ?? null,
			city: applicationStore.application.city ?? '',
			zipcode: applicationStore.application.zipcode ?? '',
			phone: applicationStore.application.phone ?? '',
		};

		const {
			register,
			control,
			getValues,
			setValue,
			handleSubmit,
			formState: { errors },
		} = useForm<FormValues>({
			defaultValues,
			mode: 'onBlur',
		});

		const onSubmit = (data: typeof defaultValues) => {
			analyticsManager.track(
				AnalyticsTrack.ApplicationAddressSubmitted({
					location: getStateName(loadedStates, data.state_id),
					city: data.city?.toLocaleLowerCase(),
				})
			);
			updateApplication(data);
			next();
		};

		const handleValueChange = (e: React.FocusEvent<HTMLInputElement, Element>) => {
			setValue(e.target.name as keyof FormValues, e.target.value);
		}

		return (
			<Page>
				<Content paddingX scrollY isLoading={isLoading}>
					<Form control={control} onBlur={() => updateApplication(getValues())}>
						<Stack spacing='6'>
							<CustomFormControl label={t('Address *')} error={errors.address}>
								<CustomInput
									type='text'
									{...register('address', { required: true })}
									placeholder={t('Enter address')}
									onBlur={handleValueChange}
								/>
							</CustomFormControl>

							<CustomFormControl label={t('State *')} error={errors.state_id}>
								<LocationStateSelect
									{...register('state_id', { required: true })}
								/>
							</CustomFormControl>

							<CustomFormControl label={t('City *')} error={errors.city}>
								<CustomInput
									type='text'
									placeholder={t('Enter city')}
									{...register('city', { required: true })}
									onBlur={handleValueChange}
								/>
							</CustomFormControl>

							<CustomFormControl label={t('Zip code *')} error={errors.zipcode}>
								<CustomInput
									type='tel'
									mask='99999'
									defaultValue={defaultValues.zipcode}
									placeholder={t('Enter zip code')}
									{...register('zipcode', { required: true })}
									onBlur={handleValueChange}
								/>
							</CustomFormControl>

							<CustomFormControl label={t('Phone *')} error={errors.zipcode}>
								<CustomInput
									type='tel'
									mask='(999) 999-9999'
									defaultValue={defaultValues.phone}
									placeholder={t('Enter phone')}
									{...register('phone', { required: true })}
									onBlur={handleValueChange}
								/>
							</CustomFormControl>
						</Stack>
					</Form>
				</Content>

				<Footer>
					<CustomButton
						bg='blue.500'
						color='white'
						size='lg'
						fontSize='lg'
						variant='solid'
						width='100%'
						_hover={{
							bg: 'blue.300',
						}}
						onClick={handleSubmit(onSubmit)}>
						<T>Next</T>
					</CustomButton>
				</Footer>
			</Page>
		);
	}
);
