import { LockIcon } from '@chakra-ui/icons';
import { Stack, StackProps, Text } from '@chakra-ui/react';

type SecurityNoteProps = { children: React.ReactNode } & StackProps;

export const SecurityNote = ({ children, ...props }: SecurityNoteProps) => {
	return (
		<Stack spacing='3' direction='row' align='center' {...props}>
			<LockIcon width='19px' height='24px' color='#50CD89' />
			<Text fontWeight={400} fontSize='xs' color='gray.500' lineHeight='16px'>
				{children}
			</Text>
		</Stack>
	);
};
