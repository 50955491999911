import { Address } from './address';

export type Payment = {
	type: PaymentType;

	details: PaymentCardDetails;
} & Address;

export enum PaymentType {
	Card = 'Card',
}

export type PaymentCardDetails = {
	provider: 'BasisTheory';
	version?: 'v1' | 'v2';
	/**
	 * @format uuid
	 */
	token_id: string;
	/**
	 * Token with other informations besides the card itself.
	 * @format uuid
	 */
	extra_token_id?: string;
	/**
	 * @format "XXXXXXXXXXXX4242"
	 */
	card_number: string;

	card_holder_name: string;
};
