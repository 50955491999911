import {
	Alert,
	AlertIcon,
	Box,
	Fade,
	Heading,
	SimpleGrid,
	Skeleton,
	Stack,
	Text,
} from '@chakra-ui/react';
import { ReactComponent as OfficeIcon } from 'assets/profession-type-office.svg';
import { ReactComponent as ServiceIcon } from 'assets/profession-type-service.svg';
import { ReactComponent as TradeIcon } from 'assets/profession-type-trade.svg';
import { useProfessionTypes } from 'hooks/use-profession-types';
import { ProfessionType } from 'models/profession-type';

type TypesProps = {
	typeSelected: number;
	toggleProfessionType: (professionType: ProfessionType) => any;
};

export const ProfessionTypes = ({
	typeSelected,
	toggleProfessionType,
}: TypesProps) => {
	const { data, isLoading, isError, error } = useProfessionTypes();

	if (isLoading) {
		return (
			<>
				<Skeleton width={'100%'} height={200} marginTop={5} />
				<Skeleton width={'100%'} height={200} marginTop={5} />
				<Skeleton width={'100%'} height={200} marginTop={5} />
			</>
		);
	}

	if (isError) {
		return (
			<Alert status='error'>
				<AlertIcon />
				There was an error processing your request:{' '}
				{error?.message ?? error?.statusText}
			</Alert>
		);
	}

	const renderIcon = (code: string) => {
		switch (code) {
			case 'office':
				return <OfficeIcon width={40} height={40} />;
			case 'service':
				return <ServiceIcon width={40} height={40} />;
			case 'trade':
				return <TradeIcon width={40} height={40} />;
			default:
				return '';
		}
	};

	return (
		<SimpleGrid columns={1} spacing={5} marginTop={5}>
			{data.map((type) => {
				let isSelected = typeSelected === type.id;

				return (
					<Fade in={true} key={type.code}>
						<Box
							borderRadius={8}
							bg={isSelected ? 'blue.50' : ''}
							border={1}
							borderStyle={'solid'}
							borderColor={isSelected ? 'blue.500' : 'gray.200'}
							padding={5}
							cursor={'pointer'}
							onClick={() => toggleProfessionType(type)}>
							<Stack spacing={4} direction={'row'} align={'center'}>
								{renderIcon(type.code)}
								<Heading
									fontSize={'sm'}
									fontWeight={600}
									color={isSelected ? 'blue.500' : 'gray.500'}>
									{type.name}
								</Heading>
								<Text
									textAlign={'left'}
									fontSize={'sm'}
									fontWeight={500}
									color={isSelected ? 'blue.500' : 'gray.500'}>
									{type.description}
								</Text>
							</Stack>
						</Box>
					</Fade>
				);
			})}
		</SimpleGrid>
	);
};
