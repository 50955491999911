import { ArrowBackIcon, CopyIcon } from '@chakra-ui/icons';
import { Heading, Text, Tooltip, useClipboard } from '@chakra-ui/react';
import { ReactComponent as InviteIcon } from 'assets/invite-page-icon.svg';
import { Content, Header, Page } from 'components';
import { Link } from 'react-router-dom';
import { RoutePath } from 'routes';

export const InvitePage = () => {
	const {
		onCopy,
		value: inviteUrl,
		hasCopied,
	} = useClipboard(
		window.location.protocol + '//' + window.location.host + RoutePath.quote
	);

	return (
		<Page>
			<Header canGoBack={false} />
			<Content paddingX className='flex flex-col gap-4'>
				<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold'>
					<Link to={RoutePath.profile}>
						<ArrowBackIcon />
					</Link>{' '}
					Invite a friend
				</Heading>
				<div className='w-full border-gray-200 border-2 rounded-lg pl-5 pt-5'>
					<Text>Invite a friend for the best</Text>
					<Text className='font-semibold text-xl'>
						Gig Worker Insurance Plans!
					</Text>
					<div className='flex'>
						<div className='basis-2/3 pb-5'>
							<Text className='mt-3'>
								Insurance does not have to be expensive. Let the world know that
								they can get covered with insurance for as low as{' '}
								<span className='font-bold'>$50</span> a month!
							</Text>
						</div>
						<div className='flex basis-1/3 justify-center items-end px-3'>
							<InviteIcon width={140} />
						</div>
					</div>
				</div>
				<Text className='font-bold'>Invite URL</Text>
				<div className='flex mb-2 justify-between items-center p-3 rounded bg-slate-300'>
					<div className='text-black'>{inviteUrl}</div>
					<Tooltip
						hasArrow
						label={'Copied!'}
						isOpen={hasCopied}
						placement='top'>
						<CopyIcon
							cursor={'pointer'}
							color={'black'}
							onClick={onCopy}
							marginRight={3}
						/>
					</Tooltip>
				</div>
			</Content>
		</Page>
	);
};
