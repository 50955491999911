import { useNavigate, useParams } from "react-router-dom";
import { Heading, Divider } from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { Content, Header, Page, CustomButton } from 'components';
import { useStores } from "stores";
import { RoutePath } from "routes";
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useTimesheetEntry } from "hooks/timesheet/queries";
import { dateToString } from "utils/date/date-to-string";
import { dateStringToDate } from "utils/date/date-string-to-date";
import { TimesheetHourStatus } from "./shared/timesheet-hour-status";
import { formatFloatToHour } from "../utils/format-float-to-hour";
import { useDeleteMutationTimesheetEntry } from "hooks/timesheet/mutations";
import { ConfirmDialog } from 'components/confirm/confirm-dialog';
import { useState } from "react";

export const TimesheetHoursResume = () => {
	const navigate = useNavigate();
	const params = useParams<{ orgUuid: string, entryUuid: string }>();

	const [ isConfirmationDialogOpen, setIsConfirmationDialogOpen ] = useState(false);

	const store = useStores();
	const currentOrg = store.authenticationStore.organizations.find(org => org.uuid === params.orgUuid);

	const {
		data: timesheetEntry,
		isLoading,
	} = useTimesheetEntry(params.orgUuid, params.entryUuid);

	const deleteMutationTimesheetEntry = useDeleteMutationTimesheetEntry(params.orgUuid, params.entryUuid, timesheetEntry?.date || "");

	const shouldShowEdit = !isLoading && (timesheetEntry?.status === "Draft" || timesheetEntry?.status === "Rejected");
	const shouldShowResend = !isLoading && timesheetEntry?.status === "Rejected";
	const shouldShowDelete = !isLoading && timesheetEntry?.status !== "Rejected" && timesheetEntry?.status !== "Approved";

	const handleDelete = async () => {
		await deleteMutationTimesheetEntry.mutateAsync();
		navigate(`${RoutePath.timesheet}/${currentOrg.uuid}`);
	}

	return (
		<Page>
			<Header canGoBack={false} />
			<Content scrollY paddingX className='flex flex-col'>
				<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold' marginBottom={7}>
					<div className="flex justify-between pr-1">
						<div>Time Details</div>
						<div className="cursor-pointer" onClick={() => navigate(`${RoutePath.timesheet}/${currentOrg.uuid}`)}><CloseIcon /></div>
					</div>
				</Heading>
				{!isLoading && <Content scrollY>
					<div className="flex flex-row justify-between">
						<div className="flex flex-col space-y-2">
							<div className="font-semibold">Company</div>
							<div>{currentOrg.name}</div>
						</div>
					</div>
					<Divider marginTop={6} marginBottom={6} />
					<div className="flex flex-row justify-between">
						<div className="flex flex-col space-y-2">
							<div className="font-semibold">Date</div>
							<div>{dateToString(dateStringToDate(timesheetEntry.date), "MM.dd.yyyy")}</div>
						</div>
					</div>
					<Divider marginTop={6} marginBottom={6} />
					<div className="flex flex-col">
						<div className="flex flex-col space-y-2">
							<div className="flex font-semibold">Hours Worked</div>
							<div>{formatFloatToHour(timesheetEntry.worked_hours)}</div>
						</div>
					</div>
					<Divider marginTop={6} marginBottom={6} />
					<div className="flex flex-col">
						<div className="flex flex-col space-y-2">
							<div className="font-semibold">Note</div>
							<div>{timesheetEntry.description}</div>
						</div>
					</div>
					<Divider marginTop={6} marginBottom={6} />
					<div className="flex flex-col">
						<div className="flex flex-col space-y-2">
							<div className="font-semibold">Status</div>
							<div><TimesheetHourStatus status={timesheetEntry.status} /></div>
						</div>
					</div>
					<Divider marginTop={6} marginBottom={6} />
					<div className="space-y-2">
						{shouldShowEdit && <div className="w-full">
							<CustomButton
								className="w-full"
								colorScheme="green"
								variant="outline"
								onClick={() => navigate(RoutePath.editTimesheetEntry(currentOrg.uuid, params.entryUuid))}
							>
								<EditIcon className="mr-1" /> Edit entry
							</CustomButton>
						</div>}
						{shouldShowResend && <div className="w-full">
							<CustomButton
								className="w-full"
								colorScheme="primary"
								variant="outline"
								onClick={() => {/* Resend here */}}
							>
								Resend
							</CustomButton>
						</div>}
						{shouldShowDelete && <div className="w-full">
							<CustomButton
								className="w-full"
								colorScheme="red"
								variant="outline"
								onClick={() => setIsConfirmationDialogOpen(true)}
							>
								<DeleteIcon className="mr-1" /> Delete
							</CustomButton>
						</div>}
					</div>
				</Content>}
				<ConfirmDialog
					isOpen={isConfirmationDialogOpen}
					onClose={() => setIsConfirmationDialogOpen(false)}
					onConfirm={handleDelete}
					header={"Are you sure?"}
					message={"All the date will be lost. This can not be undone."}
				/>
			</Content>
		</Page>
	);
}