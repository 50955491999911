import { Link, Stack, Text } from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { ErrorResponse } from 'api';
import {
	AlertError,
	Content,
	ContentHeader,
	CustomButton,
	Header,
	Page,
} from 'components';
import { PasswordLevel } from 'components/authentication';
import { CustomFormControl, CustomInput } from 'components/form';
import { CustomInputPassword } from 'components/form/custom-input-password';
import { observer } from 'mobx-react';
import { CustomerRegister } from 'models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import { Logger } from 'utils';
import {
	containsAtLeastOneLetter,
	containsAtLeastOneNumber,
	containsAtLeastOneSymbol,
} from 'utils/form';

export const RegisterPage = observer(() => {
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { quoteStore, authenticationStore, utmStore } = useStores();
	const [errorResponse, setErrorResponse] = useState<ErrorResponse>(null);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		authenticationStore.logout();
		analyticsManager.page(AnalyticsPage.General.Register);
	}, [authenticationStore]);

	const defaultValues = {
		first_name: searchParams.get('first_name') ?? '',
		last_name: searchParams.get('last_name') ?? '',
		password: '',
		email: searchParams.get('email') ?? quoteStore.quote.email,
	};

	const {
		watch,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CustomerRegister>({
		defaultValues,
		mode: 'onSubmit',
	});

	const linkQuoteToCustomer = async () => {
		try {
			await quoteStore.linkWithCustomer();
		} catch (error) {
			Logger.error({
				message: 'Unable to connect customer with the filled out quote',
				exception: error,
				extraData: {
					quote: quoteStore.quote,
				},
			});
		}
	};

	const onSubmit = async (data: CustomerRegister) => {
		try {
			setErrorResponse(null);
			await authenticationStore.register(
				{
					...data,
					quote_uuid: quoteStore.quote.uuid,
					individual_uuid: searchParams.get('individualUuid') ?? null,
				},
				{
					utm_source: utmStore.utm.utm_source,
					utm_medium: utmStore.utm.utm_medium,
					utm_campaign: utmStore.utm.utm_campaign,
					utm_term: utmStore.utm.utm_term,
					utm_content: utmStore.utm.utm_content,
				}
			);
			linkQuoteToCustomer();
			analyticsManager.track(AnalyticsTrack.UserCreatedAccount());
			utmStore.clean();
			navigate(RoutePath.application);
		} catch (error: any) {
			setErrorResponse(error);
		}
	};

	const password = watch('password');

	return (
		<Page>
			<Header />
			<Content scrollY paddingX>
				<ContentHeader
					title='First let’s create an account'
					subTitle={
						<>
							<T>Already have an Account? </T>
							<Link color='blue.500' onClick={() => navigate(RoutePath.login)}>
								Sign in
							</Link>
						</>
					}
				/>
				<Stack gap='4'>
					<CustomFormControl
						label={t('First name *')}
						error={errors.first_name}>
						<CustomInput
							type='text'
							placeholder={t('Enter first name')}
							{...register('first_name', {
								required: true,
								minLength: 3,
							})}
						/>
					</CustomFormControl>
					<CustomFormControl label={t('Last name *')} error={errors.last_name}>
						<CustomInput
							type='text'
							placeholder={t('Enter last name')}
							{...register('last_name', {
								required: true,
								minLength: 3,
							})}
						/>
					</CustomFormControl>
					<CustomFormControl label={t('Email *')} error={errors.email}>
						<CustomInput
							type='email'
							placeholder={t('Enter email')}
							{...register('email', {
								required: true,
							})}
						/>
					</CustomFormControl>
					<CustomFormControl
						label={t('Password *')}
						error={errors.password}
						renderAfterError={
							<>
								<PasswordLevel password={password} />
								<Text color='gray.500' fontSize='xs'>
									<T>
										Use 8 or more characters with a mix of letters, numbers &
										symbols.
									</T>
								</Text>
							</>
						}>
						<CustomInputPassword
							placeholder={t('Enter password')}
							{...register('password', {
								required: true,
								minLength: 8,
								validate: {
									containsAtLeastOneLetter,
									containsAtLeastOneNumber,
									containsAtLeastOneSymbol,
								},
							})}
						/>
					</CustomFormControl>

					<Text color='gray.500' fontSize='xs'>
						<T>By continuing, you agree to GigEasy's</T>
						<> </>
						<Link
							color='blue.500'
							target='_blank'
							href='https://www.gigeasy.com/terms-and-conditions'>
							<T>Terms of Use</T>
						</Link>
						<> </>
						<T>and</T>
						<> </>
						<Link
							color='blue.500'
							target='_blank'
							href='https://www.gigeasy.com/privacy-policy'>
							<T>Privacy Policy</T>
						</Link>
						.
					</Text>

					<AlertError error={errorResponse} isResponse />

					<Stack direction='row' paddingY='4' paddingBottom='8'>
						<CustomButton
							bg='blue.500'
							color='white'
							className='flex grow'
							size='lg'
							variant='solid'
							_hover={{
								bg: 'blue.300',
							}}
							onClick={handleSubmit(onSubmit)}>
							Register
						</CustomButton>
					</Stack>
				</Stack>
			</Content>
		</Page>
	);
});
