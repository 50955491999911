import { AnalyticsPage, analyticsManager } from 'analytics';
import { ApplicationPlanSummary, ContentHeader } from 'components';
import { useApplicationResults } from 'hooks';
import { observer } from 'mobx-react';
import { ApplicationDependent } from 'models';
import { useEffect } from 'react';
import { useStores } from 'stores';

type ApplicationConfirmDependentsProps = {
	dependents: ApplicationDependent[];
};

export const ApplicationConfirmDependents = observer(
	({ dependents }: ApplicationConfirmDependentsProps) => {
		const { applicationStore } = useStores();

		const {
			data: applicationResult,
			isLoading: isLoadingResult,
			isRefetching: isRefetchingResult,
			refetch,
		} = useApplicationResults(applicationStore.application.uuid);

		useEffect(() => {
			analyticsManager.page(AnalyticsPage.Application.InsuranceDependentsPlans);
		}, []);

		return (
			<>
				<ContentHeader
					title='Confirm your plan selection'
					subTitle='You will not be charged yet.'
				/>
				<ApplicationPlanSummary
					isLoading={isLoadingResult}
					isRefetching={isRefetchingResult}
					result={applicationResult}
					dependents={dependents}
					showDiscountInput
					edit
					refetch={() => refetch()}
				/>
			</>
		);
	}
);
