import { Endpoints, api } from 'api';
import { MutationKey, QueryKey } from 'constant';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useMutationQuotePlans(uuidQuote: string) {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: [MutationKey.quotePlanUpdate, uuidQuote],
		mutationFn: (uuidPlan: string) =>
			api
				.post(Endpoints.quotesPlanUpdate(uuidQuote, uuidPlan))
				.then((response) => response.data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.quoteResults, uuidQuote],
			});
		},
	});
}

export function useMutationEmailCodeSend(uuidQuote: string) {
	return useMutation({
		mutationKey: [MutationKey.quoteEmailCodeSend, uuidQuote],
		mutationFn: () =>
			api
				.get(Endpoints.quotesEmail(uuidQuote))
				.then((response) => response.data),
	});
}

export function useMutationEmailCodeValidate(uuidQuote: string) {
	return useMutation({
		mutationKey: [MutationKey.quoteEmailCodeValidate, uuidQuote],
		mutationFn: (code: string) =>
			api
				.post<{ validated: boolean }, { code: string }>(
					Endpoints.quotesEmail(uuidQuote),
					{ code }
				)
				.then((response) => response.data),
	});
}
