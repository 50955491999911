import { Endpoints, ErrorResponse, api } from 'api';
import { makeAutoObservable } from 'mobx';
import { getPersistedStore, makePersistable } from 'mobx-persist-store';
import {
	Application,
	ApplicationDependent,
	ApplicationStatus,
	InsuranceType,
	Quote,
} from 'models';
import { Logger, Status } from 'utils';

type FromRootStore = {
	quoteStore: { getUpdatedQuote: () => Promise<Quote> };
};

export class ApplicationStore {
	application: Application = {};

	dependents: ApplicationDependent[] = [];

	error?: ErrorResponse = undefined;

	status?: Status = Status.Idle;

	hasCustomProfession?: boolean = false;

	rootStore: FromRootStore;

	constructor(_rootStore: FromRootStore) {
		this.rootStore = _rootStore;
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'ApplicationStore',
			properties: ['application'],
			storage: window.localStorage,
		});
		this.load();
		this.setHasCustomProfession(this.application.profession_id === null);
	}

	get isSetupFinished(): boolean {
		return this.status === Status.Resolved || this.status === Status.Rejected;
	}

	get isLoading(): boolean {
		return this.status === Status.Pending;
	}

	get isDraft(): boolean {
		return this.application.status === ApplicationStatus.Draft;
	}

	setHasCustomProfession(hasCustom: boolean) {
		this.hasCustomProfession = hasCustom;
	}

	setStatus(status: Status) {
		this.status = status;
	}

	setApplication(application: Application) {
		this.application = application;
	}

	async load(local: boolean = true) {
		this.setStatus(Status.Pending);
		if (local) {
			await getPersistedStore(this);
		}
		try {
			const uuidCustomer = await api.getUuid();
			const application = uuidCustomer
				? await this.fetchCustomerApplication(uuidCustomer, {
						createIfNotFound: true,
					})
				: {};
			this.setApplication({});
			this.updateApplication(application, false);
			this.setStatus(Status.Resolved);
		} catch (error) {
			Logger.error({
				message: 'Error loading the application from remote api',
				extraData: this.application,
				exception: error,
			});
			this.error = error;
			this.setStatus(Status.Rejected);
		}
	}

	async reload() {
		return this.load(false);
	}

	async fetchCustomerApplication(
		uuidCustomer: string,
		{ createIfNotFound }: { createIfNotFound: boolean }
	) {
		const response = await api.get<Application[]>(
			Endpoints.customerApplications(uuidCustomer)
		);
		if (createIfNotFound && response.data.length === 0) {
			return this.createApplication();
		}
		return response.data[0];
	}

	async createApplication() {
		const application: Partial<Application> = {};
		const response = await api.post<Application, object>(
			Endpoints.application(),
			application
		);
		let applicationSaved = response.data;

		try {
			const { uuid: quote } =
				(await this.rootStore.quoteStore.getUpdatedQuote()) ?? {};

			const quoteResponse = await api.post(
				Endpoints.applicationAttachQuote(applicationSaved.uuid),
				{
					quote,
				}
			);

			applicationSaved = quoteResponse.data;
		} catch (error) {
			Logger.error({
				message: 'Unable to update the application with the quote information',
				exception: error,
			});
		}
		return applicationSaved;
	}

	async patchApplication(partial: Partial<Application>) {
		return api.patch<Application, Partial<Application>>(
			Endpoints.application(this.application.uuid),
			partial
		);
	}

	async toggleInsuranceType({ code }: Pick<InsuranceType, 'code'>) {
		let updatedList = Object.assign([], this.application.insurance_types ?? []);
		if (updatedList.includes(code)) {
			updatedList = updatedList.filter((e) => e != code);
		} else {
			updatedList.push(code);
		}

		await this.updateApplication({ insurance_types: updatedList });
	}

	async updateApplication(
		partial: Partial<Application>,
		patchRemote: boolean = true
	) {
		this.setApplication({
			...this.application,
			...partial,
		});
		if (patchRemote) {
			await this.patchApplication(partial);
		}
	}

	clean() {
		this.setApplication({});
	}
}
