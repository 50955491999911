import { Spinner, Text } from '@chakra-ui/react';
import { useLocationStates } from 'hooks';
import { Address } from 'models';
import { useMemo } from 'react';

type LocationDisplayProps = { address: Address };

export const LocationDisplay = ({ address }: LocationDisplayProps) => {
	const { data: loadedStates = [], isLoading } = useLocationStates();

	const state = useMemo(() => {
		return loadedStates.find((state) => state.id === address.state_id)?.code;
	}, [address.state_id, loadedStates]);

	if (isLoading) {
		return <Spinner color='blue.500' />;
	}

	return (
		<Text>
			{address.address} {address.city} {state} {address.zipcode}
		</Text>
	);
};
