import { useToast as useToastChakra } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useToast = () => {
	const toast = useToastChakra();

	const toastError = useCallback(
		({ message }: { message: string }) =>
			toast({
				title: message,
				position: 'bottom-right',
				status: 'error',
			}),
		[toast]
	);

	const toastSuccess = useCallback(
		({ message, duration }: { message: string; duration?: number }) =>
			toast({
				title: message,
				position: 'bottom-right',
				status: 'success',
				duration: duration ?? 5000,
			}),
		[toast]
	);

	const toastWarning = useCallback(
		({ message, duration }: { message: string; duration?: number }) =>
			toast({
				title: message,
				position: 'bottom-right',
				status: 'warning',
				duration: duration ?? 5000,
			}),
		[toast]
	);

	return { toast, toastError, toastSuccess, toastWarning };
};
