import {
	Alert,
	Avatar,
	Box,
	Button,
	Heading,
	List,
	ListItem,
	Skeleton,
	SkeletonText,
	Stack,
	Text,
} from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { analyticsMapFromPlanResult } from 'analytics/mapper';
import { Content, ContentHeader, Footer, Page } from 'components';
import { LoadingSection } from 'components/loading-section';
import { ProductSelectPlans } from 'components/product-select-plans';
import { useMutationQuotePlans, useQuoteResults } from 'hooks/quote';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import { currencyFormat } from 'utils';

export const ApplicationQuoteResultTab = observer(({ setCurrentTab }) => {
	const navigate = useNavigate();
	const { quoteStore } = useStores();
	const mutationQuotePlans = useMutationQuotePlans(quoteStore.quote.uuid);
	const {
		data: quoteResult,
		isLoading,
		isRefetching,
		refetch: refetchResult,
	} = useQuoteResults(quoteStore.quote.uuid);

	useEffect(() => {
		analyticsManager.page(AnalyticsPage.Quote.Quote);
	}, []);

	useEffect(() => {
		if (quoteStore.quote.uuid && !isLoading) {
			analyticsManager.track(
				AnalyticsTrack.QuoteResult(
					analyticsMapFromPlanResult(quoteResult, quoteStore.quote.dependents)
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quoteStore.quote.uuid, isLoading]);

	const eligibleEntries = useMemo(() => {
		return Object.entries(quoteResult?.insurance_types?.eligible ?? {});
	}, [quoteResult]);

	const availableEntries = useMemo(() => {
		return Object.entries(quoteResult?.insurance_types?.available ?? {});
	}, [quoteResult]);

	const isEmpty = eligibleEntries.length === 0 && availableEntries.length === 0;

	const submit = () => {
		analyticsManager.track(
			AnalyticsTrack.QuoteProceedtoApplication(
				analyticsMapFromPlanResult(quoteResult, quoteStore.quote.dependents)
			)
		);
		navigate(RoutePath.register);
	};

	const removeInsuranceType = async (insuranceType: string) => {
		await quoteStore.toggleInsuranceType({ code: insuranceType });
		await refetchResult();
	};

	const includeInsuranceType = async (insuranceType: string) => {
		await quoteStore.toggleInsuranceType({ code: insuranceType });
		await refetchResult();
	};

	return (
		<Page>
			{isLoading && (
				<Content scrollY paddingX>
					<Skeleton height='40px' marginTop='5' />
					<SkeletonText noOfLines={1} mt='4' spacing='4' skeletonHeight='2' />
					<Skeleton width='213' height='52' marginTop='5' />
					<Skeleton width='100%' height='200' marginTop='5' />
					<Skeleton width='100%' height='200' marginTop='5' />
					<Skeleton width='100%' height='200' marginTop='5' />
					<Skeleton height='20px' marginTop='5' />
				</Content>
			)}
			{!isLoading && (
				<Page>
					{isEmpty && (
						<Content scrollY paddingX>
							<Alert
								colorScheme='red'
								variant='subtle'
								_light={{ color: 'red.900' }}
								marginTop='5'
								textAlign='center'>
								<T>
									Unfortunately we do not have any options available to you at
									this time.
								</T>
							</Alert>
							<Button
								marginTop={5}
								bg={'blue.500'}
								color={'white'}
								size={'lg'}
								fontSize={'lg'}
								variant='solid'
								width={'100%'}
								onClick={() => setCurrentTab(0)}>
								<T>Update my information</T>
							</Button>
						</Content>
					)}
					{!isEmpty && (
						<>
							<Box _light={{ bg: 'white' }} className='px-4 pb-4'>
								<ContentHeader title='Here’s great plan for you' />
								<div className='flex items-center'>
									<Heading
										fontSize='5xl'
										_light={{ color: 'gray.900' }}
										fontWeight='700'>
										~ {currencyFormat(quoteResult?.total)}
									</Heading>

									<LoadingSection
										isLoading={
											isRefetching || isLoading || mutationQuotePlans.isPending
										}
										className='pl-6'
									/>
								</div>
								<Text
									_light={{ color: 'gray.900' }}
									fontSize='md'
									fontWeight='500'>
									<T>Estimated per month for you</T>
									{quoteStore.quote.dependents > 0 && (
										<T
											keyName={' and {dependents} dependents'}
											params={{
												dependents: quoteStore.quote.dependents,
											}}
											defaultValue={' and {dependents} dependents'}
										/>
									)}
								</Text>
							</Box>
							<Content scrollY paddingX>
								<Stack spacing={5} marginTop={0}>
									<ProductSelectPlans
										isRefetching={isRefetching}
										result={quoteResult}
										onChangePlan={(uuidPlan) => {
											mutationQuotePlans.mutate(uuidPlan);
										}}
										onRemoveInsuranceType={removeInsuranceType}
										onIncludeInsuranceType={includeInsuranceType}
									/>
									<Heading
										fontSize={'lg'}
										_light={{ color: 'gray.900' }}
										fontWeight={600}>
										<T>What’s next?</T>
									</Heading>
									<List
										spacing={3}
										_light={{ bg: '#F9F9F9' }}
										_dark={{ borderColor: 'blue.500' }}
										borderWidth={1}
										padding={5}
										borderRadius={8}>
										<ListItem marginBottom={5}>
											<Stack direction={'row'} padding={0}>
												<Box paddingRight={5} alignItems={'center'}>
													<Avatar
														bg={'white'}
														borderRadius={'50%'}
														name={'1'}
														color={'blue.500'}
														size={'md'}
													/>
												</Box>
												<Box
													width={'full'}
													borderBottom={'1px dashed #E1E3EA'}
													paddingBottom={3}>
													<Heading
														fontSize={'sm'}
														_light={{ color: 'gray.900' }}
														fontWeight={600}>
														<T>Complete your account</T>
													</Heading>
													<Text
														lineHeight={'20px'}
														fontSize={'sm'}
														_light={{ color: 'gray.600' }}
														fontWeight={500}>
														<T>
															We already saved some basic information, proceed
															with creating your account.
														</T>
													</Text>
												</Box>
											</Stack>
										</ListItem>
										<ListItem marginBottom={5}>
											<Stack direction={'row'}>
												<Box paddingRight={5} alignItems={'center'}>
													<Avatar
														bg={'white'}
														borderRadius={'50%'}
														name={'2'}
														color={'blue.500'}
														size={'md'}
													/>
												</Box>
												<Box
													width={'full'}
													borderBottom={'1px dashed #E1E3EA'}
													paddingBottom={3}>
													<Heading
														fontSize={'sm'}
														_light={{ color: 'gray.900' }}
														fontWeight={600}>
														<T>Provide more information</T>
													</Heading>
													<Text
														lineHeight={'20px'}
														fontSize={'sm'}
														_light={{ color: 'gray.600' }}
														fontWeight={500}>
														<T>
															This is just an average quotation. To get an offer
															we need some more information.
														</T>
													</Text>
												</Box>
											</Stack>
										</ListItem>
										<ListItem>
											<Stack direction={'row'}>
												<Box paddingRight={5} alignItems={'center'}>
													<Avatar
														bg={'white'}
														borderRadius={'50%'}
														name={'3'}
														color={'blue.500'}
														size={'md'}
													/>
												</Box>
												<Box width={'full'}>
													<Heading
														fontSize={'sm'}
														_light={{ color: 'gray.900' }}
														fontWeight={600}>
														<T>Get insured</T>
													</Heading>
													<Text
														lineHeight={'20px'}
														fontSize={'sm'}
														_light={{ color: 'gray.600' }}
														fontWeight={500}>
														<T>
															Complete your application to make sure you're
															covered!
														</T>
													</Text>
												</Box>
											</Stack>
										</ListItem>
									</List>
								</Stack>
							</Content>
						</>
					)}
				</Page>
			)}
			<Footer>
				{!isEmpty && (
					<Button
						bg='blue.500'
						color='white'
						size='lg'
						fontSize='lg'
						variant='solid'
						width='100%'
						onClick={() => submit()}>
						<T>Continue with plan</T>
					</Button>
				)}
			</Footer>
		</Page>
	);
});
