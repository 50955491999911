type AnalyticsPageInformation = { category: string; page: string };

export const AnalyticsPage = {
	Quote: {
		Info: { category: 'Quote', page: 'Quote - Info' },
		Quote: { category: 'Quote', page: 'Quote - Results' },
	},
	General: {
		ConfirmEmail: { category: 'General', page: 'General - Confirm Email' },
		Login: { category: 'General', page: 'General - Login' },
		Register: { category: 'General', page: 'General - Register' },
	},
	Application: {
		PersonalInfoAboutYou: {
			category: 'Application',
			page: 'Application - About you',
		},
		PersonalInfoAddress: {
			category: 'Application',
			page: 'Application - Address',
		},
		PersonalInfoDependents: {
			category: 'Application',
			page: 'Application - Dependents',
		},
		PersonalInfoProfession: {
			category: 'Application',
			page: 'Application - Profession',
		},
		InsurancePlans: {
			category: 'Application',
			page: 'Application - Results',
		},
		InsuranceDependentsPlans: {
			category: 'Application',
			page: 'Application - Dependents Selection',
		},
		Payment: { category: 'Application', page: 'Application - Payment' },
		Results: { category: 'Application', page: 'Application - Summary' },
	},
} as const;

export const AnalyticsTrack = {
	/**
	 * @fires_when User fills in all the info tab and successfully submits it.
	 */
	QuoteInfoChosen: (properties: {
		total_dependents: number;
		has_spouse: boolean;
		has_children: boolean;
		total_children: number;
		/**
		 * @format State user selected
		 */
		location: string;
	}) => ({
		event: 'Quote Info Chosen',
		properties,
	}),

	/**
	 * @fires_when User successfully submited the quote request and filled in all the information
	 */
	QuoteResult: (properties: {
		monthly_estimate: number;
		ineligible_insurance_type: string[];
		total_dependents: number;
		eligible_insurance: { name: string; type: string; price: number }[];
	}) => ({
		event: 'Quote Result',
		properties,
	}),

	/**
	 * @fires_when User clicked on proceed to application.
	 */
	QuoteProceedtoApplication: (properties: {
		monthly_estimate: number;
		ineligible_insurance_type: string[];
		total_dependents: number;
		selected_insurance: { name: string; type: string; price: number }[];
		eligible_insurance: { name: string; type: string; price: number }[];
	}) => ({
		event: 'Quote Proceed to Application',
		properties,
	}),

	/**
	 * @fires_when User successfully confirms their email
	 */
	EmailConfirmed: () => ({
		event: 'Email Confirmed',
		properties: undefined,
	}),

	/**
	 * @fires_when User fills in all the about you information and successfully submits it.
	 */
	ApplicationInfoSubmitted: () => ({
		event: 'Application Info Submitted',
		properties: undefined,
	}),

	/**
	 * @fires_when User fills in all the address information and successfully submits it.
	 */
	ApplicationAddressSubmitted: (properties: {
		/**
		 * @format State user selected
		 */
		location: string;
		city: string;
	}) => ({
		event: 'Application Address Submitted',
		properties,
	}),

	/**
	 * @fires_when User fills in all the profession information and successfully submits it.
	 */
	ApplicationProfessionSubmitted: (properties: {
		/**
		 * @format State user selected
		 */
		profession: string;
	}) => ({
		event: 'Application Profession Submitted',
		properties,
	}),

	/**
	 * @fires_when User fills in all the dependents information and successfully submits it.
	 */
	ApplicationDependentsSubmitted: (properties: {
		total_dependents: number;
		total_children: number;
		has_spouse: boolean;
		has_children: boolean;
	}) => ({
		event: 'Application Dependents Submitted',
		properties,
	}),

	/**
	 * @fires_when User selected insurance plans and successfully submits it.
	 */
	ApplicationResult: (properties: {
		monthly_estimate: number;
		total_dependents: number;
		selected_insurance: { name: string; type: string; price: number }[];
		eligible_insurance: { name: string; type: string; price: number }[];
		ineligible_insurance_type: string[];
	}) => ({
		event: 'Application Results',
		properties,
	}),

	/**
	 * @fires_when User selected dependents for each insurance plans and successfully submits it.
	 */
	ApplicationDependentsPlansSubmitted: (properties: {
		monthly_estimate: number;
		total_dependents: number;
		selected_insurance: {
			name: string;
			type: string;
			price: number;
			total_dependents: number;
		}[];
	}) => ({
		event: 'Application Dependents Plans Submitted',
		properties,
	}),

	/**
	 * @fires_when User fills payment information and successfully submits it.
	 */
	ApplicationPaymentSubmitted: (properties: {
		has_billing_address_equal: boolean;
		location: string;
	}) => ({
		event: 'Application Payment Submitted',
		properties,
	}),

	/**
	 * @fires_when User clicks to see benefits of a given insurance plan.
	 */
	PlanSummaryBenefits: (properties: {
		insurance_name: string;
		insurance_type: string;
	}) => ({
		event: 'Plan Summary Benefits',
		properties,
	}),

	/**
	 * @fires_when User fills login information and successfully logged in.
	 */
	UserLoggedIn: () => ({
		event: 'User Logged In',
		properties: undefined,
	}),

	/**
	 * @fires_when User fills sign up information and successfully created account.
	 */
	UserCreatedAccount: () => ({
		event: 'User Created Account',
		properties: undefined,
	}),
} as const;

class AnalyticsManager {
	page(information: AnalyticsPageInformation) {
		window.analytics?.page(information.category, information.page);
		// @ts-ignore
		if (!!window.Intercom) {
			// @ts-ignore
			window?.Intercom('update', {
				page_title: `${information.category} - ${information.page}`,
			});
		}
	}

	track(
		information: ReturnType<
			(typeof AnalyticsTrack)[keyof typeof AnalyticsTrack]
		>
	) {
		window.analytics?.track(information.event, information.properties);

		// @ts-ignore
		if (!!window.Intercom) {
			// @ts-ignore
			window?.Intercom('trackEvent', information.event, information.properties);
		}
	}

	identify({
		userId,
		email,
		name,
		utm_source,
		utm_medium,
		utm_campaign,
		utm_term,
		utm_content,
	}: {
		userId?: string;
		email?: string;
		name?: string;
		utm_source?: string;
		utm_medium?: string;
		utm_campaign?: string;
		utm_term?: string;
		utm_content?: string;
	}) {
		window.analytics?.identify({
			userId,
			email,
			name,
			subdomain_origin: 'Individual',
			utm_source,
			utm_medium,
			utm_campaign,
			utm_term,
			utm_content,
		});

		// @ts-ignore
		if (!!window.Intercom) {
			// @ts-ignore
			window?.Intercom('update', {
				user_id: userId,
				email,
				name,
				subdomain_origin: 'Individual',
				utm_source,
				utm_medium,
				utm_campaign,
				utm_term,
				utm_content,
			});
		}
	}
}

export const analyticsManager = new AnalyticsManager();
