import { Center, Heading, Text } from '@chakra-ui/react';

type ContentHeaderProps = {
	title?: string | React.ReactNode;
	titleClass?: string;
	subTitle?: string | React.ReactNode;
	subTitleClass?: string;
	icon?: React.ReactNode;
};

export const ContentHeader = ({
	title,
	titleClass = '',
	subTitle,
	subTitleClass = '',
	icon,
}: ContentHeaderProps) => (
	<div className='my-6'>
		{icon && <Center className='mb-6'>{icon}</Center>}
		{title && (
			<Heading
				fontSize='2xl'
				_light={{ color: 'gray.900' }}
				fontWeight='bold'
				className={titleClass}>
				{title}
			</Heading>
		)}
		{subTitle && (
			<Text
				fontSize='md'
				fontWeight='medium'
				_light={{ color: 'gray.900' }}
				className={`mt-3 ${subTitleClass}`}>
				{subTitle}
			</Text>
		)}
	</div>
);
