import { Spinner } from '@chakra-ui/react';

type LoadingSectionProps = {
	isLoading: boolean;
	className?: string;
	children?: React.ReactNode;
};

export const LoadingSection = ({
	children,
	isLoading,
	className,
}: LoadingSectionProps) => {
	if (!isLoading) {
		return <>{children}</>;
	}
	return (
		<div className={`flex h-full items-center justify-center ${className}`}>
			<Spinner color='blue.500' />
		</div>
	);
};
