import {
	Skeleton,
	SkeletonText,
	SlideFade,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { AlertError, Content, Header, Page } from 'components';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from 'stores';
import { Status } from 'utils';
import { ApplicationQuoteInfoTab } from './info';
import { ApplicationQuoteResultTab } from './result';

export const ApplicationQuotePage = observer(() => {
	const { quoteStore } = useStores();
	const [currentTab, setCurrentTab] = useState(0);
	const isLoaded = quoteStore.status === Status.Resolved;
	const haveError = quoteStore.status === Status.Rejected;

	return (
		<>
			<Page>
				<Header
					canGoBack={currentTab === 1}
					onClickBack={() => setCurrentTab(0)}
				/>
				<Content className='px-0'>
					{haveError && <AlertError error={quoteStore.error} isResponse />}
					{!isLoaded && (
						<Content scrollY paddingX>
							<Skeleton height='40px' marginTop={5} />
							<Skeleton width={198} height={200} marginTop={5} />
							<SkeletonText
								mt='4'
								noOfLines={1}
								spacing='4'
								skeletonHeight='2'
							/>
							<Skeleton width={'100%'} height={200} marginTop={5} />
							<Skeleton width={'100%'} height={200} marginTop={5} />
							<Skeleton width={'100%'} height={200} marginTop={5} />
							<Skeleton height='20px' marginTop={5} />
						</Content>
					)}
					{isLoaded && (
						<>
							<Tabs
								isLazy
								isFitted
								variant='unstyled'
								className='!flex flex-col overflow-hidden h-full'
								defaultIndex={0}
								index={currentTab}
								onChange={setCurrentTab}>
								<TabPanels className='flex flex-col grow overflow-hidden mt-1'>
									<TabPanel className='overflow-hidden h-full !p-0'>
										<SlideFade in={true}>
											<ApplicationQuoteInfoTab
												setCurrentTab={(val) => setCurrentTab(val)}
											/>
										</SlideFade>
									</TabPanel>
									<TabPanel className='overflow-hidden h-full !p-0'>
										<SlideFade in={true}>
											<ApplicationQuoteResultTab
												setCurrentTab={(val) => setCurrentTab(val)}
											/>
										</SlideFade>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</>
					)}
				</Content>
			</Page>
		</>
	);
});
