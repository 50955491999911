import {
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { GlobalError } from 'react-hook-form';
import { Logger } from 'utils';

type CustomFormControlProps = FormControlProps & {
	label?: string;
	error?: GlobalError;
	renderAfterError?: ReactNode;
};

export const CustomFormControl = ({
	label,
	children,
	error,
	renderAfterError,
	...props
}: CustomFormControlProps) => {
	const renderError = () => {
		if (!error) {
			return null;
		}

		const getMessage = () => {
			switch (error.type) {
				case 'required':
					return 'This field is required.';
				case 'minLength':
					return 'Invalid minimum length.';
				default:
					if (!error.message) {
						Logger.error({
							message: 'Form error without description',
							extraData: error,
						});
					}
					return error.message;
			}
		};

		const message = getMessage();

		if (!message) {
			return null;
		}

		return <FormErrorMessage fontSize='xs'>{message}</FormErrorMessage>;
	};

	return (
		<FormControl isInvalid={!!error} {...props}>
			{label && (
				<FormLabel
					fontSize='md'
					_light={{ color: 'gray.900' }}
					fontWeight='400'>
					{label}
				</FormLabel>
			)}
			{children}

			{renderError()}
			{renderAfterError}
		</FormControl>
	);
};
