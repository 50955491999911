import { Badge, Heading, Text } from '@chakra-ui/react';
import { T } from '@tolgee/react';
import dayjs from 'dayjs';
import { Transaction } from 'models';
import { currencyFormat } from 'utils';

type Props = {
	data: Transaction[];
};

export const PaymentList = ({ data }: Props) => {
	return (
		<div className='flex flex-col p-4 gap-4 rounded border border-gray-200'>
			<div className='flex flex-row justify-between'>
				<Heading fontSize='lg' _light={{ color: 'gray.900' }} fontWeight='bold'>
					<T>Payment history</T>
				</Heading>
			</div>

			{data?.map((item: Transaction) => (
				<TransactionItem key={item.uuid} item={item} />
			))}
		</div>
	);
};

const TransactionItem = ({ item: data }: { item: Transaction }) => {
	return (
		<div className='flex flex-row justify-between'>
			<div>
				<Heading fontSize='xl'>{currencyFormat(data.net)}</Heading>
				<Text fontSize='xs'>{dayjs(data.date).format('MMMM D, YYYY')}</Text>
			</div>
			<div>
				<Badge>
					<T>{data.status}</T>
				</Badge>
			</div>
		</div>
	);
};
