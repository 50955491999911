import {
	ArrowBackIcon,
	HamburgerIcon,
	MoonIcon,
	SunIcon,
} from '@chakra-ui/icons';
import {
	Button,
	IconButton,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	useColorMode,
} from '@chakra-ui/react';
import { T, useTolgee } from '@tolgee/react';
import { ReactComponent as LogoImage } from 'assets/logos/gig-easy-icon-blue.svg';
import { config } from 'config';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

type HeaderProps = {
	canGoBack?: boolean;
	onClickBack?: () => void;
};

export const Header = observer(({ canGoBack, onClickBack }: HeaderProps) => {
	const { authenticationStore } = useStores();
	const navigate = useNavigate();
	const tolgee = useTolgee(['language']);
	const { colorMode, toggleColorMode } = useColorMode();

	const logout = () => {
		authenticationStore.logout();
		navigate(RoutePath.login);
	};

	const login = () => {
		navigate(RoutePath.login);
	};

	const languages: Record<string, string> = {
		'en-US': '🇺🇸 English',
		es: '🇪🇸 Espanol',
	};

	return (
		<div className='h-12 justify-between flex flex-row items-center w-full max-w-3xl m-auto my-4 px-4'>
			{canGoBack && (
				<Button
					_light={{ color: 'blackAlpha.900' }}
					variant='link'
					onClick={onClickBack}>
					<ArrowBackIcon height='24px' width='24px' />
				</Button>
			)}

			<Link href={RoutePath.root} alignContent={'center'}>
				<LogoImage width={102} height={33} />
			</Link>

			<Menu>
				<MenuButton
					as={IconButton}
					aria-label='Menu'
					icon={<HamburgerIcon />}
					variant='outline'
				/>
				<MenuList>
					{authenticationStore.isLoggedIn && (
						<>
							<MenuItem cursor={'unset'}>
								{authenticationStore.customer.email}
							</MenuItem>
							<MenuDivider />
							<MenuItem onClick={logout}>
								<T>Log out</T>
							</MenuItem>
						</>
					)}
					{!authenticationStore.isLoggedIn && (
						<MenuItem onClick={login}>
							<T>Log in</T>
						</MenuItem>
					)}
					<MenuItem
						onClick={() => window.open('https://help.gigeasy.com/', '_blank')}>
						<T>Help & Support</T>
					</MenuItem>
					{!config.is_production && (
						<>
							<MenuDivider />
							{Object.entries(languages)
								.filter(([key, _]) => tolgee.getLanguage() !== key)
								.map(([key, value]) => (
									<MenuItem
										key={value}
										onClick={() => tolgee.changeLanguage(key)}>
										{value}
									</MenuItem>
								))}
						</>
					)}
					<MenuDivider />
					<MenuItem
						onClick={toggleColorMode}
						icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}>
						{colorMode === 'light' ? <T>Dark mode</T> : <T>Light mode</T>}
					</MenuItem>
				</MenuList>
			</Menu>
		</div>
	);
});
