import {
	Badge,
	Heading,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { CustomButton } from 'components';

import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import dayjs from 'dayjs';
import { Transaction } from 'models';
import { useState } from 'react';
import { currencyFormat } from 'utils';
import { InfoIcon } from '@chakra-ui/icons';

type Props = {
	data: Transaction;
};

export const LatestPayment = ({ data }: Props) => {
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const {
		isOpen: isOpenDeductionHelper,
		onOpen: opOpenDeductionHelper,
		onClose: onCloseDeductionHelper,
	} = useDisclosure();

	const renderDetails = () => {
		return (
			<div className='flex flex-col'>
				<Text fontSize='xs'>
					<T>Gross Pay</T>
				</Text>
				<Text fontSize='xs' fontWeight='bold'>
					{currencyFormat(data.gross)}
				</Text>
				<div className='flex flex-row'>
					<Text fontSize='xs'>
						<T>Deductions</T>
					</Text>
					<IconButton
						aria-label='Add to friends'
						variant='link'
						size='xs'
						colorScheme='primary'
						onClick={() => opOpenDeductionHelper()}
						icon={<InfoIcon />}
					/>
				</div>
				<Text fontSize='xs' fontWeight='bold'>
					{currencyFormat(data.discount)}
				</Text>
			</div>
		);
	};

	return (
		<div className='flex flex-col p-4 gap-4 rounded border border-gray-200'>
			<div className='flex flex-row justify-between'>
				<Heading fontSize='lg' _light={{ color: 'gray.900' }} fontWeight='bold'>
					<T>Latest Payment</T>
				</Heading>
				<Text fontSize='xs'>{dayjs(data.date).format('MMMM D, YYYY')}</Text>
			</div>
			<div className='flex flex-row justify-between'>
				<div>
					<Heading fontSize='xl'>{currencyFormat(data.net)}</Heading>
					<div>
						<Badge>
							<T>{data.status}</T>
						</Badge>
					</div>
					{showDetails && renderDetails()}
					<CustomButton
						variant='link'
						className='gap-1 flex flex-row'
						color='blue.400'
						onClick={() => setShowDetails((x) => !x)}>
						{showDetails ? <T>Show less</T> : <T>Show details</T>}
						<ChevronDownIcon
							className={`${
								showDetails ? 'transform rotate-180' : ''
							} transition ease-in-out duration-500`}
							width={24}
						/>
					</CustomButton>
				</div>
			</div>
			<Modal
				isOpen={isOpenDeductionHelper}
				onClose={onCloseDeductionHelper}
				isCentered
				size='sm'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T>Deductions</T>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text className='pb-4'>
							<T>
								Your employer is required by law to provide coverage to their
								employees to ensure they are protected in the event of an
								occupational accident such as an injury or illness that occurs
								as a direct result of an individual's work-related activities.
								Deductions are the amount that your employer has deducted from
								your pay to offset some of this coverage.
							</T>
						</Text>
					</ModalBody>
				</ModalContent>
			</Modal>
		</div>
	);
};
