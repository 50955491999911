import { Button, ButtonProps, Spinner } from '@chakra-ui/react';
import { useState } from 'react';

type CustomButtonProps = { isLoadingOnlyIcon?: boolean } & ButtonProps;

export const CustomButton = ({
	children,
	onClick: onClickProps,
	rightIcon,
	leftIcon,
	isDisabled: isDisabledProps,
	isLoading: isLoadingProps,
	isLoadingOnlyIcon,
	...props
}: CustomButtonProps) => {
	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	const fullLoading = !isLoadingOnlyIcon;
	const iconLoading = isLoadingOnlyIcon;
	const isLoading = isLoadingState || isLoadingProps;

	const onClick = async (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (onClickProps) {
			const isPromise = (p: any) => {
				if (typeof p === 'object' && typeof p.then === 'function') {
					return true;
				}

				return false;
			};
			const action = onClickProps(event);

			if (isPromise(action)) {
				setIsLoadingState(true);
				await action;
				setIsLoadingState(false);
			}
		}
	};

	return (
		<Button
			{...props}
			onClick={onClick}
			isDisabled={isLoading || isDisabledProps}
			isLoading={fullLoading && isLoading}
			leftIcon={iconLoading && isLoading && leftIcon ? <Spinner /> : leftIcon}
			rightIcon={
				iconLoading && isLoading && rightIcon ? <Spinner /> : rightIcon
			}>
			{children}
		</Button>
	);
};
