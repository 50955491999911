import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
	fonts: {
		heading: `'Inter', sans-serif`,
		body: `'Inter', sans-serif`,
	},
	colors: {
		primary: {
			100: '#3E8FCA',
			200: '#3E8FCA',
			300: '#3E8FCA',
			400: '#3E8FCA',
			500: '#3E8FCA',
			600: '#3E8FCA',
			700: '#3E8FCA',
			800: '#3E8FCA',
			900: '#3E8FCA',
		},
	},
});
