import { Endpoints, coreApi } from 'api';
import { QueryKey } from 'constant';
import { useQuery } from '@tanstack/react-query';
import { TimesheetEntryAPIDTO } from "models/timesheet";

export function useTimesheetEntries(orgUuid: string, start: string, end: string) {
	return useQuery<TimesheetEntryAPIDTO[], any>({
		queryKey: [QueryKey.timesheetEntries, orgUuid, start, end],
		queryFn: () => {
			if (!orgUuid) return null;

			return coreApi
				.get<TimesheetEntryAPIDTO[]>(`${Endpoints.timesheetEntries}?organization_uuid=${orgUuid}&start=${start}&end=${end}`)
				.then((response) => response as unknown as TimesheetEntryAPIDTO[]);
		},
		refetchOnWindowFocus: false,
	});
}

export function useTimesheetEntry(orgUuid: string, entryUuid: string) {
	return useQuery<TimesheetEntryAPIDTO, any>({
		queryKey: [QueryKey.timesheetEntries, orgUuid, entryUuid],
		queryFn: () => coreApi
			.get<TimesheetEntryAPIDTO>(`${Endpoints.timesheetEntries}/${orgUuid}/${entryUuid}`)
			.then((response) => response as unknown as TimesheetEntryAPIDTO),
		refetchOnWindowFocus: false,
	});
}
