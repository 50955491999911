import { Content, Header, Page } from 'components';
import { EmailValidationModal } from 'components/email-validation-modal';
import {
	useMutationEmailCodeSend,
	useMutationEmailCodeValidate,
} from 'hooks/customer';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

export const ValidateEmailPage = observer(() => {
	const { authenticationStore } = useStores();
	const navigate = useNavigate();

	useEffect(() => {
		if (authenticationStore.isEmailAuthenticated) {
			navigate(RoutePath.root);
		}
	}, [
		authenticationStore.isLoggedIn,
		authenticationStore.isEmailAuthenticated,
		navigate,
	]);

	const isLoading = !authenticationStore.isSetupFinished;

	const mutationEmailCodeSend = useMutationEmailCodeSend(
		authenticationStore.customer.uuid
	);
	const mutationEmailValidate = useMutationEmailCodeValidate(
		authenticationStore.customer.uuid
	);

	return (
		<Page>
			<Header />
			<Content paddingX scrollY isLoading={isLoading}>
				<EmailValidationModal
					email={authenticationStore.customer.email}
					canEdit={false}
					isErrorCodeSend={mutationEmailCodeSend.isError}
					isErrorValidateEmail={mutationEmailValidate.isError}
					dispatchCode={async () => {
						await mutationEmailCodeSend.mutateAsync();
					}}
					validateEmail={async (code: string) => {
						const response = await mutationEmailValidate.mutateAsync(code);
						if (response.validated) {
							await authenticationStore.validateEmail();
							return;
						} else {
							throw Error('Invalid code.');
						}
					}}
				/>
			</Content>
		</Page>
	);
});
