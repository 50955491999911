import {
	Content,
	ContentHeader,
	CustomButton,
	Footer,
	Header,
	Page,
} from 'components';
import { ReactComponent as DogDisconnect } from 'assets/dog-disconnect.svg';
import { useNavigate } from 'react-router-dom';
import { T } from '@tolgee/react';
import { RoutePath } from 'routes';
import { FallbackProps } from 'react-error-boundary';

type ErrorCatchPageProps = {
	error?: FallbackProps;
};

export const ErrorCatchPage = ({ error }: ErrorCatchPageProps) => {
	const navigate = useNavigate();
	return (
		<Page>
			<Header />
			<Content paddingX>
				<ContentHeader
					icon={<DogDisconnect width={200} height={200} />}
					title="Uh-oh, it's a ruff day!"
					titleClass='text-center'
					subTitle='Something went wrong, and our team is on it! Please check back again in a few minutes. If the problem persists, please contact Support.'
					subTitleClass='text-center'
				/>
			</Content>
			<Footer>
				<CustomButton
					bg='blue.500'
					color='white'
					size='lg'
					fontSize='lg'
					variant='solid'
					width='100%'
					_hover={{
						bg: 'blue.300',
					}}
					onClick={() => {
						navigate(RoutePath.root);
						error?.resetErrorBoundary();
					}}>
					<T>Refresh</T>
				</CustomButton>
			</Footer>
		</Page>
	);
};
