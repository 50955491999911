import { PlusSquareIcon } from '@chakra-ui/icons';
import { Accordion, Button, Stack, useDisclosure } from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { Content, CustomButton, Footer, Page } from 'components';
import { ConfirmDialog } from 'components/confirm/confirm-dialog';
import { CustomFormControl, CustomRadio } from 'components/form';
import {
	useApplicationDependentMutation,
	useApplicationDependents,
} from 'hooks';
import { observer } from 'mobx-react';
import { ApplicationDependent } from 'models';
import { useEffect, useState } from 'react';
import { settings } from 'settings';
import { useStores } from 'stores';
import { ApplicationDependentAccordion } from './accordion';
import { ApplicationDependentForm } from './dependent-form';

type ApplicationPersonalInfoDependentsProps = {
	next: () => void;
};

export const ApplicationPersonalInfoDependents = observer(
	({ next }: ApplicationPersonalInfoDependentsProps) => {
		const { t } = useTranslate();
		const { applicationStore, quoteStore } = useStores();

		useEffect(() => {
			analyticsManager.page(AnalyticsPage.Application.PersonalInfoDependents);
		}, []);

		const {
			isOpen: isOpenRemoveDependent,
			onOpen: onOpenRemoveDependent,
			onClose: onCloseRemoveDependent,
		} = useDisclosure();

		const {
			isOpen: isOpenRemoveAllDependents,
			onOpen: onOpenRemoveAllDependents,
			onClose: onCloseRemoveAllDependents,
		} = useDisclosure();

		const [removeDependentIndex, setRemoveDependentIndex] = useState<
			number | undefined
		>(undefined);

		const [dependentForm, setDependentForm] =
			useState<ApplicationDependent>(null);

		const { data: dependents = [], isLoading: isLoadingDependents, isRefetching: isRefetchingDependends } =
			useApplicationDependents(applicationStore.application.uuid);

		const [haveDependents, setHaveDependents] = useState<boolean>(
			quoteStore.quote.dependents > 0 || dependents.length > 0
		);
		const haveMaxDependents =
			dependents?.length >= settings.application.max_dependents;

		useEffect(() => {
			if (dependents.length > 0) {
				setHaveDependents(true);
			}
		}, [dependents]);

		const applicationDependentMutation = useApplicationDependentMutation(
			applicationStore.application.uuid
		);

		const isLoading = isLoadingDependents || !applicationStore.isSetupFinished;

		const haveDependentsOptions = [
			{ value: '1', text: 'Yes' },
			{ value: '0', text: 'No' },
		];
		const haveSpouse: boolean = dependents.some((d) => d.spouse);

		const editEmptyDependent = (spouse?: boolean) => {
			setDependentForm({
				spouse: !!spouse,
				first_name: '',
				last_name: '',
				birthday: '',
				gender: null,
			});
		};

		useEffect(() => {
			if (isLoading || isRefetchingDependends) {
				return;
			}
			if (haveDependents) {
				if (dependents.length === 0 && dependentForm === null) {
					editEmptyDependent(quoteStore.quote.spouse === 1);
				}
			} else {
				setDependentForm(null);
			}
		}, [
			isLoading,
			isRefetchingDependends,
			haveDependents,
			dependents,
			quoteStore.quote.spouse,
			dependentForm,
		]);

		const addDependent = async (dependent: ApplicationDependent) => {
			await applicationDependentMutation.mutateAsync({
				method: 'UPSERT',
				body: dependent,
			});
			setDependentForm(null);
		};

		const deleteAllDependents = async () => {
			setHaveDependents(false);
			await Promise.all(
				dependents
					.filter((dependent) => dependent.uuid)
					.map((dependent) =>
						applicationDependentMutation.mutateAsync({
							method: 'DELETE',
							uuid: dependent.uuid,
						})
					)
			);
		};

		const deleteDependent = async (index: number) => {
			const uuid = dependents[index].uuid;
			await applicationDependentMutation.mutateAsync({
				method: 'DELETE',
				uuid,
			});
			setRemoveDependentIndex(undefined);
		};

		const confirmDeleteDependent = (index: number) => {
			onOpenRemoveDependent();
			setRemoveDependentIndex(index);
		};

		const editDependent = async (
			dependent: ApplicationDependent,
			index: number
		) => {
			const uuid = dependents[index].uuid;
			await applicationDependentMutation.mutateAsync({
				method: 'UPSERT',
				uuid,
				body: dependent,
			});
			setDependentForm(null);
		};

		const closeEditForms = () => {
			if (dependents.some((d) => d === dependentForm)) {
				setDependentForm(null);
			}
		};

		const renderConfirmDeleteDependent = () => {
			return (
				<ConfirmDialog
					isOpen={isOpenRemoveDependent}
					onClose={onCloseRemoveDependent}
					onConfirm={() => deleteDependent(removeDependentIndex)}
					header={t('Remove dependent')}
					message={t('Are you sure you want to remove this dependent?')}
				/>
			);
		};

		const renderConfirmDeleteAllDependents = () => {
			return (
				<ConfirmDialog
					isOpen={isOpenRemoveAllDependents}
					onClose={onCloseRemoveAllDependents}
					onConfirm={() => deleteAllDependents()}
					header={t('Remove dependents')}
					message={t('Are you sure you want to remove all dependents?')}
				/>
			);
		};

		return (
			<Page>
				<Content paddingX scrollY isLoading={isLoading}>
					<Stack spacing='6'>
						<CustomFormControl
							label={t(
								'Do you have a spouse or dependents you want to include on your policy(s)?'
							)}>
							<div className='pt-4'>
								<CustomRadio
									options={haveDependentsOptions}
									value={haveDependents ? '1' : '0'}
									onChange={(val) => {
										if (val === '1') {
											setHaveDependents(true);
										} else {
											if (dependents.length > 0) {
												onOpenRemoveAllDependents();
											} else {
												deleteAllDependents();
											}
										}
									}}
								/>
							</div>
						</CustomFormControl>
						<Accordion
							allowToggle
							onChange={(a) => {
								if (a === -1) {
									closeEditForms();
								}
							}}>
							{dependents &&
								dependents.map((dependent, index) => (
									<ApplicationDependentAccordion
										key={index}
										index={index}
										isEdit={dependentForm === dependent}
										dependent={dependent}
										onEdit={async () => setDependentForm(dependent)}
										onRemove={async () => confirmDeleteDependent(index)}>
										{dependentForm === dependent && (
											<ApplicationDependentForm
												haveSpouse={haveSpouse}
												dependent={dependent}
												addDependent={async (dependent) =>
													editDependent(dependent, index)
												}
												onCancel={() => setDependentForm(null)}
											/>
										)}
									</ApplicationDependentAccordion>
								))}
						</Accordion>
						{dependentForm && !dependents.some((d) => d === dependentForm) && (
							<ApplicationDependentForm
								disableCancel={dependents.length === 0}
								haveSpouse={haveSpouse}
								dependent={dependentForm}
								addDependent={(dependent) => addDependent(dependent)}
								onCancel={() => setDependentForm(null)}
							/>
						)}
						{!dependentForm && haveDependents && (
							<Button
								size='sm'
								leftIcon={<PlusSquareIcon />}
								variant='outline'
								color='blue.500'
								isDisabled={haveMaxDependents}
								onClick={() => editEmptyDependent()}>
								Add another dependent
							</Button>
						)}
					</Stack>
					{renderConfirmDeleteDependent()}
					{renderConfirmDeleteAllDependents()}
				</Content>
				<Footer>
					<CustomButton
						bg='blue.500'
						color='white'
						size='lg'
						fontSize='lg'
						variant='solid'
						width='100%'
						isDisabled={
							isLoading ||
							(haveDependents && dependents.length === 0) ||
							!!dependentForm
						}
						_hover={{
							bg: 'blue.300',
						}}
						onClick={() => {
							analyticsManager.track(
								AnalyticsTrack.ApplicationDependentsSubmitted({
									total_dependents: dependents.length,
									has_spouse: !!dependents.find((d) => d.spouse),
									has_children: !!dependents.find((d) => !d.spouse),
									total_children: dependents.filter((d) => !d.spouse).length,
								})
							);
							next();
						}}>
						<T>Next</T>
					</CustomButton>
				</Footer>
			</Page>
		);
	}
);
