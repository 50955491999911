import { Stack } from '@chakra-ui/react';
import { useTranslate } from '@tolgee/react';
import { ErrorResponse } from 'api';
import {
	AlertError,
	Content,
	ContentHeader,
	CustomButton,
	Header,
	Page,
} from 'components';
import { CustomFormControl, CustomInput } from 'components/form';
import { observer } from 'mobx-react';
import { CustomerForgotPassword } from 'models';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

export const ForgotPasswordPage = observer(() => {
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { authenticationStore } = useStores();
	const [errorResponse, setErrorResponse] = useState<ErrorResponse>(null);
	const [submitted, setSubmitted] = useState(false);

	const defaultValues = {
		email: '',
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CustomerForgotPassword>({
		defaultValues,
		mode: 'onSubmit',
	});

	const goBack = () => {
		navigate(RoutePath.login);
	};

	const onSubmit = async (data: CustomerForgotPassword) => {
		try {
			setErrorResponse(null);
			await authenticationStore.forgotPassword(data);
			setSubmitted(true);
		} catch (error: any) {
			setErrorResponse(error);
		}
	};

	const subTitle = submitted
		? 'An email has been sent to the provided address with instructions on how to reset your password. Please check your inbox and follow the link	provided to complete the password reset process.'
		: 'Your password reset link will be sent to the registered email address';

	return (
		<Page>
			<Header canGoBack onClickBack={goBack} />
			<Content scrollY paddingX>
				<ContentHeader title='Reset your password' subTitle={subTitle} />
				{submitted && (
					<Stack>
						<CustomButton
							bg='blue.500'
							color='white'
							className='flex grow'
							size='lg'
							variant='solid'
							_hover={{
								bg: 'blue.300',
							}}
							onClick={goBack}>
							Back
						</CustomButton>
					</Stack>
				)}
				{!submitted && (
					<Stack gap='4'>
						<CustomFormControl label={t('Email *')} error={errors.email}>
							<CustomInput
								type='email'
								placeholder={t('Enter email')}
								{...register('email', {
									required: true,
								})}
							/>
						</CustomFormControl>

						<AlertError error={errorResponse} isResponse />

						<Stack direction='row' paddingY='4' paddingBottom='8'>
							<CustomButton
								bg='blue.500'
								color='white'
								className='flex grow'
								size='lg'
								variant='solid'
								_hover={{
									bg: 'blue.300',
								}}
								onClick={handleSubmit(onSubmit)}>
								Reset password
							</CustomButton>
						</Stack>
					</Stack>
				)}
			</Content>
		</Page>
	);
});
