export const QueryKey = {
	locationStates: 'locationStates',
	insuranceTypes: 'insuranceTypes',
	professions: 'professions',
	professionTypes: 'professionTypes',
	quoteResults: 'quoteResults',
	payments: 'payments',
	applicationResults: 'applicationResults',
	applicationDependents: 'applicationDependents',
	timesheetEntries: 'timesheetEntries',
};

export const MutationKey = {
	quotePlanUpdate: 'quotePlanUpdate',
	quoteEmailCodeSend: 'quoteEmailCodeSend',
	quoteEmailCodeValidate: 'quoteEmailCodeValidate',
	applicationPlanUpdate: 'applicationPlanUpdate',
	applicationDependentsUpdate: 'applicationDependentsUpdate',
	customerEmailCodeSend: 'customerEmailCodeSend',
	customerEmailCodeValidate: 'customerEmailCodeValidate',
	timesheetEntries: 'timesheetEntries',
	timesheetEntry: 'timesheetEntry',
	timesheetEntrySubmit: 'timesheetEntrySubmit',
};
