import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import flatpickr from 'flatpickr';
import { startOfWeek } from 'date-fns';
import 'flatpickr/dist/flatpickr.min.css';
import { useEffect, useMemo } from 'react';
import { getStartAndEndOfWeek } from 'utils/date/get-start-and-end-of-week';

type Props = {
	/**
	 *
	 * @param date YYYY-MM-DD
	 * @returns
	 */
	onChange: (date: Date[] | null) => void;
	dateRef: { start: Date; end: Date };
};

export const SelectDateRange = ({ onChange, dateRef }: Props) => {
	const { currentStart, currentEnd } = useMemo(() => {
		const { start: currentStart, end: currentEnd } = dateRef
			? dateRef
			: getStartAndEndOfWeek(startOfWeek(new Date()));

		return {
			currentStart,
			currentEnd,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRef.start, dateRef.end]);

	useEffect(() => {
		const fp = flatpickr('.datepicker', {
			static: true,
			mode: 'range',
			monthSelectorType: 'static',
			closeOnSelect: false,
			dateFormat: 'M j, Y',
			defaultDate: [currentStart, currentEnd],
			prevArrow:
				'<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
			nextArrow:
				'<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
			onChange,
		});

		return () => {
			(fp as flatpickr.Instance)?.destroy();
		};
	}, [onChange, currentStart, currentEnd]);

	return (
		<div className='relative w-full'>
			<input
				className='datepicker w-[120%] rounded border border-stroke bg-white py-2 pl-10 pr-4 text-sm font-medium shadow-card-2 focus-visible:outline-none dark:border-dark-stroke dark:bg-dark-box'
				placeholder='Select dates'
				data-class='flatpickr-right'
			/>
			<div className='pointer-events-none absolute inset-0 left-4 right-auto flex items-center text-light-stroke-2'>
				<CalendarIcon />
			</div>
		</div>
	);
};
