import { T } from '@tolgee/react';
import { AnalyticsPage, analyticsManager } from 'analytics';
import { ContentHeader } from 'components';
import { ProductSelectPlans } from 'components/product-select-plans';
import { useApplicationPlansMutation, useApplicationResults } from 'hooks';
import { observer } from 'mobx-react';
import { ApplicationDependent } from 'models';
import { useEffect } from 'react';
import { useStores } from 'stores';

type ApplicationConfirmBenefitsProps = {
	dependents: ApplicationDependent[];
};

export const ApplicationConfirmBenefits = observer(
	({ dependents }: ApplicationConfirmBenefitsProps) => {
		const { applicationStore } = useStores();

		const {
			data: applicationResult,
			isRefetching: isRefetchingResult,
			refetch: refetchResult,
		} = useApplicationResults(applicationStore.application.uuid);

		const dependentsCount = dependents?.length ?? 0;
		const mutationApplicationPlans = useApplicationPlansMutation(
			applicationStore.application.uuid
		);

		useEffect(() => {
			analyticsManager.page(AnalyticsPage.Application.InsurancePlans);
		}, []);

		const removeInsuranceType = async (insuranceType: string) => {
			await applicationStore.toggleInsuranceType({ code: insuranceType });
			await refetchResult();
		};

		const includeInsuranceType = async (insuranceType: string) => {
			await applicationStore.toggleInsuranceType({ code: insuranceType });
			await refetchResult();
		};

		return (
			<>
				<ContentHeader
					title="You've completed part 1"
					subTitle={
						<>
							<T>That was quick! Now choose your benefits.</T>
							<> </>
							{dependentsCount > 0 && (
								<T
									keyName={'application-completed-description-dependents'}
									params={{
										dependentsCount,
									}}
									defaultValue={
										'Prices below are for you and {dependentsCount, plural, one {one dependent} other {# dependents}}. You can remove {dependentsCount, plural, one {the dependent} other {dependents}} from each benefit on the next screen.'
									}
								/>
							)}
						</>
					}
				/>
				<ProductSelectPlans
					result={applicationResult}
					isRefetching={isRefetchingResult}
					onChangePlan={(uuidPlan: string) => {
						mutationApplicationPlans.mutate(uuidPlan);
					}}
					onRemoveInsuranceType={removeInsuranceType}
					onIncludeInsuranceType={includeInsuranceType}
				/>
			</>
		);
	}
);
