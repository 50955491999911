import { Endpoints, api } from 'api';
import { MutationKey } from 'constant';
import { useMutation } from '@tanstack/react-query';

export function useMutationEmailCodeSend(uuidCustomer: string) {
	return useMutation({
		mutationKey: [MutationKey.customerEmailCodeSend, uuidCustomer],
		mutationFn: () =>
			api
				.get(Endpoints.customerEmail(uuidCustomer))
				.then((response) => response.data),
	});
}

export function useMutationEmailCodeValidate(uuidCustomer: string) {
	return useMutation({
		mutationKey: [MutationKey.customerEmailCodeValidate, uuidCustomer],
		mutationFn: (code: string) =>
			api
				.post<{ validated: boolean }, { code: string }>(
					Endpoints.customerEmail(uuidCustomer),
					{ code }
				)
				.then((response) => response.data),
	});
}
