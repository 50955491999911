import { PlanResult } from 'models';

export const analyticsMapFromPlanResult = (
	result: PlanResult,
	total_dependents: number
): {
	monthly_estimate: number;
	ineligible_insurance_type: string[];
	total_dependents: number;
	selected_insurance: {
		name: string;
		type: string;
		price: number;
		total_dependents: number;
	}[];
	eligible_insurance: { name: string; type: string; price: number }[];
} => {
	const eligible_insurance = Object.entries(
		result?.insurance_types?.eligible ?? {}
	)
		.map(([key, plan]) => {
			return plan.products.map((product) => ({
				isSelected: product.id === plan.product_id,
				total_dependents: plan?.dependents
					? Object.keys(plan?.dependents ?? {}).length
					: undefined,
				id: product.id,
				name: product.name,
				type: key,
				price: product.price,
			}));
		})
		.flat();

	return {
		monthly_estimate: result?.total,
		total_dependents,
		ineligible_insurance_type: result?.insurance_types?.ineligible,
		eligible_insurance: eligible_insurance.map(({ name, type, price }) => ({
			name,
			type,
			price,
		})),
		selected_insurance: eligible_insurance
			.filter((p) => p.isSelected)
			.map(({ name, type, price, total_dependents }) => ({
				name,
				type,
				price,
				total_dependents,
			})),
	};
};
