import convertCentsToDollars from "./convert-cents-to-dollars";
import CurrencyFormatter from "./currency-formater";
import FractionCurrencyFormatter from "./fraction-currency-formatter";

export default function formatCurrency(val = '0', areCents = true) {
  let value = Number(val) || 0;

  if (areCents) {
    value = convertCentsToDollars(value);
  }

  return value % 1 === 0
    ? CurrencyFormatter.format(value)
    : FractionCurrencyFormatter.format(value);
}
