import { Heading } from "@chakra-ui/react";
import { Content } from 'components';

export const TimesheetEmptyState = () => {
	return (
		<div className="space-y-5">
			<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold'>
				Timesheets
			</Heading>
			<Content>
				<div className="space-y-5">
					<div className="w-[80%] flex text-center justify-center m-auto">
						GigEasy Timesheets is here to revolutionize the way you log your hours, 
						making it simpler and more efficient than ever before.
					</div>
					<div className="w-[80%] flex text-center justify-center m-auto">
						Ask your company about this feature to get started.
					</div>
				</div>
			</Content>
		</div>
);
}
