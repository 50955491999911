import { Endpoints, coreApi } from 'api';
import { QueryKey } from 'constant';
import { LocationState } from 'models';
import { useQuery } from '@tanstack/react-query';
import { millisecondsIn } from 'utils';

const staleTime = millisecondsIn.day;

export function useLocationStates() {
	return useQuery<LocationState[], any>({
		queryKey: [QueryKey.locationStates],
		queryFn: () =>
			coreApi
				.get<LocationState[]>(Endpoints.states)
				.then((response) => response.data),
		staleTime,
	});
}
