import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FormatIcu } from '@tolgee/format-icu';
import {
	DevTools,
	ObserverPlugin,
	Tolgee,
	TolgeeProvider,
} from '@tolgee/react';
import { config } from 'config';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RootStore, RootStoreContext } from 'stores';
import { theme } from 'theme';
import enLocale from './i18n/en-US.json';
import esLocale from './i18n/es.json';

const queryClient = new QueryClient();

const tolgee = Tolgee()
	.use(DevTools())
	.use(ObserverPlugin())
	.use(FormatIcu())
	.init({
		defaultLanguage: 'en-US',
		apiKey: config.tolgee.apiKey,
		apiUrl: config.tolgee.apiUrl,
		staticData: {
			'en-US': enLocale,
			es: esLocale,
		},
	});

export function Providers({ children }) {
	const [rootStore, setRootStore] = useState<RootStore>();

	useEffect(() => {
		const store = new RootStore();
		setRootStore(store);
	}, []);

	if (!rootStore) {
		return null;
	}

	return (
		<RootStoreContext.Provider value={rootStore}>
			<QueryClientProvider client={queryClient}>
				<TolgeeProvider tolgee={tolgee}>
					<ChakraProvider theme={theme}>
						{config.enable_analytics && (
							<Helmet>
								<script>
									{`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/uyb3v8ou';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
								</script>
								<script>
									{`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="U9hx9HYp3HJpd2b1nzHAmw0mZRoBENV3";;analytics.SNIPPET_VERSION="4.16.1";
  analytics.load("${config.segment_key}");
  }}();`}
								</script>
								<script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WLZ37FQ9');`}</script>
								<script>{`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}`}</script>
								<noscript>
									{`<iframe
							src='https://www.googletagmanager.com/ns.html?id=GTM-WLZ37FQ9'
							height='0'
							width='0'
							style='display:none;visibility:hidden'></iframe>`}
								</noscript>
								<script>
									{`window.intercomSettings = {
                                    app_id: "uyb3v8ou",
                                    hide_default_launcher: true
                                    };
                                    `}
								</script>
								<script>
									{`window.Intercom("boot", {
                                    api_base: "https://api-iam.intercom.io",
                                    app_id: "uyb3v8ou"
                                    });`}
								</script>
							</Helmet>
						)}
						<link
							rel='stylesheet'
							href='https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css'
						/>
						{children}
					</ChakraProvider>
				</TolgeeProvider>
			</QueryClientProvider>
		</RootStoreContext.Provider>
	);
}
