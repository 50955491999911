import { Endpoints, api } from 'api';
import { QueryKey } from 'constant';
import { QuoteResult } from 'models';
import { useQuery } from '@tanstack/react-query';

export function useQuoteResults(uuid: string) {
	return useQuery<QuoteResult, any>({
		queryKey: [QueryKey.quoteResults, uuid],
		queryFn: () =>
			api
				.get<QuoteResult>(Endpoints.quotesResults(uuid))
				.then((response) => response.data),
	});
}
