import { Select, Stack } from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { Content, CustomButton, Footer, Page, SecurityNote } from 'components';
import { CustomFormControl, CustomInput } from 'components/form';
import { observer } from 'mobx-react';
import { Application, ApplicationGender } from 'models';
import { useEffect, useState } from 'react';
import { Form, useForm } from 'react-hook-form';
import { useStores } from 'stores';
import { formatDateIfValid, getGenderText } from 'utils';
import { isValidAge, isValidDate } from 'utils/form';

type ApplicationPersonalInfoAboutYouProps = {
	next: () => void;
};

type FormValues = Pick<Application, 'birthday' | 'ssn' | 'gender'>;

export const ApplicationPersonalInfoAboutYou = observer(
	({ next }: ApplicationPersonalInfoAboutYouProps) => {
		const { t } = useTranslate();
		const { applicationStore } = useStores();
		const [ssnType, setSsnType] = useState<'password' | 'tel'>('password');

		useEffect(() => {
			analyticsManager.page(AnalyticsPage.Application.PersonalInfoAboutYou);
		}, []);

		const updateApplication = (data) => {
			const { birthday } = data;
			applicationStore.updateApplication({
				...data,
				birthday: formatDateIfValid(birthday, 'MM/DD/YYYY', 'YYYY-MM-DD'),
			});
		};

		const defaultValues = {
			birthday:
				formatDateIfValid(
					applicationStore.application.birthday,
					'YYYY-MM-DD',
					'MM/DD/YYYY',
					false
				) ?? '',
			ssn: applicationStore.application.ssn ?? '',
			gender: applicationStore.application.gender ?? null,
		};

		const {
			register,
			handleSubmit,
			control,
			getValues,
			setValue,
			formState: { errors },
		} = useForm<FormValues>({
			defaultValues,
			mode: 'onBlur',
		});

		const onSubmit = (data) => {
			updateApplication(data);
			analyticsManager.track(AnalyticsTrack.ApplicationInfoSubmitted());
			next();
		};

		const handleValueChange = (e: React.FocusEvent<HTMLInputElement, Element>) => {
			setValue(e.target.name as keyof FormValues, e.target.value);
		}

		return (
			<Page>
				<Content paddingX scrollY>
					<Form control={control} onBlur={() => updateApplication(getValues())}>
						<Stack spacing='6'>
							<CustomFormControl
								label={t('Date of birth *')}
								error={errors.birthday}>
								<CustomInput
									name="birthday"
									mask='99/99/9999'
									type='tel'
									placeholder='mm/dd/yyyy'
									defaultValue={defaultValues.birthday}
									{...register('birthday', {
										required: true,
										validate: { isValidDate, isValidAge },
									})}
									onBlur={handleValueChange}
								/>
							</CustomFormControl>
							<CustomFormControl
								label={t('What was your gender at birth? *')}
								error={errors.gender}>
								<Select
									size='lg'
									_light={{ bg: 'gray.100' }}
									{...register('gender', {
										required: true,
									})}>
									{!applicationStore.application.gender && (
										<option value={''}>Select</option>
									)}
									<option value={ApplicationGender.Male}>
										{getGenderText(ApplicationGender.Male)}
									</option>
									<option value={ApplicationGender.Female}>
										{getGenderText(ApplicationGender.Female)}
									</option>
								</Select>
							</CustomFormControl>
							<CustomFormControl
								label={t('Social security number *')}
								error={errors.ssn}>
								<CustomInput
									type={ssnType}
									mask='999-99-9999'
									defaultValue={defaultValues.ssn}
									onFocusCapture={() => setSsnType('tel')}
									onBlurCapture={() => setSsnType('password')}
									placeholder={t('Enter number')}
									{...register('ssn', {
										required: true,
									})}
									onBlur={handleValueChange}
								/>
							</CustomFormControl>
						</Stack>
					</Form>
					<SecurityNote paddingTop='16px'>
						<T>
							Our insurance carriers for Dental and Vision require this
							information.
						</T>
					</SecurityNote>
				</Content>

				<Footer>
					<CustomButton
						bg='blue.500'
						color='white'
						size='lg'
						fontSize='lg'
						variant='solid'
						width='100%'
						_hover={{
							bg: 'blue.300',
						}}
						onClick={handleSubmit(onSubmit)}>
						<T>Next</T>
					</CustomButton>
				</Footer>
			</Page>
		);
	}
);
