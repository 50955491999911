import { Heading, Text } from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { Content, CustomButton, Header, Page, TabsFooter } from 'components';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

import { ReactComponent as DoctorIcon } from 'assets/doctor.svg';
import { ReactComponent as GlassesIcon } from 'assets/glasses.svg';
import { ReactComponent as TootheIcon } from 'assets/toothe.svg';

export const BenefitsPage = observer(() => {
	const { applicationStore } = useStores();
	const navigate = useNavigate();

	useEffect(() => {
		if (applicationStore.isSetupFinished && !applicationStore.isDraft) {
			navigate(RoutePath.applicationResult);
		}
	}, [applicationStore.isSetupFinished, applicationStore.isDraft, navigate]);

	return (
		<Page>
			<Header canGoBack={false} />
			<Content paddingX className='flex flex-col gap-4'>
				<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold'>
					<T>Insurance Coverage</T>
				</Heading>

				<div className='flex flex-row justify-around pt-6'>
					<div className='flex flex-col'>
						<DoctorIcon width={80} />
						<Text
							fontSize='md'
							fontWeight='medium'
							align='center'
							_light={{ color: 'gray.900' }}
							className={`mt-4`}>
							<T>Health Care</T>
						</Text>
					</div>
					<div className='flex flex-col'>
						<TootheIcon width={80} />
						<Text
							fontSize='md'
							fontWeight='medium'
							align='center'
							_light={{ color: 'gray.900' }}
							className={`mt-4`}>
							<T>Dental Care</T>
						</Text>
					</div>
					<div className='flex flex-col'>
						<GlassesIcon width={80} />
						<Text
							fontSize='md'
							fontWeight='medium'
							align='center'
							_light={{ color: 'gray.900' }}
							className={`mt-4`}>
							<T>Vision Care</T>
						</Text>
					</div>
				</div>

				<Text
					fontSize='md'
					fontWeight='medium'
					align='center'
					_light={{ color: 'gray.900' }}
					className={`my-6`}>
					<T>
						We've got you covered with flexible coverage options starting at
						$50/mo.
					</T>
				</Text>

				<CustomButton
					bg='blue.500'
					color='white'
					size='lg'
					fontSize='lg'
					variant='solid'
					width='100%'
					_hover={{ bg: 'blue.300' }}
					onClick={() => navigate(RoutePath.application)}>
					<T>Go to Application</T>
				</CustomButton>
			</Content>
			<TabsFooter />
		</Page>
	);
});
