import { useQuery } from '@tanstack/react-query';
import { Endpoints, coreApi } from 'api';
import { QueryKey } from 'constant';
import { Profession } from 'models/profession';
import { millisecondsIn } from 'utils';

const staleTime = 5 * millisecondsIn.minute;

export function useProfession() {
	return useQuery<Profession[], any>({
		queryKey: [QueryKey.professions],
		queryFn: () =>
			coreApi
				.get<Profession[]>(Endpoints.professions)
				.then((response) => response.data),
		staleTime,
	});
}
