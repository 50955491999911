import { Heading } from "@chakra-ui/react";
import { Content } from "components";
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useStores } from "stores";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "routes";
import formatCurrency from "utils/currency/format-currency";
import { useEffect } from "react";

export const ContractsList = () => {
	const navigate = useNavigate();
	const { authenticationStore: { organizations } } = useStores();

	useEffect(() => {
		if (organizations.length === 1) {
			navigate(`${RoutePath.timesheet}/${organizations[0].uuid}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizations.length, navigate])

	return (
		<>
			<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold'>
				Contracts
			</Heading>
			<Content>
				{organizations.map(org => (
					<div
						className="border rounded-2xl border-gray-300 p-5 cursor-pointer hover:bg-gray-50 transition-all ease-in-out"
						key={org.uuid}
						onClick={() => navigate(`${RoutePath.timesheet}/${org.uuid}`)}
					>
						<div className="flex">
							<div className="basis-1/2 space-y-1">
								<div className="text-lg font-bold">{org.name}</div>
								<div className="text-sm">{org.employment.role}</div>
								<div className="text-sm text-gray-400 font-medium">Rate: {formatCurrency(String(org.employment.pay_rate))} p/h</div>
							</div>
							<div className="basis-1/2 flex justify-end items-center"><ChevronRightIcon color="blue.400" className="text-4xl" /></div>
						</div>
					</div>
				))}
			</Content>
		</>
	);
}