import { Address } from './address';
import { ApplicationResult } from './application-result';
import { ApplicationProductStatus, ApplicationStatus } from './enums';

export enum ApplicationGender {
	Male = 'Male',
	Female = 'Female',
}

export type ApplicationDependent = {
	uuid?: string;
	spouse: boolean;
	first_name?: string;
	last_name?: string;
	insurance_types?: string[];
	/**
	 * @format yyyy-mm-dd
	 */
	birthday?: string;
	gender?: ApplicationGender;
};

export type ApplicationPayment = {
	card_number: string;
};

export type ApplicationProduct = {
	product_id: string;
	status: ApplicationProductStatus;
};

type ApplicationExtras = {
	need_profession: boolean;
	need_NGA_associate: boolean;
};

export type Application = {
	uuid?: string;
	insurance_types?: string[];
	status?: ApplicationStatus;
	profession_id?: number;
	profession?: string;
	profession_custom?: string;
	/**
	 * @format yyyy-mm-dd
	 */
	birthday?: string;
	gender?: ApplicationGender;

	ssn?: string;
	phone?: string;
	payment?: ApplicationPayment;
	/**
	 * Only shows when not in Draft.
	 */
	results?: ApplicationResult;
	products?: ApplicationProduct[];
	extras?: ApplicationExtras;

	discount_code?: string;
} & Address;
