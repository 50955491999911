import { useQuery } from '@tanstack/react-query';
import { Endpoints, coreApi } from 'api';
import { QueryKey } from 'constant';
import { ProfessionType } from 'models/profession-type';
import { millisecondsIn } from 'utils';

const staleTime = 5 * millisecondsIn.minute;

export function useProfessionTypes() {
	return useQuery<ProfessionType[], any>({
		queryKey: [QueryKey.professionTypes],
		queryFn: () =>
			coreApi
				.get<ProfessionType[]>(Endpoints.professionTypes)
				.then((response) => response.data),
		staleTime,
	});
}
