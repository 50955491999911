import {
	SlideFade,
	Slider,
	SliderFilledTrack,
	SliderMark,
	SliderThumb,
	SliderTrack,
	Stack,
} from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { Content, ContentHeader, CustomButton, Footer, Page } from 'components';
import {
	CustomFormControl,
	CustomInput,
	LocationStateSelect,
} from 'components/form';
import { CustomRadio } from 'components/form/custom-radio';
import { ProfessionTypes } from 'components/profession-type';
import { useLocationStates } from 'hooks';
import { observer } from 'mobx-react';
import { LocationState } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { useStores } from 'stores';
import { getStateName } from 'utils/address';

export const ApplicationQuoteInfoTab = observer(({ setCurrentTab }) => {
	const { data: loadedStates = [], isLoading: isLoadingLocationStates } =
		useLocationStates();
	const { t } = useTranslate();
	const { quoteStore } = useStores();
	const [allowedToSubmit, setAllowedToSubmit] = useState(false);
	const spouse = quoteStore.quote.spouse ?? 0;
	const dependents = quoteStore.quote.dependents ?? 0;
	const locationStateId = quoteStore.quote.state_id ?? undefined;
	const [email, setEmail] = useState(quoteStore.quote.email || '');
	const [needProfession, setNeedProfession] = useState(false);
	const isLoading = isLoadingLocationStates || quoteStore.isLoading;

	const goToQuote = async () => {
		if (email) {
			analyticsManager.identify({ email });
		}

		await quoteStore.reload();
		setCurrentTab(1);
	};

	const formValidate = useCallback(() => {
		setAllowedToSubmit(
			!!locationStateId &&
				!!(
					(needProfession && quoteStore.quote?.profession_type_id) ||
					!needProfession
				)
		);
	}, [locationStateId, needProfession, quoteStore.quote?.profession_type_id]);

	const validateIfNeedProfession = useCallback(() => {
		const currentState: LocationState = loadedStates.find(
			(state) => state.id === locationStateId
		);

		setNeedProfession(currentState?.individual_flow_requires_profession);
	}, [locationStateId, loadedStates]);

	useEffect(() => {
		validateIfNeedProfession();
		formValidate();
	}, [locationStateId, loadedStates, formValidate, validateIfNeedProfession]);

	useEffect(() => {
		validateIfNeedProfession();
		formValidate();
		analyticsManager.page(AnalyticsPage.Quote.Info);
	});

	return (
		<Page>
			<Content scrollY paddingX isLoading={isLoading}>
				<Stack spacing='3'>
					<ContentHeader title='Health insurance options starting at $50 monthly.' />
					<CustomFormControl label={t('How many dependents?')}>
						<Slider
							min={0}
							max={8}
							defaultValue={dependents}
							onChangeEnd={(val: number) =>
								quoteStore.updateQuote({ dependents: val })
							}
							aria-label='slider-ex-6'>
							{[0, 1, 2, 3, 4, 5, 6, 7, 8].map((v) => (
								<SliderMark
									key={v}
									value={v}
									{...{
										mt: '3',
										ml: -1,
										fontSize: 'sm',
									}}>
									{v}
								</SliderMark>
							))}
							<SliderTrack height={15} borderRadius={3}>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb boxSize={6}>{dependents}</SliderThumb>
						</Slider>
					</CustomFormControl>

					{dependents > 0 && (
						<SlideFade in={true}>
							<CustomFormControl
								label={t('Does your family include a spouse or partner?')}
								marginTop={2}>
								<CustomRadio
									options={[
										{ value: '1', text: 'Yes' },
										{ value: '0', text: 'No' },
									]}
									value={String(spouse) || '0'}
									defaultValue='0'
									onChange={(val: '0' | '1') =>
										quoteStore.updateQuote({ spouse: Number(val) as 0 | 1 })
									}
								/>
							</CustomFormControl>
						</SlideFade>
					)}

					<CustomFormControl label={t('Where you currently live?')}>
						<LocationStateSelect
							value={locationStateId}
							onChange={(val) => {
								const value = val.target.value;

								quoteStore.updateQuote({
									state_id: value ? Number(value) : null,
								});
							}}
						/>
					</CustomFormControl>

					<CustomFormControl label={t('What’s your email address? (optional)')}>
						<CustomInput
							type='email'
							value={email}
							size={'lg'}
							placeholder={t('Enter email address')}
							onChange={(val) => {
								setEmail(val.target.value);
							}}
							onBlur={() => {
								quoteStore.updateQuote({
									email,
								});
							}}
						/>
					</CustomFormControl>

					{needProfession && (
						<>
							<SlideFade in={true}>
								<CustomFormControl label={t('What is your profession?')}>
									<ProfessionTypes
										typeSelected={quoteStore.quote?.profession_type_id}
										toggleProfessionType={(type) => {
											quoteStore.updateQuote({
												profession_type_id: type.id,
											});
										}}
									/>
								</CustomFormControl>
							</SlideFade>
						</>
					)}
				</Stack>
			</Content>
			<Footer>
				<CustomButton
					bg='blue.500'
					color='white'
					size='lg'
					fontSize='lg'
					variant='solid'
					width='100%'
					isDisabled={!allowedToSubmit || isLoading}
					onClick={async () => {
						analyticsManager.track(
							AnalyticsTrack.QuoteInfoChosen({
								total_dependents: dependents,
								has_spouse: spouse === 1,
								location: getStateName(loadedStates, locationStateId),
								has_children: dependents - spouse > 0,
								total_children: dependents - spouse,
							})
						);

						await goToQuote();
					}}>
					<T>Get quote</T>
				</CustomButton>
			</Footer>
		</Page>
	);
});
