import { ReactComponent as LogoImage } from 'assets/logos/gig-easy-icon-blue.svg';

export const LoadingPage = () => {
	return (
		<div className='flex h-screen w-full items-center justify-center'>
			<div className='relative flex w-full animate-pulse justify-center'>
				<LogoImage width={48} height={48} />
			</div>
		</div>
	);
};
