import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	CloseButton,
	Heading,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { ReactComponent as StripePaymentUndraw } from 'assets/undraw_stripe_payments.svg';
import { Content, CustomButton, Header, Page, TabsFooter } from 'components';
import { useTransaction } from 'hooks/transaction';
import { AccountType } from 'models/account';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import { LatestPayment } from './latest-payment';
import { PaymentList } from './payment-list';

export const PayPage = () => {
	const { data, isLoading } = useTransaction();
	const { authenticationStore } = useStores();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get('success') === 'true') {
			authenticationStore.setRedirectToOnboarding(false);
		}
	}, [searchParams, authenticationStore]);

	const redirectToOnboarding = (type: string) => {
		location.href = `${RoutePath.onboardingRedirect}?uuid=${authenticationStore.customer.uuid}&type=${type}`;
	};

	const renderEmpty = () => {
		return (
			<>
				<Text
					fontSize='md'
					fontWeight='medium'
					align='center'
					_light={{ color: 'gray.900' }}
					className={`my-10`}>
					<T>Receive money from your employer using GigEasy Payments.</T>
				</Text>
				<CustomButton
					bg='blue.500'
					color='white'
					size='lg'
					fontSize='lg'
					variant='solid'
					width='100%'
					_hover={{
						bg: 'blue.300',
					}}
					onClick={() =>
						window.open('https://gigeasyinc.com/1099payments/', '_blank')
					}>
					<T>Learn More</T>
				</CustomButton>
			</>
		);
	};

	const renderLoading = () => {
		return (
			<div className='flex w-full h-40 justify-center items-center'>
				<Spinner color='blue.500' size='lg' />
			</div>
		);
	};

	const renderPayments = () => {
		const hasSucess = searchParams.get('success');
		if (
			authenticationStore.customer?.extras?.has_onboarding_to_do ||
			hasSucess === 'false'
		) {
			return (
				<>
					<Alert status='warning'>
						<AlertIcon />
						<Box>
							<AlertTitle>Someone is trying to pay you!</AlertTitle>
							<AlertDescription>
								A company is trying to send you money but you haven’t set up
								your payout method yet.
							</AlertDescription>
						</Box>
					</Alert>
					<Text>
						You need to make an onboarding to receive payments through GigEasy
						Pay.
					</Text>
					<div className='flex flex-col justify-center items-center'>
						<StripePaymentUndraw width={150} />
						<Heading
							mt={5}
							fontSize='xl'
							_light={{ color: 'gray.900' }}
							fontWeight='bold'>
							<T>I want to receive my payments as:</T>
						</Heading>
						<div className='flex flex-row justify-center items-center'>
							<CustomButton
								marginTop={5}
								marginRight={5}
								bg='blue.500'
								color='white'
								size='lg'
								fontSize='lg'
								variant='solid'
								width='100%'
								_hover={{ bg: 'blue.300' }}
								onClick={() => redirectToOnboarding(AccountType.Individual)}>
								<T>Individual</T>
							</CustomButton>
							<CustomButton
								marginTop={5}
								bg='blue.500'
								color='white'
								size='lg'
								fontSize='lg'
								variant='solid'
								width='100%'
								_hover={{ bg: 'blue.300' }}
								onClick={() => redirectToOnboarding(AccountType.Company)}>
								<T>Business</T>
							</CustomButton>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				{hasSucess === 'true' && <AlertAllGood />}
				{!hasSucess && data.length === 0 && (
					<Alert status='warning'>
						<AlertIcon />
						<Box>
							<AlertTitle>Nice work!</AlertTitle>
							<AlertDescription>
								Now, when you have any payment to receive, you’ll see it here.
							</AlertDescription>
						</Box>
					</Alert>
				)}
				{data.length > 0 && (
					<>
						<LatestPayment data={data.shift()} />
						{data.length > 0 && <PaymentList data={data} />}
					</>
				)}
			</>
		);
	};

	const renderContent = () => {
		if (isLoading) {
			return renderLoading();
		}

		if (!authenticationStore?.customer?.features?.payments) {
			return renderEmpty();
		}

		return renderPayments();
	};

	return (
		<Page>
			<Header canGoBack={false} />
			<Content scrollY paddingX className='flex flex-col gap-4'>
				<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold'>
					<T>Payment Details</T>
				</Heading>
				{renderContent()}
			</Content>
			<TabsFooter />
		</Page>
	);
};

const AlertAllGood = () => {
	return (
		<Alert status='success' className='position-relative w-full'>
			<AlertIcon />
			<Box>
				<AlertTitle>Nice work!</AlertTitle>
				<AlertDescription>
					You’re all set up to receive payments.
				</AlertDescription>
			</Box>
			<CloseButton
				alignSelf='flex-start'
				position='absolute'
				right={0}
				top={0}
				onClick={() => (location.href = `${RoutePath.pay}`)}
			/>
		</Alert>
	);
};
