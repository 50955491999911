import {
	Link,
	ListItem,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	OrderedList,
	Text,
} from '@chakra-ui/react';
import planDeclinedText from 'constant/plan-declined-text.json';

export const ApplicationPlanDeclined = () => {
	return (
		<>
			<ModalOverlay background='white' />
			<ModalContent maxW='3xl' shadow='none'>
				<ModalHeader>{planDeclinedText.title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text paddingBottom='6' _light={{ color: 'gray.700' }}>
						{planDeclinedText.description}
					</Text>
					<OrderedList>
						{planDeclinedText.points.map((point) => (
							<ListItem _light={{ color: 'gray.700' }}>
								<Text fontWeight='500' as='span'>
									{point.title}
								</Text>
								<Text as='span'>{point.description}</Text>
							</ListItem>
						))}
					</OrderedList>
					<Text paddingY='6' _light={{ color: 'gray.700' }}>
						{planDeclinedText.contact.text}
						<Link
							href={`mailto:${planDeclinedText.contact.email}`}
							isExternal
							color='blue.500'>
							{planDeclinedText.contact.email}
						</Link>
					</Text>
				</ModalBody>
			</ModalContent>
		</>
	);
};
