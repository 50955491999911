import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Divider,
	Modal,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { AnalyticsPage, analyticsManager } from 'analytics';
import { googleTagManager } from 'analytics/ga';
import { ReactComponent as ApplicationApprovedIcon } from 'assets/approved.svg';
import { ReactComponent as ApplicationReceivedIcon } from 'assets/group-14.svg';
import { ReactComponent as ApplicationAnyRejectedIcon } from 'assets/group-15.svg';
import {
	ApplicationPlanSummary,
	Content,
	ContentHeader,
	Header,
	Page,
	TabsFooter,
} from 'components';
import { ApplicationPlanDeclined } from 'components/application';
import { useApplicationDependents } from 'hooks';
import { observer } from 'mobx-react';
import { ApplicationProductStatus, ApplicationStatus } from 'models';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import { Logger, currencyFormat, formatCardNumber } from 'utils';

export const ApplicationResultPage = observer(() => {
	const navigate = useNavigate();
	const { applicationStore } = useStores();
	const {
		isOpen: isOpenDeclineReasons,
		onOpen: onOpenDeclineReasons,
		onClose: onCloseDeclineReasons,
	} = useDisclosure();

	const applicationResult = applicationStore.application.results;
	const planIdToStatus: { [key: string]: ApplicationProductStatus } = useMemo(
		() =>
			(applicationStore.application.products ?? []).reduce(
				(acc, curr) => ({ ...acc, [curr.product_id]: curr.status }),
				{}
			),
		[applicationStore.application.products]
	);
	const eligibleValues = useMemo(
		() => Object.values(applicationResult.insurance_types.eligible),
		[applicationResult.insurance_types.eligible]
	);

	const { data: dependents = [], isLoading: isLoadingDependentsResult } =
		useApplicationDependents(applicationStore.application.uuid);

	useEffect(() => {
		if (applicationStore.isDraft) {
			navigate(RoutePath.root);
		}
		analyticsManager.page(AnalyticsPage.Application.Results);
		googleTagManager.sendApplicationResultsToGTMPurchase(
			applicationStore.application
		);
	}, [applicationStore.isDraft, applicationStore.application, navigate]);

	const isAnyPlanDeclined = useMemo(
		() =>
			eligibleValues.some(
				(plan) =>
					planIdToStatus[plan.product_id] === ApplicationProductStatus.Rejected
			),
		[planIdToStatus, eligibleValues]
	);
	const isAllPlansApproved = useMemo(
		() =>
			eligibleValues.every(
				(plan) =>
					planIdToStatus[plan.product_id] === ApplicationProductStatus.Approved
			),
		[planIdToStatus, eligibleValues]
	);

	const isLoading =
		!applicationStore.isSetupFinished ||
		applicationStore.isLoading ||
		isLoadingDependentsResult;

	const statusInformation = useMemo(() => {
		const receivedIcon = <ApplicationReceivedIcon width={138} height={138} />;
		if (isAnyPlanDeclined) {
			return {
				icon: <ApplicationAnyRejectedIcon width={138} height={138} />,
				title: 'One or more of your benefits have been declined!',
				subTitle:
					'Unfortunately we were unable to process any of your applications. Please click the links below for more information on why this may be the case.',
			};
		}
		switch (applicationStore.application.status) {
			case ApplicationStatus.Submitted:
				return {
					icon: receivedIcon,
					title: 'Application received!',
					subTitle:
						'We are processing your application and will send you an email when you can start to use your benefits.',
				};
			case ApplicationStatus.Approved:
				if (isAllPlansApproved) {
					return {
						icon: <ApplicationApprovedIcon width={138} height={138} />,
						title: 'Application approved!',
						subTitle: 'You can start using your benefits',
					};
				} else {
					return {
						icon: receivedIcon,
						title: 'One or more of your benefits have been approved!',
						subTitle:
							'You can now start using the benefits for the policies that were approved. Please check to see which ones were approved before you start using them.',
					};
				}
			default:
				Logger.error({
					message: 'Application result with invalid status',
					extraData: { status: applicationStore.application.status },
				});
				return {};
		}
	}, [
		applicationStore.application.status,
		isAnyPlanDeclined,
		isAllPlansApproved,
	]);

	const renderPaymentDetails = () => {
		if (!applicationStore.application?.payment?.card_number) {
			return null;
		}
		return (
			<>
				<Divider className='my-4' borderStyle='dashed' />
				<Text
					className='text-base font-semibold'
					_light={{ color: 'gray.900' }}>
					<T>Payment details</T>
				</Text>
				<div className='flex flex-row justify-between mt-2'>
					<Text
						className='select-none'
						_light={{ color: 'gray.500' }}
						fontSize='sm'
						fontWeight='medium'>
						{formatCardNumber(
							applicationStore.application?.payment?.card_number
						)}
					</Text>
					<Text
						className='select-none'
						_light={{ color: 'gray.500' }}
						fontSize='sm'
						fontWeight='medium'>
						{currencyFormat(applicationResult?.total)}
					</Text>
				</div>
			</>
		);
	};

	const renderDeclinedAlert = () => {
		if (!isAnyPlanDeclined) {
			return <></>;
		}
		return (
			<div>
				<Alert status='error'>
					<AlertIcon />
					<Box>
						<AlertTitle>Attention</AlertTitle>
						<AlertDescription>
							One or more applications were declined.
						</AlertDescription>
					</Box>
				</Alert>
			</div>
		);
	};

	return (
		<Page>
			<Header />
			<Content paddingX scrollY isLoading={isLoading} className='pb-6'>
				<ContentHeader
					icon={statusInformation.icon}
					title={statusInformation.title}
					subTitle={statusInformation.subTitle}
				/>
				{renderDeclinedAlert()}
				<ApplicationPlanSummary
					openDeclineReasons={onOpenDeclineReasons}
					isLoading={isLoading}
					isRefetching={false}
					result={applicationResult}
					dependents={dependents}
				/>
				{renderPaymentDetails()}
			</Content>
			<Modal
				isOpen={isOpenDeclineReasons}
				onClose={onCloseDeclineReasons}
				size='full'>
				<ApplicationPlanDeclined />
			</Modal>
			<TabsFooter />
		</Page>
	);
});
