import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export type UTM = {
	utm_source?: string;
	utm_medium?: string;
	utm_campaign?: string;
	utm_term?: string;
	utm_content?: string;
};

export class UTMStore {
	utm: UTM = {};

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'UTMStore',
			properties: ['utm'],
			storage: window.localStorage,
		});
	}

	async load({
		utm_source,
		utm_medium,
		utm_campaign,
		utm_term,
		utm_content,
	}: UTM = {}) {
		this.utm = {
			utm_source,
			utm_medium,
			utm_campaign,
			utm_term,
			utm_content,
		};
	}

	clean() {
		this.utm = {};
	}
}
