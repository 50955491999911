import { Button, Link, Stack } from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { AnalyticsPage, AnalyticsTrack, analyticsManager } from 'analytics';
import { ErrorResponse } from 'api';
import {
	AlertError,
	Content,
	ContentHeader,
	CustomButton,
	Header,
	Page,
} from 'components';
import { CustomFormControl, CustomInput } from 'components/form';
import { CustomInputPassword } from 'components/form/custom-input-password';
import { observer } from 'mobx-react';
import { CustomerLogin } from 'models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

export const LoginPage = observer(() => {
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { authenticationStore } = useStores();
	const [errorResponse, setErrorResponse] = useState<ErrorResponse>(null);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		authenticationStore.logout();
		analyticsManager.page(AnalyticsPage.General.Login);
	}, [authenticationStore]);

	const defaultValues = {
		email: searchParams.get('email') ?? '',
		password: '',
		individual_uuid: searchParams.get('individualUuid'),
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CustomerLogin>({
		defaultValues,
		mode: 'onSubmit',
	});

	const onSubmit = async (data: CustomerLogin) => {
		try {
			setErrorResponse(null);
			await authenticationStore.login(data);
			analyticsManager.track(AnalyticsTrack.UserLoggedIn());
			navigate(RoutePath.root);
		} catch (error: any) {
			setErrorResponse(error);
		}
	};

	return (
		<Page>
			<Header />
			<Content scrollY paddingX>
				<ContentHeader
					title='Log in to your account'
					subTitle={
						<>
							<T>Don’t have an Account? </T>
							<Link
								color='blue.500'
								onClick={() => navigate(RoutePath.register)}>
								Sign up
							</Link>
						</>
					}
				/>
				<Stack gap='4'>
					<CustomFormControl label={t('Email *')} error={errors.email}>
						<CustomInput
							type='email'
							placeholder={t('Enter email')}
							{...register('email', {
								required: true,
							})}
						/>
					</CustomFormControl>
					<CustomFormControl label={t('Password *')} error={errors.password}>
						<CustomInputPassword
							placeholder={t('Enter password')}
							{...register('password', {
								required: true,
							})}
						/>
					</CustomFormControl>

					<AlertError error={errorResponse} isResponse />

					<div className='flex flex-col py-4 pb-8'>
						<CustomButton
							bg='blue.500'
							color='white'
							className='flex grow'
							size='lg'
							variant='solid'
							_hover={{
								bg: 'blue.300',
							}}
							onClick={handleSubmit(onSubmit)}>
							Log in
						</CustomButton>
						<Button
							className='flex grow mt-6'
							variant='link'
							color='blue.500'
							onClick={() => navigate(RoutePath.forgotPassword)}>
							Forgot password?
						</Button>
					</div>
				</Stack>
			</Content>
		</Page>
	);
});
