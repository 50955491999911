import { useNavigate, useParams } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import classNames from "classnames";
import { isWeekend, format } from 'date-fns';
import { getDateRange } from "utils";
import { TimesheetHourStatus } from "./components/shared/timesheet-hour-status";
import { formatTimesheetEntries } from "./utils/format-timesheet-entries";
import { useTimesheetEntries } from "hooks/timesheet/queries";
import { dateToString } from "utils/date/date-to-string";
import { RoutePath } from "routes";
import { TimesheetEntry, TimesheetEntryAPIDTO } from "models/timesheet";
import { formatFloatToHour } from "./utils/format-float-to-hour";

export const TimesheetEntries = ({
	dateRef,
	entries = [],
}: {
	dateRef: { start: Date, end: Date };
	entries: TimesheetEntryAPIDTO[];
}) => {
	const navigate = useNavigate();
	const { uuid } = useParams();
	
	const dateRange = getDateRange([dateRef.start, dateRef.end]);

	let currentTimesheetEntries = formatTimesheetEntries(uuid, entries, dateRange);

	function handleEntryClick(entry: TimesheetEntry): void {
		if (entry.worked_hours) {
			return navigate(`${RoutePath.timesheetHoursResume(uuid, entry.uuid)}`)
		}

		navigate(`${RoutePath.addTimesheetEntry(uuid, dateToString(entry.date))}`)
	}

	return (
		<div>
			<div className="flex flex-row px-3 py-4 bg-amber-50">
				<div className="basis-1/5 flex justify-center font-semibold">Day</div>
				<div className="basis-1/5 flex justify-center font-semibold">Hours</div>
				<div className="basis-1/5 flex justify-center font-semibold">Pay</div>
				<div className="basis-1/5 flex justify-center font-semibold">Status</div>
				<div className="basis-1/5 flex justify-center font-semibold"></div>
			</div>
			{currentTimesheetEntries.map(entry => (
				<div key={entry.date.getUTCDate()} className={classNames("flex flex-row px-3 py-3 border-y border-slate-200", {
					"bg-slate-100": isWeekend(entry.date),
					"striped": !isWeekend(entry.date),
				})}>
					<div className="basis-1/5 flex flex-col">
						<div className="flex justify-center font-semibold">{format(entry.date, "E")}</div>
						<div className="flex justify-center">{dateToString(entry.date, "MM.dd")}</div>
					</div>
					<div className="basis-1/5 flex flex-col">
						<div className="h-full flex justify-center font-semibold items-center">{entry.worked_hours ? formatFloatToHour(entry.worked_hours) : "--"}</div>
					</div>
					<div className="basis-1/5 flex flex-col">
						<div className="h-full flex justify-center font-semibold items-center">--</div>
					</div>
					<div className="basis-1/5 flex flex-col items-center justify-center">
						<TimesheetHourStatus status={entry.status} />
					</div>
					<div className="basis-1/5 flex flex-col cursor-pointer">
						<div
							className={classNames(
								"h-full flex items-center font-semibold text-blue-500",
								{
									"justify-center": !entry.worked_hours,
									"justify-end text-2xl pr-4": entry.worked_hours
								}
							)}
							onClick={() => handleEntryClick(entry)}
						>
							{entry.worked_hours ? <ChevronRightIcon /> : "Add +"}
						</div>
					</div>
				</div>
			))}
		</div>
	);
}