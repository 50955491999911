import { useNavigate, useParams } from "react-router-dom";
import { Heading } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Content, Header, Page } from 'components';
import { useStores } from "stores";
import { RoutePath } from "routes";
import { useCreateMutationTimesheetEntry } from "hooks/timesheet/mutations";
import { TimesheetEntryAPIDTO } from "models/timesheet";
import { TimesheetEntryForm } from "./timesheet-entry-form";
import { EntryState } from "../constants/entry-state";

export const AddTimesheetEntry = () => {
	const navigate = useNavigate();
	const params = useParams<{ orgUuid: string, date: string }>();
	const store = useStores();
	const currentOrg = store.authenticationStore.organizations.find(org => org.uuid === params.orgUuid);

	const mutationTimesheetEntry = useCreateMutationTimesheetEntry(
		params.orgUuid, 
		params.date
	);

	const handleSubmit = async (formValues: TimesheetEntryAPIDTO) => {
		navigate(`${RoutePath.timesheet}/${params.orgUuid}?date=${params.date}&entryState=${EntryState.ADDED}`)
		await mutationTimesheetEntry.mutateAsync(formValues);
	};

	return (
		<Page>
			<Header canGoBack={false} />
			<Content scrollY paddingX className='flex flex-col'>
				<Heading fontSize='xl' _light={{ color: 'gray.900' }} fontWeight='bold' marginBottom={7}>
					<div className="flex justify-between pr-1">
						<div>Enter Time</div>
						<div className="cursor-pointer" onClick={() => navigate(`${RoutePath.timesheet}/${currentOrg.uuid}`)}><CloseIcon /></div>
					</div>
				</Heading>
				<Content scrollY>
					<TimesheetEntryForm onSubmit={handleSubmit} />
				</Content>
			</Content>
		</Page>
	);
}