import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider } from "@chakra-ui/react";
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PlusIcon} from 'assets/icons/plus.svg';
import { CustomInput, CustomButton } from "components";
import { TimesheetEntryAPIDTO } from "models/timesheet";
import { useStores } from "stores";
import { formatFloatToHour } from "../utils/format-float-to-hour";

const defaultDate = {
	worked_hours: null,
	description: "",
} as TimesheetEntryAPIDTO;

export const TimesheetEntryForm = ({
	data = defaultDate,
	onSubmit,
}: {
	data?: TimesheetEntryAPIDTO;
	onSubmit: (data: TimesheetEntryAPIDTO) => void;
}) => {
	const params = useParams<{ orgUuid: string, date: string }>();
	const store = useStores();
	const currentOrg = store.authenticationStore.organizations.find(org => org.uuid === (data.organization_uuid ? data.organization_uuid : params.orgUuid));

	const [ formValues, setFormValues ] = useState<TimesheetEntryAPIDTO>({
		organization_uuid: params.orgUuid,
		date: params.date,
		...data
	});

	const [ time, setTime ] = useState(data.worked_hours ? formatFloatToHour(data.worked_hours) : '');
  
	const startsWithTwo = time[0] === '2';
  const mask = [
    /[0-2]/,
    startsWithTwo ? /[0-3]/ : /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/
  ];

	const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setTime(e.target.value);

		const [ hours, minutes ] = e.target.value.split(':');
		handleFormChange(
			{
				target: {
					name: 'worked_hours',
					value: String(parseInt(hours) + parseInt(minutes) / 60)
				}
			} as ChangeEvent<HTMLInputElement>
		);
	}

	const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
		const element = e.target as HTMLInputElement;
		setFormValues({
			...formValues,
			[element.name]: element.value
		});
	}

	return (
		<>
			<div className="flex flex-row justify-between">
				<div className="flex flex-col space-y-2">
					<div className="font-semibold">Company</div>
					<div>{currentOrg.name}</div>
				</div>
				{/* <div className="flex justify-end items-center cursor-pointer text-blue-500 font-semibold">
					Change <EditIcon className="ml-1" />
				</div> */}
			</div>
			<Divider marginTop={6} marginBottom={6} />
			<div className="flex flex-row justify-between">
				<div className="flex flex-col space-y-2">
					<div className="font-semibold">Date</div>
					<div>{formValues.date}</div>
				</div>
				{/* <div className="flex justify-end items-center cursor-pointer text-blue-500 font-semibold">
					Change <EditIcon className="ml-1" />
				</div> */}
			</div>
			<Divider marginTop={6} marginBottom={6} />
			<div className="flex flex-col">
				<div className="flex flex-col">
					<div className="flex justify-center font-semibold">Duration</div>
					<div className="w-1/2 h-20 m-auto pt-3">
						<CustomInput
							name="worked_hours"
							className="h-full text-center"
							mask={mask}
							onChange={(e) => handleTimeChange(e)}
							value={time}
							placeholder="08:00"
						/>
					</div>
				</div>
			</div>
			<Divider marginTop={6} marginBottom={6} />
			<div className="flex flex-col">
				<div className="flex flex-col">
					<div className="font-semibold">Write a note</div>
					<div className="w-full h-20 m-auto pt-3">
						<CustomInput name="description" placeholder="Optional" onChange={(e) => handleFormChange(e)} value={formValues.description} />
					</div>
				</div>
			</div>
			<div className="w-full">
				<CustomButton
					className="w-full"
					colorScheme="primary"
					onClick={() => onSubmit(formValues)}
				>
					{
						data.worked_hours
							? <><EditIcon className="mr-1" /> Edit entry</>
							: <><PlusIcon className="mr-1" /> Add entry</>
					}
				</CustomButton>
			</div>
		</>
	);
}