import { Stack, Text } from '@chakra-ui/react';
import { T, useTranslate } from '@tolgee/react';
import { ErrorResponse } from 'api';
import {
	AlertError,
	Content,
	ContentHeader,
	CustomButton,
	Header,
	Page,
} from 'components';
import { PasswordLevel } from 'components/authentication';
import { CustomFormControl } from 'components/form';
import { CustomInputPassword } from 'components/form/custom-input-password';
import { observer } from 'mobx-react';
import { CustomerResetPassword } from 'models';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';
import {
	containsAtLeastOneLetter,
	containsAtLeastOneNumber,
	containsAtLeastOneSymbol,
} from 'utils/form';

export const ResetPasswordPage = observer(() => {
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { authenticationStore } = useStores();
	const [errorResponse, setErrorResponse] = useState<ErrorResponse>(null);
	const [searchParams, _] = useSearchParams();
	const token = searchParams.get('token');

	const defaultValues = {
		password: '',
	};

	const {
		watch,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CustomerResetPassword>({
		defaultValues,
		mode: 'onSubmit',
	});

	const onSubmit = async (data: CustomerResetPassword) => {
		try {
			setErrorResponse(null);
			await authenticationStore.resetPassword({
				password: data.password,
				token,
			});
			navigate(RoutePath.login);
		} catch (error: any) {
			setErrorResponse(error);
		}
	};

	const password = watch('password');

	return (
		<Page>
			<Header />
			<Content scrollY paddingX>
				<ContentHeader
					title='Reset your password'
					subTitle='Enter your new password'
				/>
				<Stack gap='4'>
					<CustomFormControl
						label={t('Password *')}
						error={errors.password}
						renderAfterError={
							<>
								<PasswordLevel password={password} />
								<Text color='gray.500' fontSize='xs'>
									<T>
										Use 8 or more characters with a mix of letters, numbers &
										symbols.
									</T>
								</Text>
							</>
						}>
						<CustomInputPassword
							placeholder={t('Enter password')}
							{...register('password', {
								required: true,
								minLength: 8,
								validate: {
									containsAtLeastOneLetter,
									containsAtLeastOneNumber,
									containsAtLeastOneSymbol,
								},
							})}
						/>
					</CustomFormControl>

					<AlertError error={errorResponse} isResponse />

					<Stack direction='row' paddingY='4' paddingBottom='8'>
						<CustomButton
							bg='blue.500'
							color='white'
							className='flex grow'
							size='lg'
							variant='solid'
							_hover={{
								bg: 'blue.300',
							}}
							onClick={handleSubmit(onSubmit)}>
							Register
						</CustomButton>
					</Stack>
				</Stack>
			</Content>
		</Page>
	);
});
