export function currencyFormat(
	num: number | string,
	fixed: number = 2,
	prefix: string = '$'
) {
	const number = Number(num);
	const isNegative = number < 0;
	return (
		(isNegative ? '-' : '') +
		prefix +
		Math.abs(number)
			.toFixed(fixed)
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
}
