import { Endpoints, api } from 'api';
import { QueryKey } from 'constant';
import { ApplicationDependent, ApplicationResult } from 'models';
import { useQuery } from '@tanstack/react-query';

export function useApplicationResults(uuid: string) {
	return useQuery<ApplicationResult, any>({
		queryKey: [QueryKey.applicationResults, uuid],
		queryFn: () => {
			return uuid
				? api
						.get<ApplicationResult>(Endpoints.applicationResults(uuid))
						.then((response) => response.data)
				: null;
		},
		refetchOnWindowFocus: false,
	});
}

export function useApplicationDependents(uuid: string) {
	return useQuery<ApplicationDependent[], any>({
		queryKey: [QueryKey.applicationDependents, uuid],
		queryFn: async () => {
			if (!uuid) {
				return [];
			}
			const dependents = await api
				.get<ApplicationDependent[]>(Endpoints.applicationDependents(uuid))
				.then((response) => response.data);
			return dependents.sort((a, b) => Number(b.spouse) - Number(a.spouse));
		},
	});
}
