import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, coreApi } from "api";
import { MutationKey, QueryKey } from "constant";
import { TimesheetEntryAPIDTO } from "models/timesheet";
import { dateStringToDate } from "utils/date/date-string-to-date";
import { dateToString } from "utils/date/date-to-string";
import { getStartAndEndOfWeek } from "utils/date/get-start-and-end-of-week";

export function useCreateMutationTimesheetEntry(orgUuid: string, date: string) {
	const queryClient = useQueryClient();
	const dates = getStartAndEndOfWeek(dateStringToDate(date));

	const start = !date ? "" : dateToString(dates.start);
	const end = !date ? "" : dateToString(dates.end);

	return useMutation({
		mutationKey: [MutationKey.timesheetEntries, orgUuid, date],
		mutationFn: (timesheetEntry: TimesheetEntryAPIDTO) =>
			coreApi
				.post(Endpoints.timesheetEntries, timesheetEntry)
				.then((response) => response.data),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.timesheetEntries, orgUuid, start, end],
			});
		},
	});
}

export function useUpdateMutationTimesheetEntry(orgUuid: string, entryUuid: string, date: string) {
	const queryClient = useQueryClient();
	const dates = getStartAndEndOfWeek(dateStringToDate(date));

	const start = !date ? "" : dateToString(dates.start);
	const end = !date ? "" : dateToString(dates.end);

	return useMutation({
		mutationKey: [MutationKey.timesheetEntry, orgUuid, entryUuid],
		mutationFn: (timesheetEntry: TimesheetEntryAPIDTO) =>
			coreApi
				.patch(Endpoints.timesheetEntry(orgUuid, entryUuid), timesheetEntry)
				.then(() => true),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.timesheetEntries, orgUuid, start, end],
			});
		},
	});
}

export function useDeleteMutationTimesheetEntry(orgUuid: string, entryUuid: string, date: string) {
	const queryClient = useQueryClient();
	const dates = getStartAndEndOfWeek(dateStringToDate(date));

	const start = !date ? "" : dateToString(dates.start);
	const end = !date ? "" : dateToString(dates.end);

	return useMutation({
		mutationKey: [MutationKey.timesheetEntry, orgUuid, entryUuid],
		mutationFn: () =>
			coreApi
				.delete(Endpoints.timesheetEntry(orgUuid, entryUuid))
				.then(() => true),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.timesheetEntries, orgUuid, start, end],
			});
		},
	});
}

export function useMutationTimesheetEntrySubmit(orgUuid: string, date: string) {
	const queryClient = useQueryClient();
	const dates = getStartAndEndOfWeek(dateStringToDate(date));

	const start = !date ? "" : dateToString(dates.start);
	const end = !date ? "" : dateToString(dates.end);

	return useMutation({
		mutationKey: [MutationKey.timesheetEntrySubmit, orgUuid],
		mutationFn: () =>
			coreApi
				.post(Endpoints.timesheetEntrySubmit(orgUuid), {})
				.then(() => true),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.timesheetEntries, orgUuid, start, end],
			});
		},
	});
}