import { Alert, AlertIcon } from '@chakra-ui/react';
import { ErrorResponse } from 'api';

type AlertErrorProps = {
	isResponse?: boolean;
	title?: string;
	error?: ErrorResponse | Error;
};

export const AlertError = ({ isResponse, title, error }: AlertErrorProps) => {
	if (!error?.message) {
		return null;
	}

	const formattedTitle =
		title ?? (isResponse ? 'There was an error processing your request: ' : '');

	return (
		<Alert status='error'>
			<AlertIcon />
			{formattedTitle}
			{error.message}
		</Alert>
	);
};
