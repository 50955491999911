import { config } from 'config';
import { ReplaySubject, firstValueFrom } from 'rxjs';

const formatUrl = (params: string[]) => {
	return params.filter(Boolean).join('/');
};

export const Endpoints = {
	/* INDIVIDUAL API ENDPOINTS */

	throwError: 'throw-error',

	login: 'customers/login',
	register: 'customers/register',
	resetPassword: 'customers/reset-password',
	updatePassword: 'customers/update-password',

	payments: 'payments',

	quotes: (uuid?: string) => formatUrl(['quotes', uuid]),
	quotesEmail: (uuid: string) => `quotes/${uuid}/email`,
	quotesResults: (uuid: string) => `quotes/${uuid}/results`,
	quotesPlanUpdate: (uuidQuote: string, uuidPlan: string) =>
		`quotes/${uuidQuote}/plans/${uuidPlan}`,
	quotesAttachCustomer: (uuidQuote: string) =>
		formatUrl(['quotes', uuidQuote, 'customer']),

	customerQuotes: (uuidCustomer: string) =>
		formatUrl(['customers', uuidCustomer, 'quotes']),
	customerApplications: (uuidCustomer?: string) =>
		formatUrl(['customers', uuidCustomer, 'applications']),
	customerEmail: (uuid: string) => `customers/${uuid}/email`,

	application: (uuid?: string) => formatUrl(['applications', uuid]),
	applicationDependents: (uuidApplication: string, uuidDependent?: string) =>
		formatUrl([`applications/${uuidApplication}/dependents`, uuidDependent]),
	applicationResults: (uuid: string) => `applications/${uuid}/results`,
	applicationPlanUpdate: (uuidApplication: string, uuidPlan: string) =>
		`applications/${uuidApplication}/plans/${uuidPlan}`,
	applicationPayment: (uuidApplication: string) =>
		`applications/${uuidApplication}/payments`,
	applicationAttachQuote: (uuidApplication: string) =>
		`applications/${uuidApplication}/quotes`,

	/* CORE API ENDPOINTS */
	insuranceTypes: 'individual/insurance-types',
	states: 'individual/states',
	professions: 'individual/professions',
	professionTypes: 'individual/profession-types',
	timesheetEntries: `individual/timesheet`,
	timesheetEntry: (orgUuid: string, entryUuid: string) =>
		`individual/timesheet/${orgUuid}/${entryUuid}`,
	timesheetEntrySubmit: (orgUuid: string) =>
		`individual/timesheet/${orgUuid}/submit`,
};

interface SuccessResponse<T> {
	data: T;
}

export interface ErrorResponse {
	status: number;
	statusText: string;
	data: {
		message: string;
		errors: { [key: string]: string[] };
	};
	message: string;
}

class Api {
	base_url = config.api;

	uuid$: ReplaySubject<string> = new ReplaySubject<string>(1);
	token$: ReplaySubject<string> = new ReplaySubject<string>(1);

	constructor(base_url: string) {
		this.base_url = base_url;
	}

	setToken(customerUuid?: string, token?: string) {
		this.token$.next(token);
		this.uuid$.next(customerUuid);
	}

	async getUuid(): Promise<string> {
		return firstValueFrom(this.uuid$);
	}

	async getToken(): Promise<string> {
		return firstValueFrom(this.token$);
	}

	async getHeaders(): Promise<HeadersInit> {
		const token = await this.getToken();
		return {
			'Content-Type': 'application/json',
			Authorization: token ? `Bearer ${token}` : undefined,
		};
	}

	async get<T>(path: string): Promise<SuccessResponse<T>> {
		return fetch(`${this.base_url}/${path}`, {
			headers: await this.getHeaders(),
		}).then(async (response) => {
			if (response.ok) {
				return response.json();
			}
			return this.handlerResponseError(response);
		});
	}

	async post<T, B>(path: string, body?: B): Promise<SuccessResponse<T>> {
		const method = 'POST';
		return fetch(`${this.base_url}/${path}`, {
			method,
			headers: await this.getHeaders(),
			body: JSON.stringify(body ?? {}),
		}).then((response) => {
			if (response.ok) {
				return response.json();
			}

			return this.handlerResponseError(response);
		});
	}

	async patch<T, B>(path: string, body: B): Promise<SuccessResponse<T>> {
		const method = 'PATCH';

		return fetch(`${this.base_url}/${path}`, {
			method,
			headers: await this.getHeaders(),
			body: JSON.stringify(body),
		}).then((response) => {
			if (response.ok) {
				return response.json();
			}

			return this.handlerResponseError(response);
		});
	}

	async delete<T>(path: string): Promise<SuccessResponse<T>> {
		const method = 'DELETE';

		return fetch(`${this.base_url}/${path}`, {
			method,
			headers: await this.getHeaders(),
		}).then((response) => {
			if (response.ok) {
				return response.json();
			}

			return this.handlerResponseError(response);
		});
	}

	async handlerResponseError(response: Response) {
		if ([401, 403].includes(response.status as number)) {
			window.location.href = '/login';
			return;
		}

		const data = await response.json();

		return Promise.reject<ErrorResponse>({
			message: data.message,
			data: data.data,
			status: response.status,
			statusText: response.statusText,
		});
	}
}

export const api = new Api(config.api);
export const coreApi = new Api(config.core_api);
