import * as Sentry from '@sentry/react';

type LogOptions = {
	message: string;
	extraData?: Error | unknown;
	exception?: Error;
};

export class Logger {
	private static logToSentry(
		options: LogOptions,
		severity: Sentry.SeverityLevel
	) {
		Sentry.withScope((scope) => {
			if (options.extraData)
				scope.setExtra(
					'extra-data',
					JSON.stringify(options.extraData, null, 4)
				);

			if (options.exception) {
				Sentry.captureException(options.exception);
			} else {
				Sentry.captureMessage(options.message, severity);
			}
		});
	}

	static log(options: LogOptions) {
		console.log(options);
	}

	static info(options: LogOptions) {
		console.info(options);
	}

	static debug(options: LogOptions) {
		console.debug(options);
	}

	static error(options: LogOptions) {
		this.logToSentry(options, 'error');
		console.error(options);
	}
}
