import { observer } from 'mobx-react';
import { LoadingPage } from 'pages/loading';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

export const HomePage = observer((): JSX.Element => {
	const navigate = useNavigate();
	const { authenticationStore, applicationStore } = useStores();
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		if (authenticationStore.isSetupFinished) {
			if (authenticationStore.isLoggedIn) {
				if (authenticationStore.isEmailAuthenticated) {
					setIsLoggedIn(true);

					if (authenticationStore.customer.extras?.has_onboarding_to_do) {
						navigate(RoutePath.pay);
					}
				} else {
					navigate(RoutePath.validateEmail);
				}
			} else {
				navigate(RoutePath.quote);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authenticationStore.isSetupFinished]);

	useEffect(() => {
		if (isLoggedIn && applicationStore.isSetupFinished) {
			if (authenticationStore.customer?.features.payments) {
				navigate(RoutePath.benefits);
			} else if (applicationStore.isDraft) {
				navigate(RoutePath.application);
			} else {
				navigate(RoutePath.applicationResult);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, applicationStore.isSetupFinished]);

	return <LoadingPage />;
});
