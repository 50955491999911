import { Badge } from "@chakra-ui/react";

export function TimesheetHourStatus({
	status,
}: {
	status: "Draft" | "Pending" | "Approved" | "Rejected";
}) {
	const statusBadgeAttributes = {
		Draft: { label: "Not Sent", color: "gray" },
		Submitted: { label: "Pending", color: "orange" },
		Approved: { label: "Approved", color: "green" },
		Rejected: { label: "Rejected", color: "red" },
	}

	return (
		statusBadgeAttributes[status] && <Badge
			variant="solid"
			colorScheme={statusBadgeAttributes[status].color}
		>
			{statusBadgeAttributes[status].label}
		</Badge>
	)
}