import { useMemo } from 'react';
import {
	containsAtLeastOneLetter,
	containsAtLeastOneNumber,
	containsAtLeastOneSymbol,
} from 'utils/form';

type PasswordLevelProps = { password: string };
export const PasswordLevel = ({ password }: PasswordLevelProps) => {
	const passwordLevel = useMemo(() => {
		if (password.length === 0) {
			return 0;
		}
		const diffCharTypes =
			(containsAtLeastOneLetter(password) === true ? 1 : 0) +
			(containsAtLeastOneNumber(password) === true ? 1 : 0) +
			(containsAtLeastOneSymbol(password) === true ? 1 : 0) +
			(password.length >= 8 ? 1 : 0);
		return diffCharTypes;
	}, [password]);

	const getBackgroundColor = () => {
		switch (passwordLevel) {
			case 0:
				return 'bg-gray-400';
			case 1:
				return 'bg-red-400';
			case 2:
				return 'bg-orange-400';
			case 3:
				return 'bg-green-500';
			case 4:
				return 'bg-green-600';
			default:
				return 'bg-gray-200';
		}
	};

	const totalBars = [1, 2, 3, 4];

	const renderBar = (bar) => (
		<div
			key={bar}
			className={`flex flex-grow h-1 rounded ${
				passwordLevel >= bar ? getBackgroundColor() : 'bg-gray-300'
			}`}
		/>
	);
	return (
		<div className='flex flex-row gap-0.5 py-3'>{totalBars.map(renderBar)}</div>
	);
};
