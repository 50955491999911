import { Spinner } from '@chakra-ui/react';

type ContentProps = {
	isLoading?: boolean;
	scrollY?: boolean;
	paddingX?: boolean;
	children?: React.ReactNode;
	className?: string;
};

export const Content = ({
	isLoading,
	scrollY,
	paddingX,
	children,
	className,
}: ContentProps) => {
	const renderContent = () => {
		if (isLoading) {
			return (
				<div className='flex w-full h-full items-center justify-center'>
					<Spinner color='blue.500' size='xl' />
				</div>
			);
		}
		return children;
	};
	return (
		<div
			className={`relative flex flex-col grow w-full max-w-3xl m-auto overflow-x-hidden scroll-smooth ${
				paddingX ? 'px-4' : ''
			} ${scrollY ? 'overflow-y-auto pb-6' : 'overflow-y-hidden'} ${
				className ?? ''
			}`}>
			{renderContent()}
		</div>
	);
};
