import { CloseIcon } from '@chakra-ui/icons';
import { Select, Stack } from '@chakra-ui/react';
import { useTranslate } from '@tolgee/react';
import { ReactComponent as SaveIcon } from 'assets/save-icon.svg';
import { CustomButton } from 'components';
import { CustomFormControl, CustomInput, CustomRadio } from 'components/form';
import { ApplicationDependent, ApplicationGender } from 'models';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatDateIfValid, getGenderText } from 'utils';
import { isValidAge, isValidDate } from 'utils/form';

type ApplicationDependentFormProps = {
	dependent: ApplicationDependent;
	haveSpouse: boolean;
	disableCancel?: boolean;
	onCancel(): void;
	addDependent(dependent: ApplicationDependent): Promise<void>;
};

export const ApplicationDependentForm = ({
	disableCancel = false,
	dependent,
	haveSpouse,
	onCancel,
	addDependent,
}: ApplicationDependentFormProps) => {
	const { t } = useTranslate();
	const [spouse, setSpouse] = useState<'1' | '0'>(dependent.spouse ? '1' : '0');

	const defaultValues = {
		first_name: dependent.first_name ?? '',
		last_name: dependent.last_name ?? '',
		birthday:
			formatDateIfValid(
				dependent.birthday,
				'YYYY-MM-DD',
				'MM/DD/YYYY',
				false
			) ?? '',
		gender: dependent.gender ?? null,
	};

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<
		Pick<
			ApplicationDependent,
			'first_name' | 'last_name' | 'birthday' | 'gender'
		>
	>({
		defaultValues,
		mode: 'onSubmit',
	});

	const onSubmit = async (data) => {
		const { birthday } = data;
		await addDependent({
			...data,
			spouse: spouse === '1',
			birthday: formatDateIfValid(birthday, 'MM/DD/YYYY', 'YYYY-MM-DD'),
		});
	};

	const handleValueChange = (
		e: React.FocusEvent<HTMLInputElement, Element>
	) => {
		setValue(e.target.name as keyof typeof defaultValues, e.target.value);
	};

	const isSpouseOptions = [
		{ value: '1', text: 'Yes' },
		{ value: '0', text: 'No' },
	];

	return (
		<Stack gap='6'>
			{(!haveSpouse || dependent.spouse) && (
				<CustomFormControl label={t('Is this dependent your spouse?')}>
					<div className='pt-4'>
						<CustomRadio
							options={isSpouseOptions}
							value={spouse}
							onChange={(val) => setSpouse(val as '0' | '1')}
						/>
					</div>
				</CustomFormControl>
			)}
			<CustomFormControl label={t('First name *')} error={errors.first_name}>
				<CustomInput
					type='text'
					placeholder={t('Enter first name')}
					{...register('first_name', {
						minLength: 3,
						required: true,
					})}
				/>
			</CustomFormControl>
			<CustomFormControl label={t('Last name *')} error={errors.last_name}>
				<CustomInput
					type='text'
					placeholder={t('Enter last name')}
					{...register('last_name', {
						minLength: 3,
						required: true,
					})}
				/>
			</CustomFormControl>
			<CustomFormControl label={t('Date of birth *')} error={errors.birthday}>
				<CustomInput
					mask='99/99/9999'
					type='tel'
					placeholder='mm/dd/yyyy'
					defaultValue={defaultValues.birthday}
					{...register('birthday', {
						required: true,
						validate: { isValidDate, isValidAge },
					})}
					onBlur={handleValueChange}
				/>
			</CustomFormControl>
			<CustomFormControl
				label={t('What was their gender at birth? *')}
				error={errors.gender}>
				<Select
					size='lg'
					bg='gray.100'
					{...register('gender', {
						required: true,
					})}>
					<option value={''}>Select</option>
					<option value={ApplicationGender.Male}>
						{getGenderText(ApplicationGender.Male)}
					</option>
					<option value={ApplicationGender.Female}>
						{getGenderText(ApplicationGender.Female)}
					</option>
				</Select>
			</CustomFormControl>

			<Stack direction='row'>
				<CustomButton
					className='flex grow'
					size='sm'
					leftIcon={<CloseIcon />}
					variant='outline'
					color='gray.500'
					isDisabled={disableCancel}
					onClick={onCancel}>
					Cancel
				</CustomButton>

				<CustomButton
					className='flex grow'
					size='sm'
					leftIcon={<SaveIcon />}
					variant='outline'
					color='blue.500'
					onClick={handleSubmit(onSubmit)}>
					Save
				</CustomButton>
			</Stack>
		</Stack>
	);
};
