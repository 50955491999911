import { observer } from 'mobx-react';
import { LoadingPage } from 'pages/loading';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes';
import { useStores } from 'stores';

interface AuthGuardProps {
	children: JSX.Element;
	isLoggedIn?: boolean;
	isEmailAuthenticated?: boolean;
}

export const AuthGuard = observer(
	({
		children,
		isLoggedIn = true,
		isEmailAuthenticated = true,
	}: AuthGuardProps): JSX.Element => {
		const navigate = useNavigate();
		const { authenticationStore } = useStores();
		const [authorized, setAuthorized] = useState<boolean | null>(null);

		useEffect(() => {
			if (authorized === false) {
				navigate(RoutePath.root);
			}
		}, [authorized, navigate]);

		useEffect(() => {
			if (authenticationStore.isSetupFinished) {
				if (authenticationStore.isLoggedIn) {
					setAuthorized(
						authenticationStore.isEmailAuthenticated === isEmailAuthenticated
					);
				} else {
					setAuthorized(authenticationStore.isLoggedIn === isLoggedIn);
				}
			}
		}, [
			isLoggedIn,
			isEmailAuthenticated,
			authenticationStore.isEmailAuthenticated,
			authenticationStore.isLoggedIn,
			authenticationStore.isSetupFinished,
		]);

		if (!authorized) {
			return <LoadingPage />;
		}

		return children;
	}
);
