import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Heading,
	Stack,
	Text,
} from '@chakra-ui/react';
import { T } from '@tolgee/react';
import { ReactComponent as PencilIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { CustomButton } from 'components';
import { ApplicationDependent } from 'models';
import { ReactNode } from 'react';
import { formatCapitalize, formatDateIfValid, getGenderText } from 'utils';

type ApplicationDependentAccordionProps = {
	dependent: ApplicationDependent;
	index: number;
	isEdit: boolean;
	children: ReactNode;
	onEdit(): Promise<void>;
	onRemove(): Promise<void>;
};

export function ApplicationDependentAccordion({
	dependent,
	isEdit,
	index,
	children,
	onEdit,
	onRemove,
}: ApplicationDependentAccordionProps) {
	const brithdayFormatted = formatDateIfValid(
		dependent.birthday,
		'YYYY-MM-DD',
		'MM/DD/YYYY',
		false
	);
	return (
		<AccordionItem
			key={index}
			padding={'12px'}
			marginY={2}
			border={'1px'}
			borderStyle={'solid'}
			borderColor={'#D8D8E5'}
			borderRadius={'12px'}>
			<h2>
				<AccordionButton
					bg={'transparent'}
					_hover={{
						background: 'transparent',
					}}>
					<Stack textAlign={'left'} spacing={2}>
						<Heading fontSize={'lg'} fontWeight={700} color='blackAlpha.900'>
							{formatCapitalize(dependent.first_name)}{' '}
							{formatCapitalize(dependent.last_name)}
						</Heading>
						<Text fontSize={'sm'} fontWeight={400} color='blackAlpha.900'>
							{dependent.spouse && <T>Spouse</T>}
							{!dependent.spouse && <T>Child</T>}
						</Text>
					</Stack>
					<AccordionIcon
						position={'absolute'}
						right={'10'}
						color={'blue.500'}
					/>
				</AccordionButton>
			</h2>
			<AccordionPanel pb={4}>
				{isEdit && children}
				{!isEdit && (
					<>
						<Stack spacing={4} paddingTop='8px'>
							<Stack spacing={2}>
								<Text className='font-medium' color='gray.900'>
									<T>First name</T>
								</Text>
								<Text className='text-sm' color='gray.900'>
									{formatCapitalize(dependent.first_name)}
								</Text>
							</Stack>
							<Stack spacing={2}>
								<Text className='font-medium' color='gray.900'>
									<T>Last name</T>
								</Text>
								<Text className='text-sm' color='gray.900'>
									{formatCapitalize(dependent.last_name)}
								</Text>
							</Stack>
							<Stack spacing={2}>
								<Text className='font-medium' color='gray.900'>
									<T>Date of birth</T>
								</Text>
								{brithdayFormatted && (
									<Text className='text-sm' color='gray.900'>
										{brithdayFormatted}
									</Text>
								)}
							</Stack>
							<Stack spacing={2}>
								<Text className='font-medium' color='gray.900'>
									<T>Gender at birth</T>
								</Text>
								<Text className='text-sm' color='gray.900'>
									{getGenderText(dependent.gender)}
								</Text>
							</Stack>
						</Stack>
						<Stack direction='row' paddingTop='24px'>
							<CustomButton
								className='flex grow'
								size='sm'
								leftIcon={<PencilIcon />}
								variant='outline'
								color='blue.500'
								onClick={onEdit}>
								Edit
							</CustomButton>

							<CustomButton
								className='flex grow'
								size='sm'
								leftIcon={<TrashIcon />}
								variant='outline'
								color='red.500'
								onClick={onRemove}>
								Remove
							</CustomButton>
						</Stack>
					</>
				)}
			</AccordionPanel>
		</AccordionItem>
	);
}
