import { useQuery } from '@tanstack/react-query';
import { Endpoints, api } from 'api';
import { QueryKey } from 'constant';
import { Transaction } from 'models';

export function useTransaction() {
	return useQuery<Transaction[], any>({
		queryKey: [QueryKey.payments],
		queryFn: () =>
			api
				.get<Transaction[]>(Endpoints.payments)
				.then((response) => response?.data),
	});
}
