import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
	Button,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
} from '@chakra-ui/react';
import { forwardRef, useState } from 'react';

export const CustomInputPassword = forwardRef(
	({ ...props }: InputProps, ref) => {
		const [show, setShow] = useState(false);
		const handleClick = () => setShow(!show);

		return (
			<InputGroup size='md'>
				<Input
					size='lg'
					_light={{ bg: 'gray.100' }}
					type={show ? 'text' : 'password'}
					ref={ref}
					{...props}
				/>
				<InputRightElement marginTop={1} paddingRight={2} width={'fit-content'}>
					<Button color={'gray.500'} size={'xs'} onClick={handleClick}>
						{show ? <ViewOffIcon /> : <ViewIcon />}
					</Button>
				</InputRightElement>
			</InputGroup>
		);
	}
);
