export enum ApplicationProductStatus {
	Draft = 'Draft',
	Submitted = 'Submitted',
	Approved = 'Approved',
	Rejected = 'Rejected',
}

export enum ApplicationStatus {
	Draft = 'Draft',
	Submitted = 'Submitted',
	Approved = 'Approved',
	Rejected = 'Rejected',
}
