import { createContext, useContext } from 'react';
import { ApplicationStore } from './application-store';
import { AuthenticationStore } from './authentication-store';
import { NavigationStore } from './navigation-store';
import { QuoteStore } from './quote-store';
import { UTMStore } from './utm-store';

export class RootStore {
	authenticationStore: AuthenticationStore;

	quoteStore: QuoteStore;

	applicationStore: ApplicationStore;

	utmStore: UTMStore;

	navigationStore: NavigationStore;

	constructor() {
		this.authenticationStore = new AuthenticationStore(this);
		this.quoteStore = new QuoteStore();
		this.applicationStore = new ApplicationStore(this);
		this.utmStore = new UTMStore();
		this.navigationStore = new NavigationStore();
	}

	clean() {
		this.authenticationStore.clean();
		this.applicationStore.clean();
		this.quoteStore.clean();
		this.utmStore.clean();
		this.navigationStore.clean();
	}

	reload() {
		this.applicationStore.reload();
		this.quoteStore.reload();
	}
}

export const RootStoreContext = createContext<RootStore>({} as RootStore);

export const useStores = () => useContext<RootStore>(RootStoreContext);
