import { Input, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import InputMask from 'react-input-mask';

type CustomInputProps = { mask?: string | (string | RegExp)[] } & InputProps;

export const CustomInput = forwardRef(
	({ mask, ...props }: CustomInputProps, ref) => {
		return mask ? (
			<InputMask mask={mask} ref={ref} {...props}>
				<Input size='lg' _light={{ bg: 'gray.100' }} {...props} />
			</InputMask>
		) : (
			<Input size='lg' _light={{ bg: 'gray.100' }} ref={ref} {...props} />
		);
	}
);
