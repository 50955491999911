import {
	Box,
	Text,
	UseRadioGroupProps,
	useRadio,
	useRadioGroup,
} from '@chakra-ui/react';

function RadioCard(props) {
	const { getInputProps, getRadioProps } = useRadio(props);

	const input = getInputProps();
	const checkbox = getRadioProps();

	return (
		<Box as='label' className='flex grow max-w-[200px]'>
			<input {...input} />
			<Box
				{...checkbox}
				className={`border rounded-lg w-full p-4 flex flex-row items-center ${
					props.index === 0 ? '' : 'ml-3'
				}`}
				cursor='pointer'
				_light={{
					borderColor: 'gray.200',
				}}
				_dark={{
					color: 'gray.200',
				}}
				_checked={{
					_light: {
						color: 'gray.900',
					},
					borderColor: 'blue.500',
				}}>
				<Box
					{...checkbox}
					className='rounded-full w-5 h-5 border items-center mr-5'
					borderColor='gray.200'
					color={'blue.500'}
					_checked={{
						_light: {
							borderColor: 'blue.500',
							color: 'blue.500',
						},
						color: 'gray.900',
						borderWidth: '6px',
					}}
				/>
				{props.children}
			</Box>
		</Box>
	);
}

type CustomRadioProps = {
	options: { value: string; text: string }[];
} & UseRadioGroupProps;

export const CustomRadio = ({ options, ...props }: CustomRadioProps) => {
	const { getRootProps, getRadioProps } = useRadioGroup({
		name: 'haveDependents',
		...props,
	});
	const group = getRootProps();
	return (
		<div className='flex flex-row' {...group}>
			{options.map(({ value, text }, index) => {
				const radio = getRadioProps({ value });
				return (
					<RadioCard key={value} index={index} {...radio}>
						<Text fontSize='text-base' fontWeight='bold'>
							{text}
						</Text>
					</RadioCard>
				);
			})}
		</div>
	);
};
